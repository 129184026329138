import { Box, Button, Divider, Text } from '@chakra-ui/react';
import React, { useContext, useEffect} from 'react';
import { CartContext } from '../../../context/cart';
import useCartStore from '../../../store/cartStore';

export const CheckoutCard = () => {
  const { setActiveStep, cartData, activeStep } = useContext(CartContext);
  const { cart, totalOrderPrice, setTotalOrderPrice } = useCartStore();

  const updateFinalPrice = () => {
    if (!cartData) return;

    let totalPrice = 0;

    cartData.forEach(cartItem => {
      totalPrice =
        totalPrice +
        (cartItem?.patients?.length
          ? parseInt(cartItem.price) * parseInt(cartItem.patients.length)
          : 0);
    });

    setTotalOrderPrice(totalPrice);
  };

  useEffect(() => {
    updateFinalPrice();
  }, [cart, cartData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        flex: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDir: 'column',
          borderRadius: '20px',
          border: '1px solid rgba(0, 0, 0, 0.2)',
          gap: '15px',
          p: '20px',
        }}
      >
        <Text
          sx={{
            color: 'black',
            fontWeight: 600,
          }}
        >
          {cartData?.length} Lab Tests
        </Text>
        <Divider />
        {cartData?.map(cartItem => {
          const names = cartItem?.patients?.map(person => person?.name);
          const result = names?.join(', ').replace(/,([^,]*)$/, ' &$1');
          return (
            <Box
              sx={{
                color: 'black',
                display: 'flex',
                flexDir: 'column',
                gap: '20px',
                alignItems: 'flex-start',
              }}
            >
              <Text
                sx={{
                  color: 'black',
                  fontWeight: 600,
                }}
              >
                {result}
              </Text>
              <Box
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.2)',
                  borderRadius: '20px',
                  display: 'flex',
                  py: '20px',
                  justifyContent: 'space-between',
                  px: '20px',
                  width: '100%',
                }}
              >
                <Text
                  sx={{
                    color: 'black',
                    fontWeight: 600,
                    width: '60%',
                  }}
                >
                  {cartItem?.name}
                </Text>
                <Text
                  sx={{
                    color: 'black',
                    fontWeight: 600,
                  }}
                >
                  {cartItem?.patients
                    ? parseInt(cartItem?.price) *
                      parseInt(cartItem?.patients?.length)
                    : 0}
                  /-
                </Text>
              </Box>
            </Box>
          );
        })}
        <Divider />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text
            sx={{
              color: 'black',
              fontWeight: 600,
            }}
          >
            Total
          </Text>
          <Text
            sx={{
              color: 'black',
              fontWeight: 600,
            }}
          >
            {totalOrderPrice} /-
          </Text>
        </Box>
      </Box>
      <Button
        sx={{
          backgroundColor: 'teal',
          color: 'white',
          py: '25px',
          borderRadius: '15px',
          _hover: {
            backgroundColor: 'teal',
          },
        }}
        isDisabled={activeStep === 5}
        onClick={() => setActiveStep(activeStep => activeStep + 1)}
      >
        Continue
      </Button>
    </Box>
  );
};
