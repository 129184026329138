import React, { useEffect, useState } from 'react';
// import { TopMain} from './'
import { PackageCard } from '../components/Packages/Package/PackageCard';
import { Box } from '@chakra-ui/react';
import { PatientInstructions } from '../components/Packages/Package/PatientInstructions';
import { Footer } from '../components/Footer';
import { FAQ } from '../components/Landing/FAQ';
import { MostSearchPackages } from '../components/Packages/Package/MostSearchPackages';
import { useLocation } from 'react-router-dom';
import { packageDetails } from '../api/packages';
import { FloatingButton } from '../components/Global';

export const PackagePage = () => {
  const location = useLocation();
  const [packages, setPackages] = useState('');
  // console.log('calling test location obeject', location);

  const searchObject = new URLSearchParams(location?.search);

  // console.log('calling searchOBject', searchObject);

  const id = searchObject.get('packageId');

  useEffect(() => {
    const fetchData = async () => {
      const res = await packageDetails({ id });
      setPackages(res);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      width={'full'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <PackageCard packages={packages} />
      <PatientInstructions />
      {/* <LabInformation packages={packages} /> */}
      <MostSearchPackages />
      <FloatingButton />
      <FAQ />
      <Footer />
    </Box>
  );
};
