import React, { useEffect, useState } from 'react';
import {
  HStack,
  Input,
  Button,
  InputGroup,
  InputRightAddon,
  useBreakpoint,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';

export const SearchBar = () => {
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();

  const breakpoint = useBreakpoint();

  const handleSearch = async e => {
    e?.preventDefault();
    // const res = await searchlabs(keyword);
    // setSearchResult(res);
    // setKeyword('');
    // console.log('calling searched lab res', res);
    // console.log(searchResult);
    keyword === ''
      ? navigate(`/search`)
      : navigate(`/search?keyword=${keyword}`);
  };

  // useEffect(() => {
  //   const handleSearch = async () => {
  //     const res = await searchlabs(keyword);
  //     setSearchResult(res);
  //     // setKeyword('');
  //     // console.log('calling searched lab res', res);
  //     // console.log(searchResult);
  //     navigate('/search');
  //   };
  //   handleSearch();
  // }, [keyword]);

  return (
    <form onSubmit={handleSearch}>
      <HStack
        display={['flex', 'flex']}
        width={['100%', '100%', '100%', '100%', '100%']}
      >
        <InputGroup border={'none'} size={['sm', 'md']} width={'100%'}>
          <Input
            placeholder="Search By City, Lab Or Pincode"
            type="text"
            variant={'filled'}
            size={['sm', 'md']}
            color={'black'}
            width={'100%'}
            _focus={{ bg: 'white', color: 'black' }}
            borderLeftRadius={'7'}
            name="keyword"
            onChange={e => setKeyword(e.target.value)}
            value={keyword}
          />
          <InputRightAddon
            padding={'0'}
            border={'none'}
            size={['sm', 'md']}
            borderRightRadius={'7'}
          >
            <Button
              size={['sm', 'md']}
              type="submit"
              borderLeftRadius={'0'}
              colorScheme="teal"
              border={'none'}
              px={[2, 6]}
              py={2}
            >
              {/* {breakpoint === 'sm' ||
              breakpoint === 'base' ||
              breakpoint === 'md' ? (
                <SearchIcon />
              ) : (
                'Search'
              )} */}
              Search
            </Button>
          </InputRightAddon>
        </InputGroup>
      </HStack>
    </form>
  );
};
