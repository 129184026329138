import { Box, Flex, HStack } from '@chakra-ui/react';
import { Logo } from '../Logo';
import { Navigations } from './Navigations';
import { MobileNavbar } from './MobileNavbar';
import { TopSearchBar } from './TopSearcBar';

export const NavBar = () => {
  return (
    <HStack
      alignItems={'center'}
      justifyContent={'space-between'}
      p={[5, 5]}
      px={5}
      py={[4, 5]}
      height={'15vh'}
      width={'100%'}
    >
      <Logo />
      <Flex display={['flex', 'none', 'flex', 'flex', 'flex']}>
        <TopSearchBar />
      </Flex>
      <Box
        display={['none', 'flex']}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <Navigations />
      </Box>
      <MobileNavbar />
    </HStack>
  );
};
