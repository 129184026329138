import React, { useEffect } from 'react';
// import { TopMain} from './'
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router';
import { FAQ } from '../components/Landing/FAQ';

import { TestDetails } from '../components/UserOrders/TestDetails';

export const TestOrderDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '100%',
        height: '85vh',
        flexDir: 'column',
        px: ['3', '5', '5', '5'],
        py: 5,
        gap: 5,
        marginLeft: ['0px', '0px', '260px', '260px'],
        overflowY: 'scroll',
        zIndex: '1000',
        pb: '100px',
      }}
    >
      <Box>
        <TestDetails />
        <FAQ />
      </Box>
    </Box>
  );
};
