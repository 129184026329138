import { Box, Divider, Flex, IconButton, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { CartContext } from '../../../../../context/cart';

export const Item = ({ data, index }) => {
  const { removeFromCart } = useContext(CartContext);
  const [price, setPrice] = useState(0);

  const updatePrice = () => {
    if (!data) return;
    let totalPrice = data?.patients?.length * data?.price || 0;

    setPrice(totalPrice);
  };

  useEffect(() => {
    updatePrice();
  });

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={2}
      color={'black'}
    >
      <Text fontSize={14}>{data.name}</Text>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box fontSize={14} display={'flex'} alignItems={'center'} gap={2}>
          <Text textDecor={''}>₹ {price || '00'}</Text>
          <Divider orientation="vertical" />
          <IconButton
            variant={'ghost'}
            color={'red'}
            borderRadius={50}
            onClick={() => removeFromCart(data)}
          >
            <AiFillDelete />
          </IconButton>
        </Box>
      </Box>
    </Flex>
  );
};
