import {
  Box,
  Grid,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai';
import { FaLocationArrow } from 'react-icons/fa';

export const Contact = () => {
  return (
    <VStack spacing={10} py={10} px={[3, 10, 10, 10, 16]} width={'100%'}>
      <Heading color={'#0C727E'}>Contact Us</Heading>
      <Grid
        justifyContent={'space-evenly'}
        flexDir={['1fr', '1fr', '1fr', '1fr 1fr', '1fr 1fr']}
        width={'100%'}
        display={'flex'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={[
            'center',
            'center',
            'center',
            'flex-end',
            'flex-end',
          ]}
        >
          <VStack
            spacing={5}
            alignItems={['flex-start']}
            px={[3, 8]}
            width={['100%', '70%', '80%', '76%', '76%']}
            justifyContent={'center'}
          >
            <HStack spacing={[5, 8]}>
              <Icon as={AiFillPhone} fontSize={['24', '30']} />
              <Box>
                <Heading fontSize={18} fontWeight={500} color={'#0C727E'}>
                  Phone
                </Heading>
                <Text fontWeight={500}>+91 73037 01602</Text>
              </Box>
            </HStack>
            <HStack spacing={[5, 8]}>
              <Icon as={AiOutlineMail} fontSize={['24', '30']} />
              <Box>
                <Heading fontSize={18} fontWeight={500} color={'#0C727E'}>
                  Email
                </Heading>
                <Text fontWeight={500}>contact@dilatelabs.com</Text>
              </Box>
            </HStack>
            <HStack spacing={[5, 8]}>
              <Icon as={FaLocationArrow} fontSize={['24', '30']} />
              <Box>
                <Heading fontSize={18} fontWeight={500} color={'#0C727E'}>
                  Dilate Labs Private Limited
                </Heading>
                <Text fontWeight={500}>
                  2972 Westheimer Rd. Santa Ana, Illinois 85486{' '}
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Box>
        <Box
          width={'100%'}
          display={['none', 'none', 'flex', 'flex', 'flex']}
          alignItems={'center'}
          justifyContent={['center']}
        >
          <Image src="/assets/landing/Contact.png" height={300} width={300} />
        </Box>
      </Grid>
    </VStack>
  );
};
