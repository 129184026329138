import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  Input,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TestCard } from './TestCard';
import useTestStore from '../../../../store/testStore';
import { getTests, searchTests } from '../../../../api/tests';

export const LabTests = () => {
  const { tests, setTests } = useTestStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });
  const fetchData = async () => {
    try {
      const res = await getTests();
      setTests(res);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = async e => {
    e.preventDefault();
    const res = await searchTests(e.target.value);
    setTests(res);
  };

  return (
    <VStack p={[3, 10, 10, 24, 32]} spacing={[10]} height={'full'} id="tests">
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[25, 30]}>
          Lab Tests
        </Heading>
        <Input
          variant="outline"
          placeholder="Search For Test"
          size={['md', 'lg']}
          width={['80vw', '35vw']}
          onChange={e => handleSearch(e)}
        />
      </Flex>
      <VStack
        spacing={[6, 8]}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tests.slice(0, 6).map((test, i) => {
          return <TestCard test={test} key={i} index={i} />;
        })}
      </VStack>
      <Button
        variant={'ghost'}
        color={'teal'}
        onClick={() => navigate('/tests')}
      >
        All Tests
      </Button>
    </VStack>
  );
};
