import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import CircumIcon from '@klarr-agency/circum-icons-react';
import useCartStore from '../../../store/cartStore';
import { useNavigate } from 'react-router-dom';

export const Navbar = () => {
  const { location, setIsLocationOpen } = useCartStore();
  const navigate = useNavigate();
  return (
    <Flex
      bg={'teal'}
      color={'white'}
      py={5}
      px={['5', '12', '12', '24']}
      justifyContent={'space-between'}
      width={'100%'}
    >
      <Flex
        gap={3}
        alignItems={'center'}
        sx={{
          cursor: 'pointer',
          fontSize: 'xl',
        }}
        onClick={() => navigate('/')}
      >
        <ArrowBackIcon />
        <Text>Back</Text>
      </Flex>
      <Flex
        alignItems={'center'}
        gap={2}
        width={'xm'}
        cursor={'pointer'}
        onClick={() => setIsLocationOpen(true)}
      >
        <CircumIcon name="location_on" />
        <Box>
          <Text fontSize={20} fontWeight={600}>
            {!location && 'Click Here'}
            {location.split(' ')[0]} {location.split(' ')[1]}
          </Text>
          <Text fontSize={14}>
            {!location && 'Fetch Location'}
            {location.split(' ').map((location, index) => {
              if (index === 0 || index === 1) return;
              return `${location} `;
            })}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
