import { Button, Flex, Heading, Input, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PackageCard } from './PackageCard';
import usePackageStore from '../../../../store/packageStore';
import { getPackages, searchPackages } from '../../../../api/packages';

export const LabPackages = () => {
  const { packages, setPackages } = usePackageStore();
  const navigate = useNavigate();
  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPackages();
      setPackages(res);
    };
    fetchData();
  }, []);

  const handleSearch = async e => {
    e.preventDefault();
    const res = await searchPackages(e.target.value);
    setPackages(res);
  };

  return (
    <VStack p={[3, 10]} spacing={12}>
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[24, 30]}>
          Lab Packages
        </Heading>
        <Input
          variant="outline"
          placeholder="Search For Package"
          size={['md', 'lg']}
          width={['80vw', '35vw']}
          onChange={e => handleSearch(e)}
        />
      </Flex>
      <VStack
        spacing={[6, 8]}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {packages.slice(0, 4).map((packages, i) => {
          return <PackageCard packages={packages} key={i} />;
        })}
      </VStack>
      <Button
        variant={'ghost'}
        color={'teal'}
        onClick={() => navigate('/packages')}
      >
        All Packages
      </Button>
    </VStack>
  );
};
