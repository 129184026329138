import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  VStack,
  useToast,
  Stack,
  Avatar,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconComponent } from '../../Global/IconComponents';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { MdLocationOn } from 'react-icons/md';
import axios from 'axios';
import { getOrderDetails } from '../../../api/order';
import { Review } from '../../Reviews';

export const PackageDetails = () => {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState({});
  const [packagesOrder, setPackagesOrder] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // console.log('calling test location obeject', location);

  const searchObject = new URLSearchParams(location?.search);

  // console.log('calling searchOBject', searchObject);

  const orderId = searchObject.get('orderId');

  console.log('calling order Id', orderId);

  const toast = useToast();

  const handleCancelled = async () => {
    try {
      navigate(
        `/profile/orders/orderdetails?orderId=${orderId}&action?=cancel`
      );
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/labs/orders/cancel/${orderId}`
      );

      setPackagesOrder(res?.data?.order);
      toast({
        title: res?.data?.message,
        duration: 9000,
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error cancelling order:', error);
      toast({
        title: 'error in cancelling the order',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchOrder = async () => {
    if (orderId) {
      try {
        setLoading(true);
        const res = await getOrderDetails(orderId);
        setPackages(() => res.packages);
        // console.log('calling getorderDetails res', res);
        setPackagesOrder(res);
      } catch (error) {
        console.error('error while fetching orderDetails', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  return (
    <VStack
      justifyContent={'space-between'}
      spacing={0}
      color={'black'}
      gap={12}
      px={['1', '3']}
      width={'100%'}
      transition={'all'}
    >
      <Stack
        width={'100%'}
        gap={[0, 0, 0, 0, 10]}
        justifyContent={'space-between'}
        alignItems={['center']}
        flexDir={['column', 'column', 'column', 'column', 'row']}
      >
        <VStack
          sx={{
            py: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
            gap: 5,
          }}
        >
          <Heading fontSize={['25px', '3xl']}>{packages?.name}</Heading>
          <Text
            sx={{
              width: '100%',
              fontSize: ['16px', '18px'],
            }}
          >
            {packages?.description}
          </Text>
          <HStack spacing={7} fontSize={'lg'}>
            <Box
              sx={{ display: 'flex', flexDir: 'column', alignItems: 'center' }}
            >
              <Text color={'teal.400'}>Basic</Text>
              <Text>{packages?.tests?.length} Tests</Text>
            </Box>
            <Box
              sx={{ display: 'flex', flexDir: 'column', alignItems: 'center' }}
            >
              <Text color={'teal.400'}>Essential</Text>
              <Text>
                {packagesOrder?.parameterValues?.length} Paramters Covered
              </Text>
            </Box>
          </HStack>
        </VStack>
        <VStack spacing={1}>
          <Text fontSize={20} color={'teal'}>
            ₹ {packages?.price}
          </Text>
          <Text
            color={'gray'}
            sx={{
              display: 'flex',
              textDecor: 'line-through',
            }}
            fontSize={'15'}
          >
            ₹ 5500.00
          </Text>

          <Button
            bg={
              packagesOrder?.orderStatus === 'pending'
                ? '#FFC035'
                : packagesOrder?.orderStatus === 'completed'
                ? '#2CBAA3'
                : 'messenger.500'
            }
            color={'#fff'}
            _hover={{}}
          >
            {packagesOrder?.orderStatus}
          </Button>
        </VStack>
      </Stack>
      <VStack width={'100%'} alignItems={'flex-start'} gap={'5'}>
        <Heading color={'#1D5563'} fontSize={['25px', '3xl']}>
          Customer Details
        </Heading>
        <HStack
          justifyContent={'space-between'}
          width={'100%'}
          gap={'5'}
          flexWrap={'wrap'}
        >
          <VStack gap={'5'} alignItems={'flexd-start'}>
            <HStack gap={'5'}>
              <Avatar src={packagesOrder?.consumerDetails?.user?.fileUrl} />
              <VStack alignItems={'flex-start'}>
                <Text color={'teal'}>
                  {packagesOrder?.consumerDetails?.user?.phone}
                </Text>
                <Text>{packagesOrder?.consumerDetails?.user?._id}</Text>
              </VStack>
            </HStack>
            <Box
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: '20px',
                px: ['5'],
                py: '20px',
                display: 'flex',
                flexDir: 'column',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: ['center', 'flex-start'],
                  alignItems: ['flex-start', 'center'],
                  flexDir: ['column', 'row'],
                }}
              >
                <IconComponent
                  icon={<PhoneIcon />}
                  text={packagesOrder?.consumerDetails?.phone}
                />
                <IconComponent
                  icon={<EmailIcon />}
                  text={packagesOrder?.consumerDetails?.email}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <IconComponent
                  icon={<MdLocationOn />}
                  text={
                    packagesOrder?.consumerDetails?.address?.secondaryAddress
                  }
                />
              </Box>
            </Box>
          </VStack>
          {packagesOrder?.orderStatus === 'completed' ? (
            <VStack justifyContent={'flex-start'} gap={'5'}>
              <Button
                colorScheme="purple"
                onClick={() =>
                  navigate(
                    `/profile/orders/orderdetails/healthscore?orderId=${orderId}`
                  )
                }
              >
                Check Health Score
              </Button>
              <Button bg={'teal'} color={'#fff'}>
                <a
                  href={packagesOrder?.reportUrl}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Report
                </a>
              </Button>
            </VStack>
          ) : packagesOrder?.orderStatus === 'pending' ? (
            <Button
              bg={'#FF725E'}
              color={'#fff'}
              onClick={handleCancelled}
              isLoading={loading}
            >
              Cancel
            </Button>
          ) : (
            <Button bg={'#FF725E'} color={'#fff'} isDisabled>
              Cancel
            </Button>
          )}
        </HStack>
      </VStack>
      {packagesOrder?.orderStatus === 'completed' && (
        <Review testOrder={packagesOrder} />
      )}
    </VStack>
  );
};
