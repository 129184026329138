import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button,
  Box,
  Spinner,
  Heading,
  Text,
} from '@chakra-ui/react';
import useCartStore from '../../../store/cartStore';
import { LabCard } from '../../Labs/Lab/LabCard';

export const NearbyLabsModal = ({ isOpen, onClose }) => {
  const { nearbyLabs } = useCartStore();
  // console.log('calling nearby labs in modal', nearbyLabs);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'80%'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Nearby Labs</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY={'scroll'} overflowX={'scroll'}>
          {nearbyLabs?.length > 0 ? (
            <VStack spacing={4}>
              {nearbyLabs?.map((lab, index) => (
                <LabCard key={index} lab={lab} onClose={onClose} />
              ))}
            </VStack>
          ) : (
            <Text width={'100%'} textAlign={'center'} padding={'10'}>
              No Labs Found nearby you
            </Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
