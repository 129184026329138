/*global google */

import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import Map from 'google-map-react';
import Marker from 'google-map-react';
import useCartStore from '../../../../store/cartStore';
import { ImLocation2 } from 'react-icons/im';
import { CartContext } from '../../../../context/cart';
import useLabsStore from '../../../../store/labsStore';
import axios from 'axios';

export const LocationDrawer = props => {


  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPlaceName, setSelectedPlaceName] = useState('');
  const { isLocationOpen, setIsLocationOpen, setNearbyLabs } = useCartStore();
  const { setLocation, activeStep, setActiveStep } = useContext(CartContext);
  const { setLabData } = useLabsStore();

  useEffect(() => {
    if (map) {
      const input = document.getElementById('autocomplete');
      const autoComplete = new window.google.maps.places.Autocomplete(input);
      setAutocomplete(autoComplete);

      console.log('calling window object', window);

      autoComplete?.addListener('place_changed', () => {
        const place = autoComplete?.getPlace();
        if (!place?.geometry) {
          console.error('Place has no geometry');
          return;
        }

        const coords = {
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        };

        let position = new google.maps.LatLng(
          parseFloat(place.geometry.location.lat()),
          parseFloat(place.geometry.location.lng())
        );

        map.panTo(position);
        map.setZoom(15);

        setSuggestions([]);
        setSelectedLocation({ coords });
        setSelectedPlaceName(place.description);

        if (selectedMarker) {
          selectedMarker.setMap(null);
        }

        // // Display marker for the selected location
        const marker = new google.maps.Marker({
          position: position,
          map: map,
          title: place.description,
          id: 'selectedLocationMarker',
        });

        marker.addListener('click', () => {});

        setSelectedMarker(marker);
      });
    }
  }, [map, selectedMarker]);

  const fetchAutocompletePredictions = input => {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      { input: input },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
        } else {
          console.error('Error fetching predictions:', status);
        }
      }
    );
  };

  const handleInputChange = e => {
    const inputValue = e.target.value;
    fetchAutocompletePredictions(inputValue);
  };

  const handleSuggestionClick = prediction => {
    const placeId = prediction.place_id;
    const placesService = new window.google.maps.places.PlacesService(map);

    placesService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const location = place.geometry.location;

        let position = new google.maps.LatLng(location.lat(), location.lng());

        map.panTo(position);
        map.setZoom(15);

        setSuggestions([]);
        setSelectedLocation({ lat: location.lat(), lng: location.lng() });
        setSelectedPlaceName(prediction.description);

        inputRef.current.value = prediction.description;

        if (selectedMarker) {
          selectedMarker.setMap(null);
        }

        const marker = new google.maps.Marker({
          position: position,
          map: map,
          title: prediction.description,
          id: 'selectedLocationMarker',
        });

        marker.addListener('click', () => {});

        setSelectedMarker(marker);
      } else {
        console.error('Error fetching place details:', status);
      }
    });
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          setSelectedLocation(currentLocation);
          const geocoder = new window.google.maps.Geocoder();

          // Performing reverse geocoding to get the address from coordinates
          geocoder.geocode({ location: currentLocation }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                console.log('calling reverse gecoding', results[0]);
                const formattedAddress = results[0].formatted_address;

                map.panTo(currentLocation);
                map.setZoom(15);

                setSuggestions([]);

                if (selectedMarker) {
                  selectedMarker.setMap(null);
                }

                const marker = new window.google.maps.Marker({
                  position: currentLocation,
                  map: map,
                  title: formattedAddress,
                  id: 'currentLocationMarker',
                });

                marker.addListener('click', () => {});

                setSelectedPlaceName(formattedAddress);

                setSelectedMarker(marker);
              } else {
                console.error('No results found for reverse geocoding.');
              }
            } else {
              console.error('Geocoder failed due to: ' + status);
            }
          });
        },
        error => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  // function to fetch nearby labs from Google Maps based on userLocation
  const fetchNearbyLabs = async userLocation => {
    const service = new google.maps.places.PlacesService(map);

    return new Promise((resolve, reject) => {
      service.nearbySearch(
        {
          location: userLocation,
          radius: 5000,
          type: ['lab'],
          keyword: "labs",
        },
        (results, status) => {
          console.log(results, status)
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const nearbyLabs = results.map(result => ({
              placeId: result.place_id,
              name: result.name,
              location: {
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng(),
              },
            }));
            resolve(nearbyLabs);
          } else {
            reject(new Error('Nearby search failed'));
          }
        }
      );
    });
  };

  const fetchAllLabsFromDatabase = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/getLabs`);
      const approvedLabs = res?.data?.labs?.filter(
        lab => lab?.isApproved === true
      );
      console.log('calling approved labs', approvedLabs);
      return approvedLabs;
    } catch (error) {
      console.error('Error fetching labs from the database:', error);
      return [];
    }
  };

  // function to filter common labs present in both datasets based on placeId
  const filterCommonLabs = (nearbyLabs, allLabs) => {
    console.log('calling nearby labs', nearbyLabs);
    // Compare labs based on placeId
    return allLabs.filter(lab => {
      console.log('calling databse labs', lab?.labAddress?.placeId);
      const commonLabs = nearbyLabs.some(
        nearbyLab => nearbyLab.placeId === lab?.labAddress?.placeId
      );
      return commonLabs;
    });
  };

  // function to calculate the distance between two locations using Haversine formula
  const calculateDistance = (userLocation, labLocation) => {
    const R = 6371e3;
    const φ1 = userLocation.lat * (Math.PI / 180);
    const φ2 = labLocation.lat * (Math.PI / 180);
    const Δφ = (labLocation.lat - userLocation.lat) * (Math.PI / 180);
    const Δλ = (labLocation.lng - userLocation.lng) * (Math.PI / 180);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  // function to find the closest lab based on user location and common labs
  const findClosestLab = (userLocation, commonLabs) => {
    let closestLab = null;
    let minDistance = Infinity;

    console.log('calling common labs', commonLabs);

    commonLabs.forEach(lab => {
      const distance = calculateDistance(userLocation, lab?.labAddress);
      if (distance < minDistance) {
        minDistance = distance;
        closestLab = lab;
      }
    });

    console.log('calling closestLab', closestLab);

    return closestLab;
  };

  // function to assign lab ID to the order
  const assignLabIDToOrder = closestLab => {
    const labId = closestLab?._id;
    setLabData(closestLab);
  };

  const handleConfirmLocation = async () => {
    if (selectedLocation && selectedPlaceName) {
      setLoading(true);
      try {
        setLocation(selectedPlaceName);
        setIsLocationOpen(false);

        // find neear by places based on user location coordinates and assign the lab id of neareset lab
        const nearbyLabs = await fetchNearbyLabs(selectedLocation);
        const allLabs = await fetchAllLabsFromDatabase();
        const commonLabs = filterCommonLabs(nearbyLabs, allLabs);
        setNearbyLabs(commonLabs);
        const closestLab = findClosestLab(selectedLocation, commonLabs);
        assignLabIDToOrder(closestLab);
        setActiveStep(activeStep + 1);
      } catch (error) {
        console.error('error in handle confirm location', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Drawer
      placement={'right'}
      size={'md'}
      onClose={() => setIsLocationOpen(false)}
      isOpen={isLocationOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">Select Location</DrawerHeader>
        <DrawerCloseButton onClick={() => setIsLocationOpen(false)} />{' '}
        <DrawerBody
          sx={{
            display: 'flex',
            flexDir: 'column',
            gap: 4,
          }}
          height={'auto'}
          overflowY={'scroll'}
        >
          <Button
            width={'100%'}
            onClick={getCurrentLocation}
            colorScheme="teal"
          >
            Use Current Location
          </Button>
          {/* <Input
            type="text"
            id="autocomplete"
            ref={inputRef}
            placeholder="Enter pincode or address"
          /> */}

          {/* <Searchbox map={map} /> */}
          <Input
            name="labAddress"
            type="text"
            ref={inputRef}
            placeholder="Lab Address"
            focusBorderColor="teal.500"
            onChange={e => handleInputChange(e)}
          />
          {suggestions.length > 0 && (
            <VStack width={'100%'} gap={2} alignItems={'flex-start'}>
              {suggestions.map(prediction => (
                <HStack
                  key={prediction.place_id}
                  width={'100%'}
                  cursor={'pointer'}
                  onClick={() => handleSuggestionClick(prediction)}
                  justifyContent={'flex-start'}
                >
                  <ImLocation2 color="teal" />
                  <Text flexWrap={'wrap'} overflowWrap={'wrap'}>
                    {prediction.description}
                  </Text>
                </HStack>
              ))}
            </VStack>
          )}
          <Button
            width={'100%'}
            onClick={handleConfirmLocation}
            colorScheme="teal"
            isDisabled={!(selectedLocation && selectedPlaceName)}
          >
            Confirm Location
          </Button>
          <Box width={'100%'} gap={'10'} h={'auto'}>
            <Box
              id="map-container"
              sx={{
                width: '100%',
                height: '300px',
                borderRadius: '20px',
              }}
              zIndex={-1}
            >
              <Map
                bootstrapURLKeys={{
                  key: 'AIzaSyBB5UQNwaNzVxOTwp6okWXmx6VijFl_Kfw',
                  libraries: ['places'],
                }}
                center={selectedLocation || { lat: 37.7749, lng: -122.4194 }}
                zoom={14}
                onGoogleApiLoaded={({ map }) => setMap(map)}
              >
                {selectedLocation && (
                  <Marker
                    lat={selectedLocation.lat}
                    lng={selectedLocation.lng}
                    text={selectedPlaceName}
                  />
                )}
              </Map>
            </Box>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
