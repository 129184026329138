import { Box, Button, Grid, GridItem, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../../../context/cart';

const SlotButton = ({ slot }) => {
  const { setOrderDetail, orderDetail } = useContext(CartContext);

  return (
    <GridItem width={'100%'}>
      <Button
        sx={{
          width: '100%',
          px: '20px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '15px',
          backgroundColor:
            orderDetail?.appointmentTime === slot
              ? 'rgba(207, 255, 207, 0.8)'
              : 'transparent',
        }}
        onClick={() =>
          setOrderDetail(orderDetail => ({
            ...orderDetail,
            appointmentTime: slot,
          }))
        }
      >
        <Text
          sx={{
            fontSize: '13px',
            fontWeight: 600,
          }}
        >
          {slot}
        </Text>
      </Button>
    </GridItem>
  );
};

const TimeSlot = ({ batch, availableSlots }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDir: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style={{
            width: '20px',
            height: '20px',
          }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
          />
        </svg>
        <Text
          sx={{
            fontSize: '13px',
            fontWeight: 600,
          }}
        >
          {batch} {' Slots '}
          {batch === 'Morning' ? '(AM)' : '(PM)'}
        </Text>
      </Box>
      <Grid
        templateColumns="repeat(auto-fill, minmax(136px, 1fr))"
        gap={6}
        width={'100%'}
      >
        {availableSlots?.map((slot, ind) => (
          <SlotButton slot={slot} key={ind} />
        ))}
      </Grid>
    </Box>
  );
};

export const SelectTime = () => {
  const [timeSlot, setTimeSlot] = useState();

  useEffect(() => {
    function generateTimeRangesSeparate() {
      const amTimeRanges = [];
      const pmTimeRanges = [];

      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 60) {
          const startTime = `${hour.toString().padStart(2, '0')}:${minute
            .toString()
            .padStart(2, '0')} AM`;
          const endTime = `${(hour === 23 ? 0 : hour + 1)
            .toString()
            .padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${
            hour === 23 ? 'AM' : 'AM'
          }`;

          const timeRange = `${startTime} - ${endTime}`;

          // Separate into AM and PM arrays
          if (hour < 12) {
            amTimeRanges.push(timeRange);
          } else {
            pmTimeRanges.push(timeRange);
          }
        }
      }

      return { am: amTimeRanges, pm: pmTimeRanges };
    }

    const tSlots = generateTimeRangesSeparate();
    // console.log(tSlots);
    setTimeSlot(() => tSlots);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDir: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        maxWidth: '100%',
        width: '100%',
      }}
    >
      <Text
        sx={{
          color: 'black',
          fontSize: '18px',
          fontWeight: 600,
        }}
      >
        Select Time
      </Text>
      <Box
        sx={{
          display: 'flex',
          flexDir: 'column',
          gap: '20px',
          alignItems: 'center',
          width: '100%',
          overflowX: 'scroll',
          px: '30px',
          py: '20px',
          border: '1px solid rgba(0, 0, 0, 0.2)',
          borderRadius: '20px',
        }}
      >
        <TimeSlot batch={'Morning'} availableSlots={timeSlot?.am} />
        <TimeSlot batch={'Afternoon'} availableSlots={timeSlot?.pm} />
      </Box>
    </Box>
  );
};
