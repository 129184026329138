import axios from 'axios';

export const getLabs = async () => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER}/getLabs`);
  const approvedLabs = res?.data?.labs?.filter(lab => lab?.isApproved === true);
  return approvedLabs;
};

export const searchlabs = async keyword => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER}/getLabs?keyword=${keyword}`
  );
  const approvedLabs = res?.data?.labs?.filter(lab => lab?.isApproved === true);
  return approvedLabs;
};

export const labDetails = async id => {
  try {
    if (id) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/getLabs?id=${id}`
      );

      const lab = res.data.lab;
      return lab;
    }
  } catch (error) {
    console.error('error while fetching lab details', error);
  }
};
