import {
  Card,
  CardBody,
  CardFooter,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

export const AboutCard = ({ cardItem }) => {
  return (
    <Card
      borderRadius={'12'}
      boxShadow={'2xl'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      p={'0'}
      width={'100%'}
      height={'100%'}
      // width={['260px', '360px', '400px', '400px', '400px']}
      // height={['260px', '360px', '400px', '400px', '400px']}
    >
      <CardBody
        p={['3', '5']}
        pb={'0'}
        width={['260px', '360px', '400px', '400px', '400px']}
        maxH={'400px'}
      >
        <Image
          borderRadius={'12'}
          objectFit={'cover'}
          src={cardItem?.imageSrc}
          alt="Image"
          width={'100%'}
          h={['260px', '360px', '400px', '400px', '400px']}
        />
      </CardBody>
      <CardFooter width={'100%'}>
        <VStack
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'5'}
        >
          <VStack justifyContent={'center'} gap={'0'}>
            <Text fontWeight={'bold'}>{cardItem?.name}</Text>
            <Text fontWeight={'bold'}>{cardItem?.role}</Text>
          </VStack>

          <HStack
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <a href={cardItem?.facebookUrl} rel="noreferrer" target="_blank">
              <FaFacebook color="teal" size={'24px'} />
            </a>
            <a href={cardItem?.instagramUrl} rel="noreferrer" target="_blank">
              <FaInstagram color="teal" size={'24px'} />
            </a>
            <a href={cardItem?.twitterUrl} rel="noreferrer" target="_blank">
              <FaTwitter color="teal" size={'24px'} />
            </a>
            <a href={cardItem?.linkedInUrl} rel="noreferrer" target="_blank">
              <FaLinkedin color="teal" size={'24px'} />
            </a>
          </HStack>
        </VStack>
      </CardFooter>
    </Card>
  );
};
