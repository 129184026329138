import {
  Box,
  Button,
  Grid,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import axios from 'axios';
import { getUser } from '../cookie/user';
import { useNavigate } from 'react-router-dom';

export const ContactPage = () => {
  const navigate = useNavigate();
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const [queryData, setQueryData] = useState({
    name: user?.firstName + ' ' + user?.lastName,
    email: user?.email,
    phone: user?.phone,
    subject: '',
    message: '',
  });

  const toast = useToast();

  const handleChange = e => {
    setQueryData({
      ...queryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/contact`, {
        queryData,
      });

      // console.log('calling user contact response', res);

      toast({
        title: res?.data?.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/');
    } catch (error) {
      console.error('Error sending email:', error);
      toast({
        title: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width={'100%'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        h={'100%'}
        p={'64px'}
        gap={'32px'}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Heading color={'teal'}>Contact Us</Heading>
        <Text fontSize={['16px', '18px']} px={['0', '10', '10', '12', '12']}>
          Planning your next laboratory endeavor? Our dedicated team is ready to
          assist you with any inquiries or reservations. Reach out to us for a
          seamless experience in creating scientific breakthroughs.
        </Text>
        <Grid
          width={'100%'}
          px={['0', '10', '10', '12', '12']}
          gap={'10px'}
          justifyContent={'center'}
          flexDirection={['column', 'column', 'row', 'row', 'row']}
          templateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr']}
        >
          <VStack
            as="form"
            width={'100%'}
            alignItems={'flex-end'}
            gap={'16px'}
            focusBorderColor="teal.500"
          >
            <Input
              type="text"
              placeholder="Enter Name"
              name="name"
              value={queryData.name}
              onChange={handleChange}
              focusBorderColor="teal.500"
            />
            <Input
              type="email"
              placeholder="Enter Email"
              name="email"
              value={queryData.email}
              onChange={handleChange}
              focusBorderColor="teal.500"
            />
            <Input
              type="text"
              placeholder="Enter Subject"
              name="subject"
              value={queryData.subject}
              onChange={handleChange}
              focusBorderColor="teal.500"
            />
            <Textarea
              placeholder="Enter your message here"
              name="message"
              value={queryData.message}
              onChange={handleChange}
              focusBorderColor="teal.500"
            />
            <Button
              type="submit"
              width="100%"
              colorScheme="teal"
              onClick={e => handleSubmit(e)}
              isLoading={loading}
            >
              Submit your query
            </Button>
          </VStack>
          <Box
            width={'100%'}
            display={['none', 'none', 'flex', 'flex', 'flex']}
            alignItems={'flex-start'}
            justifyContent={['flex-end']}
          >
            <Image
              src="/assets/landing/Contact.png"
              height={300}
              width={300}
              alt="Contact Image"
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
