'use client';
import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useField } from '@formiz/core';
import React, { useRef, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

export const TextField = props => {
  const [show, setShow] = React.useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { errorMessage, isValid, isPristine, isSubmitted, setValue, value } =
    useField(props);
  var { label, type, required, name, disable, placeholder } = props;
  const showError = !isValid && !isFocused && (!isPristine || isSubmitted);
  const showPassword = useRef(false);

  const handleClick = () => setShow(!show);
  return (
    <>
      {type === 'password' ? (
        <InputGroup size="md">
          <Input
            disabled={disable}
            id={name}
            name={name}
            value={value}
            focusBorderColor="teal.600"
            onChange={e => setValue(e.target.value)}
            type={show ? 'text' : 'password'}
            placeholder={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      ) : type === 'number' ? (
        <InputGroup>
          <InputLeftAddon>+91</InputLeftAddon>
          <Input
            disabled={disable}
            id={name}
            name={name}
            value={value}
            focusBorderColor="teal.600"
            pl={name === 'phone' && 9}
            onChange={e => setValue(e.target.value)}
            type="number"
            placeholder={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </InputGroup>
      ) : type === 'select' ? (
        <Select
          icon={<MdArrowDropDown />}
          id={name}
          name={name}
          type={type}
          value={value}
          focusBorderColor="teal.600"
          placeholder={placeholder}
          onChange={e => setValue(e.target.value)}
        >
          <option value={'male'}>Male</option>
          <option value={'male'}>Female</option>
          <option value={'male'}>Trans Gender</option>
        </Select>
      ) : type === 'text' ? (
        <Input
          disabled={false}
          id={name}
          name={name}
          type={type}
          value={value}
          focusBorderColor="teal.600"
          placeholder={placeholder}
          onChange={e => setValue(e.target.value)}
        />
      ) : type === 'date' ? (
        <Input
          type={'date'}
          name={name}
          id={name}
          label={`${label} ${required && '*'}`}
          color="black"
          placeholder={placeholder}
          error={showError}
          value={value}
          focusBorderColor="teal.600"
          onChange={e => setValue(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      ) : (
        <Textarea
          type={type === 'password' ? (showPassword ? 'text' : type) : type}
          disabled={disable}
          pl={name === 'phone' && 9}
          name={name}
          id={name}
          label={`${label} ${required && '*'}`}
          color="black"
          placeholder={placeholder}
          error={showError}
          value={value}
          focusBorderColor="teal.600"
          onChange={e => setValue(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}
      {showError && (
        <Text
          sx={{
            color: '#FF4747',
            alignSelf: 'start',
            mt: -1,
            ml: 13,
            mr: 13,
            whiteSpace: 'pre-line',
          }}
        >
          {errorMessage}
        </Text>
      )}
    </>
  );
};
