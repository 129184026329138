import { Box, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IconComponent } from '../../Global/IconComponents';
import { CartContext } from '../../../context/cart';
import { EmailIcon, PhoneIcon, TimeIcon } from '@chakra-ui/icons';
import { MdDateRange } from 'react-icons/md';
import { MdLocationOn } from 'react-icons/md';

export const AppointmentDetails = () => {
  const { orderDetail } = useContext(CartContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Text
        sx={{
          fontWeight: 600,
          fontSize: '18px',
        }}
      >
        Appointment Details
      </Text>
      <Box
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.2)',
          borderRadius: '20px',
          px: '20px',
          py: '20px',
          display: 'flex',
          flexDir: 'column',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDir: ['column', 'row'],
            gap: '20px',
            justifyContent: 'flex-start',
            alignItems: ['flex-start', 'center'],
            pb: '10px',
            borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <IconComponent icon={<PhoneIcon />} text={orderDetail?.phone} />
          <IconComponent icon={<EmailIcon />} text={orderDetail?.email} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDir: ['column', 'row'],
            gap: '20px',
            justifyContent: 'flex-start',
            alignItems: ['flex-start', 'center'],
            pb: '10px',
            borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <IconComponent
            icon={<MdDateRange />}
            text={orderDetail?.appointmentDate}
          />

          <IconComponent
            icon={<TimeIcon />}
            text={orderDetail?.appointmentTime}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconComponent
            icon={<MdLocationOn />}
            text={orderDetail?.mainAddress}
          />
        </Box>
      </Box>
    </Box>
  );
};
