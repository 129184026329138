import { create } from 'zustand';

const useTestStore = create(set => ({
  tests: [],
  setTests: e =>
    set(state => ({
      tests: e,
    })),
}));

export default useTestStore;
