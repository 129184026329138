import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import successImage from '../../../assets/lab_search/payment/3.png';
import { getUser } from '../../../cookie/user';
import { createOrder } from '../../../api/order';

import { Link } from 'react-router-dom';
import useCartStore from '../../../store/cartStore';
import { useContext } from 'react';
import { CartContext } from '../../../context/cart';
import { useEffect } from 'react';
import axios from 'axios';
import useLabsStore from '../../../store/labsStore';

export const PaymentSection = () => {
  const { setCart, totalOrderPrice } = useCartStore();
  const { labData, setLabData } = useLabsStore();
  const { setCartPlans, setActiveStep, orderDetail, cartData } =
    useContext(CartContext);

  const user = getUser();
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [paymentMode, setPaymentMode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [packagesData, setPackagesData] = useState({});
  const [testData, setTestData] = useState({});
  const [razorpayOrder, setRazorpayOrder] = useState('');
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);

  const toast = useToast();

  // console.log('calling total order price', totalOrderPrice);

  useEffect(() => {
    let filteredPackages = cartData?.filter(plan => !plan.tag);
    let filteredTests = cartData?.filter(plan => plan.tag);
    setPackagesData(filteredPackages);
    setTestData(filteredTests);
  }, [cartData]);

  const verificationHandler = async response => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/paymentverification`,
        { response }
      );
      // console.log('calling verification res inside handler', res);

      setRazorpayOrder(res?.data?.razorpayOrder);
      setCheckoutSuccess(true);
    } catch (error) {
      console.error('error in the verification request');
    }
  };

  const razorpayCheckoutHandler = async () => {
    try {
      // console.log(
      //   'calling total order price inside checkout handler',
      //   totalOrderPrice
      // );
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/checkoutOrder`,
        { totalOrderPrice }
      );

      const orderId = res.data.razorpayOrder.id;
      // console.log('calling orderId', orderId);

      // console.log('calling checkout order res', res);

      const options = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: res.data.razorpayOrder.amount,
        currency: 'INR',
        name: user?.firstName + ' ' + user?.lastName,
        description: 'This is a Test Transaction for DLab',
        image: user?.fileUrl,
        order_id: orderId,
        handler: async response => verificationHandler(response),
        prefill: {
          name: user?.firstName + ' ' + user?.lastName,
          email: user?.email ? user?.email : 'xyz@gmail.com',
          contact: user?.phone,
        },
        notes: {
          address: user?.address,
        },
        theme: {
          color: '#0c727e',
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error('error while creating checkout', error);
    }
  };

  const orderHandler = async (items, itemType) => {
    items?.forEach(async item => {
      item?.patients?.forEach(async patient => {
        const orderData = {
          user: user?.id,
          patientId: patient?._id,
          lab: labData?._id,
          test: itemType === 'test' ? item : {},
          packages: itemType === 'packages' ? item : {},
          name: item?.name,
          sampleMode: orderDetail?.sampleMode,
          consumerDetails: {
            user,
            phone: orderDetail?.phone,
            email: orderDetail?.email,
            mainAddress: orderDetail?.mainAddress,
            address: orderDetail?.address,
          },
          paymentDetails: {
            appointmentDate: orderDetail?.appointmentDate,
            appointmentTime: orderDetail?.appointmentTime,
            orderPaymentStatus: checkoutSuccess
              ? 'success'
              : 'no online payment',
            price: parseInt(item.price),
            mode: paymentMode,
            razorpayOrder: checkoutSuccess
              ? razorpayOrder
              : 'not a razorpay order',
          },
        };

        try {
          const res = await createOrder(orderData);

          toast({
            title: res.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error creating order for :', item.name, error);
          throw error;
        }
      });
    });
  };

  useEffect(() => {
    if (checkoutSuccess) {
      orderHandler(testData, 'test');
      orderHandler(packagesData, 'packages');
      setPaymentStatus('success');
      setCart([]);
      setCartPlans([]);
    }
  }, [checkoutSuccess, razorpayOrder]);

  const handlePaymentSelection = async () => {
    setLoading(true);
    try {
      if (paymentMode === 'razorpay') {
        razorpayCheckoutHandler();
        setIsModalOpen(false);
      } else {
        orderHandler(testData, 'test');
        orderHandler(packagesData, 'packages');
        setPaymentStatus('success');
        setIsModalOpen(false);
        setCart([]);
        setCartPlans([]);
      }
    } catch (error) {
      console.error('Error creating order:', error);
      toast({
        title: 'Error in payment process',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePaymentModeChange = value => {
    setPaymentMode(value);
  };

  return (
    <VStack
      sx={{
        spacing: '10',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        // px: '30px',
        // py: '30px',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
        flex: '7',
        width: '100%',
        h: '100%',
      }}
    >
      <Heading
        width={'100%'}
        textAlign={'center'}
        color={'#0C727E'}
        borderBottom="1px solid rgba(0, 0, 0, 0.3)"
        padding={'5'}
      >
        Payment Status
      </Heading>
      <Box
        width={['100%']}
        height={['100%']}
        display={'flex'}
        flexDir={['column', 'column', 'row', 'row']}
        justifyContent={'center'}
        alignItems={['center', 'center']}
        gap={'0'}
        p={'5'}
        sx={
          {
            // border: '1px solid rgba(0, 0, 0, 0.3)',
            // py: '30px',
            // borderRadius: '20px',
            // px: '30px',
            // gap: '30px',
          }
        }
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          width={'100%'}
          h={'100%'}
        >
          <Image
            display={['none', 'flex']}
            src={successImage}
            maxW={['60%', '60%', '80%', '80%']}
            maxH={['60%', '60%', '80%', '80%']}
          />
        </Box>

        <VStack
          spacing={5}
          alignItems={['flex-start']}
          justifyContent={'center'}
          color={'black'}
          // border="1px solid rgba(0, 0, 0, 0.3)"
          // padding={'5'}
          // borderRadius={'20px'}
          width={'100%'}
          height={'100%'}
        >
          {paymentStatus === 'pending' ? (
            <>
              <Flex
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'red.200',
                  color: 'red.500',
                  borderRadius: 6,
                  px: 3,
                }}
              >
                <Text
                  fontSize={16}
                  py={'2'}
                  alignSelf={'center'}
                  fontWeight={'500'}
                >
                  Your payment is pending
                </Text>
              </Flex>
              <Text fontWeight={500}>
                Please complete the payment process to proceed with your order
              </Text>
              <Button
                colorScheme="teal"
                onClick={() => setIsModalOpen(true)}
                sx={{
                  backgroundColor: 'teal',
                  color: 'white',
                  _hover: {
                    backgroundColor: 'teal.500',
                  },
                }}
              >
                Complete Payment
              </Button>
            </>
          ) : (
            <>
              {razorpayOrder ? (
                <>
                  <Flex
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      bg: 'teal.500',
                      color: 'white',
                      borderRadius: 6,
                      px: 3,
                    }}
                  >
                    <Text
                      fontSize={16}
                      py={'2'}
                      alignSelf={'center'}
                      fontWeight={'500'}
                    >
                      Your payment is successful
                    </Text>
                  </Flex>
                  <Text fontWeight={500}>
                    ReferenceId : {razorpayOrder?.razorpay_payment_id}
                  </Text>
                </>
              ) : (
                <>
                  <Flex
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      bg: 'teal.500',
                      color: 'white',
                      borderRadius: 6,
                      px: 3,
                    }}
                  >
                    <Text
                      fontSize={16}
                      py={'2'}
                      alignSelf={'center'}
                      fontWeight={'500'}
                    >
                      Your Appointment order is successful
                    </Text>
                  </Flex>
                  <Text fontWeight={500}>
                    Please pay order amount to lab at the time of sampling
                  </Text>
                </>
              )}

              <Link to="/">
                <Button
                  colorScheme="teal"
                  width={'full'}
                  onClick={() => setActiveStep(1)}
                >
                  Go to Home
                </Button>
              </Link>
            </>
          )}
        </VStack>
      </Box>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        size={['100%', '2xl']}
      >
        <ModalOverlay />
        <ModalContent justifyContent={'center'} alignItems={'flex-start'}>
          <ModalHeader textAlign={'left'} width={'100%'}>
            Payment Options
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody width={'80%'}>
            <RadioGroup onChange={handlePaymentModeChange} value={paymentMode}>
              <VStack justifyContent={'center'} alignItems={'left'}>
                <Radio
                  id="labpay"
                  name="paymentoption"
                  value="labpay"
                  // checked={paymentMode === 'labpay'}
                >
                  Pay At Lab
                </Radio>

                <Radio
                  id="razorpay"
                  name="paymentoption"
                  value="razorpay"
                  // checked={paymentMode === 'razorpay'}
                  onChange={() => handlePaymentModeChange('razorpay')}
                >
                  Pay Now
                </Radio>
              </VStack>
            </RadioGroup>
            <VStack justifyContent={'center'} alignItems={'left'}></VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
              Close
            </Button>
            <Button
              colorScheme="teal"
              onClick={handlePaymentSelection}
              isLoading={loading}
            >
              Submit Payment
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
