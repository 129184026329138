import { Heading, VStack, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import useTestStore from '../../../../store/testStore';
import { getTests } from '../../../../api/tests';
import { useNavigate } from 'react-router-dom';
import { TestsCard } from './TestsCard';
// import { TestCard } from '../../../Landing/Tests/TestCard';

export const MostSearchTests = () => {
  const { tests, setTests } = useTestStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getTests();
      setTests(res);
    };
    fetchData();
  }, []);

  return (
    <VStack px={['3', '10', '10', '24', '32']} spacing={12}>
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[24, 30]}>
          Popular Tests
        </Heading>
      </Flex>
      <VStack
        spacing={[6, 8]}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tests.slice(3, 5).map((test, i) => (
          <TestsCard key={i} test={test} />
        ))}
      </VStack>
      <Button
        variant={'ghost'}
        color={'teal'}
        onClick={() => navigate('/tests')}
      >
        All Tests
      </Button>
    </VStack>
  );
};
