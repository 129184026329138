import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export const PackageCard = ({ packages, order }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(`/profile/orders/packagesorderdetails?orderId=${order?._id}`);
  };

  return (
    <Card
      maxW={['lg']}
      maxH={['460px', '500px', '500px', '500px']}
      p={['0', '1', '5', '5']}
      py={1}
      borderRadius={8}
      variant={'outline'}
      transition={'ease-in-out 0.3s'}
      border={'1px solid rgb(180,180,180,1)'}
      _hover={{
        // boxShadow: '0px 4px 5px 0px rgba(42, 43, 44, 0.50)',
        boxShadow: 'xl',
        border: '1px solid gray',
      }}
    >
      <CardBody
        p={['3', '3', '1', '1']}
        onClick={onClickHandler}
        _hover={{
          cursor: 'pointer',
        }}
      >
        <VStack spacing={5} pb={'1'}>
          <HStack spacing={['0', '0', '0', '5']} alignItems={['flex-start']}>
            <Image
              src="/assets/landing/package/diabetes.png"
              width={['16', '20']}
              height={['16', '20']}
            />
            <VStack alignItems={'flex-start'}>
              <Heading fontSize={[22, 25]}>
                {packages?.name.slice(0, 23)}
                {packages?.name.length > 25 && '...'}
              </Heading>
              <Text>
                {packages?.description.slice(0, 150)}
                {packages?.description.length > 150 && '...'}
              </Text>
            </VStack>
          </HStack>
          <Card
            maxW="lg"
            width={'100%'}
            sx={{
              display: 'flex',
              flexDirection: 'rows',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            px={[3, 6]}
            py={2}
            borderRadius={8}
            variant={'outline'}
          >
            <Text color={'#1DC2D5'} fontSize={[18, 20]} fontWeight={600}>
              ₹ {packages?.price}
            </Text>
            <Text
              color={'#1DC2D5'}
              fontSize={[18, 20]}
              fontWeight={600}
              display={'flex'}
              flexDirection={'column'}
            >
              basic
              <Text color={'black'} fontSize={15} fontWeight={600}>
                {packages?.tests.length} Tests
              </Text>
            </Text>
          </Card>
          {order?.orderStatus === 'completed' ? (
            <Button bg={'#0C727E'} color={'#fff'} _hover={{}}>
              <a
                href={order?.reportUrl}
                download
                target="_blank"
                rel="noreferrer"
              >
                Download Report
              </a>
            </Button>
          ) : (
            <Button bg={'#0C727E'} color={'#fff'} _hover={{}} isDisabled>
              Download Report
            </Button>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};
