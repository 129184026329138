import { HStack, Image, Text, VStack } from '@chakra-ui/react';

const STEPS = [
  {
    title: '380+ Pathology Labs In India',
    image: '/assets/landing/stats/1.png',
  },
  {
    title: '450+ Booked Test Per Dar',
    image: '/assets/landing/stats/2.png',
  },
  {
    title: '790+ Pin Codes Served Across India',
    image: '/assets/landing/stats/3.png',
  },
];

export const LandingStats = () => {
  return (
    <VStack width={'full'} display={['none', 'flex']} mb={['12', '']}>
      <HStack
        width={'full'}
        flexDir={['column', 'row']}
        spacing={[16, '24']}
        alignItems={'center'}
        justifyContent={'center'}
        p={14}
        px={[10, 1]}
        bgColor={'teal.400'}
        color={'#fff'}
      >
        {STEPS.map((s, i) => (
          <VStack spacing={1} key={i}>
            <Image src={s.image} width={[150, 180]} height={[150, 180]} />
            <Text textAlign={'center'} fontWeight={500} fontSize={21} as="h4">
              {s.title}
            </Text>
          </VStack>
        ))}
      </HStack>
    </VStack>
  );
};
