import { Button, Flex, VStack } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiUser } from 'react-icons/bi';

export const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menu = [
    {
      title: 'UserDetails',
      link: '/profile',
      icon: <BiUser />,
    },
    {
      title: 'Orders',
      link: '/profile/orders',
      icon: <AiOutlineShoppingCart />,
    },
  ];
  return (
    <Flex
      bg={'#E5F6FB'}
      height={'full'}
      display={[
        'none',
        'none',
        location.pathname === '/' ? 'none' : 'flex',
        location.pathname === '/' ? 'none' : 'flex',
      ]}
      position="fixed"
      px={'3'}
      py={'5'}
      top={'15vh'}
      zIndex={99}
      width={'260px'}
    >
      <VStack
        alignItems={'center'}
        width={'64'}
        spacing={'5'}
        justifyContent={'flex-start'}
        overflowY={'scroll'}
      >
        {menu.map((r, i) => {
          return (
            <Button
              variant="ghost"
              key={i}
              borderRadius={50}
              px={10}
              py={6}
              gap={2}
              justifyContent={'flex-start'}
              width={'56'}
              fontWeight={600}
              transition={'all 300ms'}
              onClick={() => navigate(r.link)}
              color={location.pathname === `${r.link}` ? '#1DC2D5' : 'black'}
              _hover={{
                color: '#1DC2D5',
              }}
            >
              {r.icon}
              {r.title}
            </Button>
          );
        })}
      </VStack>
    </Flex>
  );
};
