import {
  Box,
  IconButton,
  Image,
  Stack,
  VStack,
  HStack,
  Text,
  Button,
  Tag,
} from '@chakra-ui/react';
import images from '../../assets/lab_search/index';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { AiOutlineLineChart } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getReviews } from '../../api/reviews';
import { StarIcon } from '@chakra-ui/icons';

const LabCard = ({ labData }) => {
  const [reviewsData, setReviewsData] = useState([]);

  const totalRatings = reviewsData?.length;
  const averageRating =
    totalRatings === 0
      ? 0
      : reviewsData?.reduce((acc, review) => acc + review?.ratingStar, 0) /
        totalRatings;

  const ratingTextMap = {
    1: 'Terrible',
    2: 'Bad',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
  };

  const fetchReviews = async () => {
    const res = await getReviews(labData?._id);

    // console.log("calling get reviews res", res);
    setReviewsData(res);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const [imgData, setImgData] = useState(images);
  const navigate = useNavigate();

  const imgArray = direction => {
    const imgs = [...imgData];

    if (direction === 'left') {
      const lastElement = imgs.pop();
      imgs.unshift(lastElement);
    }
    if (direction === 'right') {
      const firstElement = imgs.shift();
      imgs.push(firstElement);
    }
    setImgData(imgs);
  };

  return (
    <Stack
      width={'100%'}
      h={['auto', 'auto', 'auto', '620px', '680px']}
      // spacing="24px"
      direction="column"
      alignItems={'center'}
      py={'5'}
      px={['3', '5']}
      borderRadius={8}
      variant={'outline'}
      transition={'ease-in-out 0.3s'}
      border={'1px solid rgb(180,180,180,1)'}
      _hover={{
        // boxShadow: '0px 4px 5px 0px rgba(42, 43, 44, 0.50)',
        boxShadow: 'xl',
        border: '1px solid gray',
      }}
    >
      <VStack alignItems="center" width={'100%'}>
        <HStack width="100%" justifyContent={'center'}>
          <IconButton
            icon={<AiOutlineLeft />}
            variant="ghost"
            onClick={() => imgArray('left')}
          />
          <Image
            src={imgData[0]}
            borderRadius={8}
            objectFit={'cover'}
            height={['80%', '80%', '80%', '80%', '100%']}
            width={['80%', '80%', '80%', '80%', '100%']}
          />
          <IconButton
            icon={<AiOutlineRight />}
            variant="ghost"
            onClick={() => imgArray('right')}
          />
        </HStack>
        {/* <Grid
          width={'100%'}
          h={'100%'}
          gap={'1'}
          px={['0', '0', '0', '0', '1']}
          templateColumns={'repeat(auto-fill, minmax(0, 1fr))'}
          display={['none', 'flex']}
          justifyContent="center" // Center align flex items
        >
          {imgData.map((img, index) =>
            index !== 0 ? (
              <Image
                key={index}
                src={img}
                objectFit={'cover'}
                borderRadius={8}
                width={'100%'}
                h={'80%'}
              />
            ) : null
          )}
        </Grid> */}
      </VStack>

      <Box width="100%" px={['1']} pb={'5'}>
        <VStack alignItems="flex-start" spacing="15px">
          <Text fontSize="25px" fontWeight="semibold" color="teal">
            {labData?.labName}
          </Text>
          <Text fontSize="15px">{labData?.labAddress.placeName?.slice(0, 60)} ...</Text>
        </VStack>
        <VStack alignItems="flex-start" spacing="15px">
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            gap={'3'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            width={'100%'}
          >
            <Tag backgroundColor="green.400" color={'white'} size={'md'}>
              {averageRating.toFixed(1)}
            </Tag>
            <HStack gap={'2'}>
              {[...Array(5)].map((_, index) => (
                <StarIcon
                  key={index}
                  color={
                    index < Math.floor(averageRating) ? '#ffd700' : 'gray.300'
                  }
                />
              ))}
            </HStack>
            <Text fontSize="14px" fontWeight={'500'} py={'2'}>
              {totalRatings === 0
                ? 'No Ratings'
                : ratingTextMap[Math.floor(averageRating)]}
            </Text>
            <Text fontSize="14px">({totalRatings} Ratings)</Text>
          </Box>
          <HStack alignItems="center" width={'100%'} flexWrap={'wrap'}>
            <Text>{`Opens at ${labData?.openingTime}:00 am - ${labData?.closingTime}:00 pm`}</Text>
            <Text color="#1DC2D5">{labData?.workingDays}</Text>
          </HStack>

          <Stack
            width={'100%'}
            alignItems="flex-start"
            flexDir={['column', 'row', 'row', 'row', 'row']}
            justifyContent={[
              'flex-start',
              'space-between',
              'space-between',
              'space-between',
              'space-between',
            ]}
          >
            <VStack spacing="15px" alignItems="flex-start">
              <Button
                size={['sm', 'md']}
                variant="outline"
                color="#1DC2D5"
                border="1px"
              >
                Send Location to Phone
              </Button>
              <HStack
                width="full"
                spacing="16px"
                alignItems="center"
                flexWrap={'wrap'}
              >
                <Button
                  variant="outline"
                  border="1px"
                  borderColor="blackAlpha.600"
                  backgroundColor="gray.200"
                  opacity="60%"
                  size="xs"
                  onClick={() => navigate(`/lab?labId=${labData?._id}`)}
                >
                  Popular blood tests
                </Button>
                <Button
                  variant="outline"
                  border="1px"
                  borderColor="blackAlpha.600"
                  backgroundColor="gray.200"
                  opacity="60%"
                  size="xs"
                  onClick={() => navigate(`/lab?labId=${labData?._id}`)}
                >
                  Health Packages
                </Button>
              </HStack>
              <HStack>
                <AiOutlineLineChart />
                <Text fontSize="12px">36 people recently enquired</Text>
              </HStack>
            </VStack>
            <Stack
              spacing="15px"
              width="100%"
              alignItems={[
                'center',
                'flex-end',
                'flex-end',
                'flex-end',
                'flex-end',
              ]}
              height="full"
              flexDirection={['row', 'column', 'column', 'column', 'column']}
            >
              <Button
                size={['sm', 'md']}
                width={['96px', '143px']}
                colorScheme="teal"
                onClick={() => navigate(`/lab?labId=${labData?._id}`)}
                cursor={'pointer'}
              >
                Visit Lab
              </Button>
              <Button
                size={['sm', 'md']}
                width={['96px', '143px']}
                variant="outline"
                color="#1DC2D5"
                border={'1px'}
                cursor={'pointer'}
                onClick={() => navigate(`/lab?labId=${labData?._id}`)}
              >
                Book A Test
              </Button>
            </Stack>
          </Stack>
        </VStack>
      </Box>
    </Stack>
  );
};

export default LabCard;
