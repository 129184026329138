import { Card, CardBody, CardFooter } from '@chakra-ui/card';
import { Box, HStack, Heading, Stack, Text } from '@chakra-ui/layout';
import React from 'react';
import { Button } from '@chakra-ui/button';
import { useNavigate } from 'react-router';

export const TestCard = ({ test, order }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(`/profile/orders/testorderdetails?orderId=${order?._id}`);
  };

  return (
    <Card
      maxWidth={['lg']}
      maxH={['460px', '460px', '460px', '400px']}
      px={[0, 3]}
      py={1}
      borderRadius={8}
      variant={'outline'}
      transition={'ease-in-out 0.3s'}
      border={'1px solid rgb(180,180,180,1)'}
      _hover={{
        // boxShadow: '0px 4px 5px 0px rgba(42, 43, 44, 0.50)',
        boxShadow: 'xl',
        border: '1px solid gray',
      }}
    >
      <CardBody onClick={onClickHandler} _hover={{ cursor: 'pointer' }}>
        <Stack gap={3}>
          <Heading fontSize={[20, 25]} _hover={{ cursor: 'pointer' }}>
            {test?.name}
          </Heading>
          <Text fontSize={['md', 'md']}>
            {test?.description.slice(0, 250)}
            {test?.description.length > 250 && '...'}
          </Text>
        </Stack>
      </CardBody>
      <CardFooter
        pb={'5'}
        justify="space-between"
        flexWrap="wrap"
        sx={{
          '& > button': {
            minW: '136px',
          },
        }}
      >
        <HStack width={'full'} justifyContent={'space-between'}>
          <Box>
            <Heading fontSize={[15, 18]} color={'#1DC2D5'}>
              ₹ {test?.price}
            </Heading>
          </Box>
          {order?.orderStatus === 'completed' ? (
            <Button bg={'#0C727E'} color={'#fff'} _hover={{}}>
              <a
                href={order?.reportUrl}
                download
                rel="noreferrer"
                target="_blank"
              >
                Download Report
              </a>
            </Button>
          ) : (
            <Button bg={'#0C727E'} color={'#fff'} _hover={{}} isDisabled>
              Download Report
            </Button>
          )}
        </HStack>
      </CardFooter>
    </Card>
  );
};
