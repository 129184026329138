import { Box } from '@chakra-ui/react';
import React from 'react';
import { CheckoutCard } from '../CheckoutCard';

export const CartWrapper = ({ children, display }) => {
  return (
    <Box
      display={display ? 'flex' : 'none'}
      flexDirection={['column', 'column', 'column', 'row']}
      width={'100%'}
      height={'100%'}
      alignItems={['center', 'center', 'center', 'flex-start']}
      justifyContent={'center'}
      py={'10'}
      px={['2', '12', '12', '24']}
      gap={['12', '12', '12', '16']}
      mb="100px"
    >
      {children}
      <CheckoutCard />
    </Box>
  );
};
