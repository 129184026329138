import { Flex, Grid, Heading, Input, Stack, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { getPackages, searchPackages } from '../../../api/packages';
import usePackageStore from '../../../store/packageStore';
import { PackageCard } from '../../Landing/Packages/PackageCard';

export const AllPackages = () => {
  const { packages, setPackages } = usePackageStore();
  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPackages();
      setPackages(res);
    };
    fetchData();
  }, []);

  const handleSearch = async e => {
    e.preventDefault();
    const res = await searchPackages(e.target.value);
    setPackages(res);
  };

  return (
    <VStack
      p={[5, 10]}
      spacing={12}
      alignItems={'center'}
      width={'100%'}
      justifyContent={'center'}
    >
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[24, 30]}>
          Explore All Packages
        </Heading>
        <Input
          variant="outline"
          placeholder="Search For Package"
          size={'lg'}
          width={['80vw', '35vw']}
          onChange={e => handleSearch(e)}
        />
      </Flex>
      <Grid
        width={'100%'}
        height={'100%'}
        gap={[8]}
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr']}
      >
        {packages.map((packages, i) => {
          return (
            <Flex width={'100%'} justifyContent={'center'} p={'0'} gap={'0'}>
              <PackageCard packages={packages} key={i} />
            </Flex>
          );
        })}
      </Grid>
    </VStack>
  );
};
