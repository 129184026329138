import axios from 'axios';
import { getUser } from '../cookie/user';

const user = getUser();

export const fetchPatient = async () => {
  try {
    if (user?._id) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/getUser/${user?._id}`
      );
      // setPatients(res.data.user.patients);
      return res.data.user.patients;
    }
  } catch (error) {
    // console.log(error.message);
    throw new Error(error?.message || 'Cannot fetch patients!');
  }
};
