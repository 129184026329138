import { Box, Button, Divider, HStack, Input, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../../context/cart';
import useCartStore from '../../../store/cartStore';

export const AddressSection = () => {
  const { location, setOrderDetail, activeStep, setActiveStep } =
    useContext(CartContext);
  const { setIsLocationOpen } = useCartStore();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [addressType, setAddressType] = useState('');
  const [mainAddress, setMainAddress] = useState('');

  const changeButtonColor = buttonId => {
    const buttons = ['Work', 'Home', 'Others'];
    buttons.forEach(id => {
      const button = document.getElementById(id);
      if (button) {
        button.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
      }
    });

    const clickedButton = document.getElementById(buttonId);
    if (clickedButton) {
      clickedButton.style.backgroundColor = 'rgb(207, 255, 207)';
      setAddressType(buttonId);
    }
  };

  const locationChangeHandler = () => {
    setActiveStep(activeStep - 1);
    setIsLocationOpen(true);
  };

  useEffect(() => {
    setOrderDetail(orderDetail => ({
      ...orderDetail,
      email: email,
      phone: phone,
      mainAddress: mainAddress,
      address: {
        addressType,
        secondaryAddress: `${location?.split(',')?.slice(1)?.join(',')}`,
      },
    }));
  }, [email, phone, location, mainAddress]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flex: 7,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDir: 'column',
          gap: '30px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDir: 'column',
            gap: '16px',
            p: '20px',
            py: '30px',
            pb: '40px',
            borderRadius: '20px',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={['row', 'row', 'row', 'row']}
            gap={['16px', '16px', '20px', '20px']}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              style={{
                width: '50px',
                height: '50px',
                color: 'black',
              }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
            <Box
              sx={{
                display: 'flex',
                flexDir: ['column'],
                gap: '10px',
                order: [1, 1, 0, 0], // Change the order for smaller screens
              }}
            >
              <Text
                sx={{
                  color: 'black',
                  fontSize: '20px',
                  fontWeight: 600,
                }}
              >
                {location?.split(',')[0]}
              </Text>
              <Text
                sx={{
                  color: 'black',
                  // fontSize: '20px',
                  fontWeight: 600,
                }}
              >
                {location?.split(',')?.slice(1)?.join(',')}
              </Text>
            </Box>
            <Button
              variant={'outline'}
              borderRadius={'20px'}
              padding={'5'}
              colorScheme="teal"
              onClick={locationChangeHandler}
            >
              Change
            </Button>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
            }}
            width={'100%'}
          >
            <Text
              width={'100%'}
              px={'4'}
              py={'1'}
              backgroundColor={'darkred'}
              borderRadius={'20'}
              color={'white'}
            >
              Phlebo will visit at this address for sample collection
            </Text>
          </Box>
          <Input
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              color: 'black',
              fontWeight: 600,
            }}
            onChange={e => setMainAddress(e.target.value)}
            placeholder="Main Address *"
            required={true}
          />
          <Box
            display={'flex'}
            gap={['16px', '16px', '10px', '10px']}
            flexDirection={['column', 'column', 'row', 'row']}
          >
            <Input
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                color: 'black',
                fontWeight: 600,
              }}
              placeholder="Address Line 2 *"
              value={location}
              required={true}
            />
            <HStack justifyContent={'space-between'}>
              <Button
                id="Work"
                sx={{
                  display: 'flex',
                  gap: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  fontSize: '13px',
                  bg: 'rgb(0,0,0,0.1)',
                }}
                onClick={() => changeButtonColor('Work')}
              >
                <Text>Work</Text>
              </Button>
              <Button
                id="Home"
                sx={{
                  display: 'flex',
                  gap: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  fontSize: '13px',
                  bg: 'rgb(0,0,0,0.1)',
                }}
                onClick={() => changeButtonColor('Home')}
              >
                <Text>Home</Text>
              </Button>
              <Button
                id="Others"
                sx={{
                  display: 'flex',
                  gap: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  fontSize: '13px',
                  bg: 'rgb(0,0,0,0.1)',
                }}
                onClick={() => changeButtonColor('Others')}
              >
                <Text>Others</Text>
              </Button>
            </HStack>
          </Box>
        </Box>
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDir: 'column',
            gap: '15px',
            p: '20px',
            py: '30px',
            pb: '40px',
            borderRadius: '20px',
          }}
        >
          <Text
            sx={{
              color: 'black',
              fontWeight: 600,
              fontSize: '18px',
            }}
          >
            Updates/Reports will be sent on
          </Text>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
            }}
          >
            <Input
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                color: 'black',
                fontWeight: 600,
              }}
              placeholder="Email *"
              type="email"
              onChange={e => setEmail(e.target.value)}
              required={true}
            />
            <Input
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                color: 'black',
                fontWeight: 600,
              }}
              placeholder="Phone Number *"
              type="phone"
              onChange={e => setPhone(e.target.value)}
              required={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
