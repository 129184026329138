import React, { useEffect } from 'react';
import { Footer } from '../components/Footer';
import { FAQ } from '../components/Landing/FAQ';
import { getTests } from '../api/tests';
import useTestStore from '../store/testStore';
import { AllTests } from '../components/Tests/AllTests';
import { FloatingButton } from '../components/Global';

export const TestsPage = () => {
  const { tests, setTests } = useTestStore();
  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getTests();
      setTests(res);
    };
    fetchData();
  }, []);

  return (
    <>
      <AllTests />
      <FloatingButton />
      <FAQ />
      <Footer />
    </>
  );
};
