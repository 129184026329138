import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import greenImage from '../../assets/healthScore/Property 1=Default.png';
import yellowImage from '../../assets/healthScore/Property 1=Variant2.png';
import orangeImage from '../../assets/healthScore/Property 1=Variant3.png';
import redImage from '../../assets/healthScore/Property 1=Variant4.png';
import { getOrderDetails } from '../../api/order';

export const HealthScore = () => {
  const [scoreRemarks, setScoreRemarks] = useState('');
  const [loading, setLoading] = useState(false);

  const [imageSrc, setImageSrc] = useState('');
  const [reportUrl, setReportUrl] = useState(null);
  const toast = useToast();

  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('orderId');

  const updateColorAndImage = score => {
    if (score >= 89) {
      setScoreRemarks('Health Score is Excellent');
      setImageSrc(greenImage);
    } else if (score >= 71 && score <= 89) {
      setScoreRemarks('Health Score is Good');
      setImageSrc(yellowImage);
    } else if (score >= 60 && score <= 70) {
      setScoreRemarks('Health Score is Average,With Some Concerns');
      setImageSrc(orangeImage);
    } else {
      setScoreRemarks(
        'Health Score is Dangerous, and Immediate Attention May Be Required'
      );
      setImageSrc(redImage);
    }
  };

  useEffect(() => {
    const fetchOrderDeatils = async () => {
      try {
        const order = await getOrderDetails(orderId);
        setReportUrl(order?.reportUrl);
      } catch (error) {
        toast({
          title: 'error while getting order details',
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    };
    fetchOrderDeatils();
  }, []);

  useEffect(() => {
    const fetchHealthScore = async orderId => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/getPatientHealthScore?orderId=${orderId}`
        );
        const { healthScore } = response.data;
        console.log('calling healthscore', healthScore);
        updateColorAndImage(healthScore);
      } catch (error) {
        toast({
          status: 'error',
          title: 'error while generating health score',
          duration: 5000,
          isClosable: true,
        });
        console.error('Error fetching health score:', error);
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchHealthScore(orderId);
    }
  }, []);
  return (
    <VStack width={'100%'} gap={'10'} alignItems={'flex-start'}>
      <Heading fontSize={'30px'} color="teal">
        Health Score Based On Report
      </Heading>
      <VStack width={'100%'} gap={'5'}>
        {loading ? (
          <>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Spinner width={'40'} h={'40'} thickness="4px" color="teal" />
            </Box>
            <Text fontWeight={'bold'}>Your Score is Loading ...</Text>
          </>
        ) : (
          <>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Image src={imageSrc} width={'40'} />
            </Box>
            <Text fontWeight={'bold'}>Your {scoreRemarks}</Text>
          </>
        )}

        <Text>
          For More Information Please Download The Wellness Report as its only
          AI Generated
        </Text>
        <Button colorScheme="teal">
          <a href={reportUrl} download target="_blank" rel="noreferrer">
            Download Report
          </a>
        </Button>
      </VStack>
      <VStack width={'100%'} gap={'5'} alignItems={'flex-start'}>
        <Heading color={'teal'} fontSize={'30px'}>
          How To Improve Your Score?
        </Heading>
        <HStack>
          <Text fontWeight={'bold'}>Prehypertension -</Text>
          <Text>Visit Your Physician For Ideal Blood Pressure</Text>
        </HStack>
        <HStack>
          <Text fontWeight={'bold'}>Excersize Regularly -</Text>
          <Text> Best Way To Keep Your Heart Healthy.</Text>
        </HStack>
        <HStack>
          <Text fontWeight={'bold'}>Stop Smoking -</Text>
          <Text>Make Your Breath Easier, Protect Lungs.</Text>
        </HStack>
        <HStack>
          <Text fontWeight={'bold'}>Eat Vegetables & Fruits -</Text>
          <Text>Balancing Body Needs, Healthy Life.</Text>
        </HStack>
      </VStack>
      <VStack width={'100%'} gap={'5'} alignItems={'flex-start'}>
        <Heading fontSize={'30px'} color="teal">
          Desclaimer
        </Heading>
        <Text>
          This is only AI Generated Report, For Better Advise Contact Doctor or
          Physician.
        </Text>
      </VStack>
      <VStack width={'100%'} gap={'10'} alignItems={'flex-start'}>
        <Heading fontSize={'30px'} color="teal">
          More Details About Score
        </Heading>
        <Stack
          flexDir={['column', 'row']}
          justifyContent={'space-between'}
          width={'100%'}
          gap={'20'}
        >
          <VStack gap={'5'}>
            <Image
              src="/assets/healthScore/Property 1=Default.png"
              width={'40'}
            />
            <Text fontWeight={'bold'}>
              Health Score is Excellent.. more information available in wellness
              report. (its AI generated)
            </Text>
          </VStack>
          <VStack gap={'5'}>
            <Image
              src="/assets/healthScore/Property 1=Variant2.png"
              width={'40'}
            />
            <Text fontWeight={'bold'}>
              Health Score is Good.. more information available in wellness
              report. (its AI generated)
            </Text>
          </VStack>
          <VStack gap={'5'}>
            <Image
              src="/assets/healthScore/Property 1=Variant3.png"
              width={'40'}
            />
            <Text fontWeight={'bold'}>
              Health Score is Average.. more information available in wellness
              report. (its AI generated)
            </Text>
          </VStack>
          <VStack gap={'5'}>
            <Image
              src="/assets/healthScore/Property 1=Variant4.png"
              width={'40'}
            />
            <Text fontWeight={'bold'}>
              Health Score is Dangerous.. more information available in wellness
              report. (its AI generated)
            </Text>
          </VStack>
        </Stack>
      </VStack>
    </VStack>
  );
};
