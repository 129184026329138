import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Patient } from './Patient';
import { CartDrawer } from './CartDrawer';
import useCartStore from '../../../store/cartStore';
import { CartContext } from '../../../context/cart';
import { SampleSwitch } from './Switch';
import { LocationDrawer } from './LocationDrawer';

export const MainSection = () => {
  const { onOpen } = useDisclosure();
  const { setIsLocationOpen, location } = useCartStore();
  const { setActiveStep, cartPlans } = useContext(CartContext);
  const btnRef = React.useRef();
  const { cart } = useCartStore();
  const [finalPrice, setFinalPrice] = useState(0);
  const discount = parseInt(finalPrice / 1.5);
  const mrp = parseInt(finalPrice * 1.5);
  // const { isLocationOpen, onLocationOpen, onLocationClose } = useDisclosure();
  const locationBtnRef = React.useRef();

  const updateFinalPrice = () => {
    if (!cartPlans) return;

    let totalPrice = 0;

    cartPlans.forEach(cartItem => {
      totalPrice =
        totalPrice +
        (cartItem?.price
          ? parseInt(cartItem?.price) *
            (cartItem?.patients ? parseInt(cartItem?.patients?.length) : 0)
          : 0);
    });

    setFinalPrice(totalPrice);
  };

  useEffect(() => {
    updateFinalPrice();
  }, [cartPlans, cart]);

  return (
    <Stack
      flexDirection={['column', 'column', 'row', 'row']}
      width={'100%'}
      height={'100%'}
      alignItems={['center', 'center', 'flex-start', 'flex-start']}
      justifyContent={'center'}
      py={'10'}
      gap={['3', '16']}
      mb="100px"
    >
      <VStack
        alignItems={['center', 'center', 'flex-end', 'flex-end']}
        justifyContent={'flex-start'}
        width={'100%'}
        gap={'6'}
      >
        <Heading
          size={'md'}
          width={'100%'}
          textAlign={'center'}
          color={'black'}
        >
          {cartPlans?.length} Cart Items
        </Heading>
        <Patient />
      </VStack>
      <VStack
        alignItems={['center', 'center', 'flex-start', 'flex-start']}
        justifyContent={'flex-start'}
        width={'100%'}
        gap={'5'}
      >
        <Box
          display={'flex'}
          width={'100%'}
          justifyContent={['center', 'center', 'flex-start', 'flex-start']}
          alignItems={'center'}
          padding={['5', '5', '0', '0']}
        >
          <SampleSwitch />
        </Box>
        <Box
          flex={1}
          display={'flex'}
          width={'100%'}
          flexDir={'row'}
          paddingX={['5', '5', '0', '0']}
          justifyContent={['center', 'center', 'flex-start', 'flex-start']}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 12,
              border: '3px solid #f2f2f2',
              gap: 5,
            }}
            width={['100%', '90%', '80%', '73%']}
            padding={['5']}
          >
            <Box
              sx={{
                display: 'flex',
                flexDir: 'column',
                color: 'black',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'space-between',
                  color: 'black',
                }}
              >
                <Text>Actual Price</Text>
                <Text>₹ {mrp}/-</Text>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    justifyContent: 'space-between',
                  }}
                >
                  <Text>Lab Discount</Text>
                  <Text>- ₹ {discount}/-</Text>
                </Box>
              </Box>
            </Box>
            <Flex
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent={['center', 'center', 'center', 'space-between']}
              alignItems={'center'}
              bg={'gray.100'}
              padding={5}
              borderRadius={'12'}
              gap={'3'}
            >
              <Box
                color={'black'}
                display={'flex'}
                justifyContent={'space-between'}
                gap={'2'}
              >
                <Text>Total Amount</Text>
                <Text>₹ {finalPrice}/-</Text>
              </Box>
              {location ? (
                <Button
                  colorScheme="teal"
                  ref={locationBtnRef}
                  variant={'solid'}
                  size={'md'}
                  onClick={() => setActiveStep(2)}
                >
                  Go to next
                </Button>
              ) : (
                <Button
                  colorScheme="teal"
                  ref={locationBtnRef}
                  variant={'solid'}
                  size={'md'}
                  onClick={() => setIsLocationOpen(true)}
                >
                  Select Address
                </Button>
              )}
            </Flex>
          </Box>
        </Box>
      </VStack>
      <LocationDrawer />
      <CartDrawer onOpen={onOpen} btnRef={btnRef} />
    </Stack>
    /* <Flex
      color={'black'}
      height={'50vh'}
      paddingBottom={'100px'}
      width={'100vw'}
      justifyContent={'center'}
      padding={['4', '8']}
    >
      <Box
        flex={1}
        gap={4}
        display={'flex'}
        flexDir={'column'}
        alignItems={'end'}
      >
        <Flex
          flexDir={['column', 'row']}
          justifyContent={['center', 'space-between']}
          alignItems={['center', 'flex-start']}
          px={['1', '5']}
          width={['100%', '80%']}
        >
          <Heading size={'md'} width={'100%'} textAlign={'center'}>
            {cartPlans?.length} Cart Items
          </Heading>
          <Flex
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              bg: 'gray.100',
              color: 'blackAlpha.400',
              borderRadius: 10,
              px: 3,
            }}
            width={'100%'}
          >
            <SampleSwitch />
          </Flex>
        </Flex>
        <Patient />
      </Box>

      <Box flex={1} mt={'16'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 16,
            border: '3px solid #f2f2f2',
            mt: `1`,
            width: '52%',
            gap: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDir: 'column',
              gap: '3',
              p: 5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              <Text>₹ Total</Text>
              <Text>₹ {mrp}/-</Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'space-between',
                }}
              >
                <Text>Lab Discount</Text>
                <Text>- ₹ {discount}/-</Text>
              </Box>
            </Box>
          </Box>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            bg={'gray.100'}
            padding={5}
            paddingRight={1}
          >
            <Box>
              <Text>Total Amount</Text>
              <Text>₹ {finalPrice}/-</Text>
            </Box>
            {location ? (
              <Button
                colorScheme="teal"
                ref={locationBtnRef}
                variant={'solid'}
                size={'md'}
                onClick={() => setActiveStep(2)}
              >
                Go to next
              </Button>
            ) : (
              <Button
                colorScheme="teal"
                ref={locationBtnRef}
                variant={'solid'}
                size={'md'}
                onClick={() => setIsLocationOpen(true)}
              >
                Select Address
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
      <LocationDrawer />
      <CartDrawer onOpen={onOpen} btnRef={btnRef} />
    </Flex> */
  );
};
