import {
  Heading,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import React from 'react';

export const FAQ = () => {
  return (
    <VStack
      maxW={'100%'}
      width={'100%'}
      spacing={['8', '12']}
      py={[0, 10]}
      pb={'32'}
    >
      <Heading color={'#0C727E'}>FAQ</Heading>
      <VStack spacing={3} width={'100%'} p={'0'}>
        <Accordion allowToggle width={'100%'}>
          <AccordionItem width={'100%'} justifyContent={'space-between'}>
            <h2>
              <AccordionButton
                // px={[4, 8]}
                py={[3, 5]}
                width={'100%'}
                justifyContent={'space-between'}
                textAlign={'start'}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  How should I book an appointment for a Lab test/Health
                  Checkup?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              You can book an appointment via the following steps: <br /> •
              Choose your city Pincode <br /> • Choose your near by Laboratory
              or package/lab test & add it to the cart <br /> • Add additional
              tests if needed <br />• You can book tests <br /> • Select date
              ,slot & time
              <br /> • Confirm your patient information
              <br /> • Appointment confirmed
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem width={'100%'} justifyContent={'space-between'}>
            <h2>
              <AccordionButton
                // px={[4, 8]}
                py={[3, 5]}
                width={'100%'}
                justifyContent={'space-between'}
                textAlign={'start'}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  How many days in advance do I need to book an appointment?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              You can book the appointment or sample collection from home for
              same day
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem width={'100%'} justifyContent={'space-between'}>
            <h2>
              <AccordionButton
                // px={[4, 8]}
                py={[3, 5]}
                width={'100%'}
                justifyContent={'space-between'}
                textAlign={'start'}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  Can an appointment be canceled?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Yes
              <br />• Select the Profile icon on the top-right corner <br />• Go
              to orders <br />• Select Pending Orders <br />• Click on the order
              card you want to cancel
              <br />• On new page you can see cancel button ,click cancel
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem width={'100%'} justifyContent={'space-between'}>
            <h2>
              <AccordionButton
                // px={[4, 8]}
                py={[3, 5]}
                width={'100%'}
                justifyContent={'space-between'}
                textAlign={'start'}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  When will I receive my Health Checkup reports?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Your Health Checkup report shall be available for download within
              12 hours.
              <br /> Steps to download the report:
              <br />• Select the Profile icon on the top-right corner <br />• Go
              to orders <br />• Select completed order from drop down menu
              <br />• Click on the download button on the order card you are
              looking for
              <br />• You will be redirected to a download page where you can
              see your report
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem width={'100%'} justifyContent={'space-between'}>
            <h2>
              <AccordionButton
                // px={[4, 8]}
                py={[3, 5]}
                width={'100%'}
                justifyContent={'space-between'}
                textAlign={'start'}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  Are any documents required to undergo Health Checkup via DLab?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              You are required to carry the following documents:
              <br /> • Appointment confirmation letter (Available in your Email
              or the Track Your Orders section) <br />• Government-issued Photo
              ID Card (Aadhar Card / Passport etc)
              <br />• Doctors Prescription if required
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem width={'100%'} justifyContent={'space-between'}>
            <h2>
              <AccordionButton
                // px={[4, 8]}
                py={[3, 5]}
                width={'100%'}
                justifyContent={'space-between'}
                textAlign={'start'}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  What should I do before a Health Checkup?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              You must follow the mentioned practices before a Health Checkup:
              <br />• Unless otherwise specified, you must fast overnight for 12
              hours before the appointment. Only water can be consumed. <br />•
              Avoid smoking or consuming alcohol for a minimum of 24 hours
              before the Health Checkup. <br />
              • Women should not undergo Health Checkups at the time of
              menstruation.
              <br />
              • Pregnant women should not undertake X-Ray.
              <br /> • You may carry urine & stool samples in a sterilized
              container obtainable from the nearest pharmacy. (if applicable){' '}
              <br />• Specific to Centre visit Health Checkup:
              <br /> • Registration is mandatory at the medical centre; this may
              take 15-20 minutes.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem width={'100%'} justifyContent={'space-between'}>
            <h2>
              <AccordionButton
                // px={[4, 8]}
                py={[3, 5]}
                width={'100%'}
                justifyContent={'space-between'}
                textAlign={'start'}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  In case of advance payment, will I be refunded for not making
                  an appearance on the appointment date?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Yes
              <br />• Please go through the -
              <Text textDecoration={'underline'} color={'teal'} as={'span'}>
                <a href="/refund">Refund policies</a>
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        {/* <Link color={'#0C727E'}>view more</Link> */}
      </VStack>
    </VStack>
  );
};
