import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { CartContext } from '../../../context/cart';

export const CartStepper = () => {
  const { activeStep, setActiveStep } = useContext(CartContext);

  const steps = [
    { title: 'Cart Items' },
    { title: 'Address' },
    { title: 'Date & Time' },
    { title: 'Order Details' },
    { title: 'Payment' },
  ];

  // const { activeStep } = useSteps({
  //   index: 1,
  //   count: steps.length,
  // });

  return (
    <Stepper
      index={activeStep}
      px={['5', '12', '24', '24']}
      my={['30px']}
      colorScheme="teal"
      justifyContent={'space-between'}
      size={['sm', 'sm', 'md', 'md']}
    >
      {/* {activeStep} */}
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator
            sx={{
              border:
                activeStep === index
                  ? '1px solid teal!important'
                  : activeStep > index
                  ? '1px solid teal!important'
                  : '1px solid rgba(0, 0, 0, 0.5)!important',
              backgroundColor:
                activeStep <= index ? 'transparent' : 'teal!important',
              cursor: 'pointer',
            }}
            onClick={() => setActiveStep(() => index + 1)}
          >
            <StepStatus
              complete={
                <StepIcon h={['10px', '15px']} width={['10px', '15px']} />
              }
              incomplete={
                <StepNumber
                  style={{
                    fontSize: ['8px', '12px'],
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}
                />
              }
              active={
                <StepNumber
                  style={{
                    fontSize: ['8px', '12px'],
                    color: 'teal',
                    borderColor: 'teal',
                  }}
                />
              }
            />
          </StepIndicator>

          {activeStep === index + 1 && (
            <Box flexShrink="0" position={'absolute'} top={'10'} left={'-3'}>
              <StepTitle
                style={{
                  color: activeStep >= index ? 'teal' : 'rgba(0, 0, 0, 0.5)',
                  fontSize: '12px',
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
                onClick={() => setActiveStep(() => index + 1)}
              >
                {step.title}
              </StepTitle>
            </Box>
          )}

          <StepSeparator
            _horizontal={[{ ml: '0', mr: '0' }, { ml: '0', mr: '0' }, {}, {}]}
          />
        </Step>
      ))}
    </Stepper>
  );
};
