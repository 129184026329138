import { Box, HStack, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const STEPS = [
  {
    title: 'Step 1.',
    description: 'Find Our Labs',
    image: '/assets/landing/steps/1.png',
    link: '/labs',
  },
  {
    title: 'Step 2.',
    description: 'Book A Test',
    image: '/assets/landing/steps/2.png',
    link: '/tests',
  },
  {
    title: 'Step 3.',
    description: 'Download Report',
    image: '/assets/landing/steps/3.png',
    link: '/profile/orders?status=completed',
  },
];

export const LandingSteps = () => {
  const navigate = useNavigate();
  return (
    <VStack
      textAlign={['center', 'center']}
      width={['full']}
      px={[5, 10]}
      py={['16', '28']}
      spacing={[10, '24']}
    >
      <Heading fontSize={[20, 30]}>
        Obtaining Your Diagnostic Report from a Nearby Lab in 3 Simple Steps
      </Heading>
      <Stack
        flexDir={['column', 'column', 'row', 'row', 'row']}
        width={'100%'}
        spacing={[16, 20]}
        justifyContent={[
          'center',
          'center',
          'space-evenly',
          'space-evenly',
          'space-evenly',
        ]}
      >
        {STEPS.map((s, i) => (
          <VStack spacing={3} key={i}>
            <motion.img
              whileTap={{ scale: 0.5 }}
              whileHover={{ scale: [null, 1.2, 1.1] }}
              transition={{ duration: 0.3 }}
              src={s.image}
              height={200}
              cursor={'pointer'}
              width={200}
              sx={{
                transition: '.2s ease-in',
              }}
              onClick={() => navigate(s?.link)}
            />
            <Box
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
              onClick={() => navigate(s?.link)}
              cursor={'pointer'}
            >
              <Heading fontSize={21} as="h4">
                {s.title}
              </Heading>
              <Text fontSize={17} as="h6">
                {s.description}
              </Text>
            </Box>
          </VStack>
        ))}
      </Stack>
    </VStack>
  );
};
