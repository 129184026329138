import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_MESSAGING_APP_ID,
  measurementId: process.env.REACT_APP_MESSAGING_MEASUREMENT_ID,
};

// Initialize Firebase

// const firebaseConfig = {
//   apiKey: 'AIzaSyCKzQX7vdNYtITYdTFOW_INtgutZkyagOI',
//   authDomain: 'firstapp-4e575.firebaseapp.com',
//   projectId: 'firstapp-4e575',
//   storageBucket: 'firstapp-4e575.appspot.com',
//   messagingSenderId: '477112560437',
//   appId: '1:477112560437:web:2ac6bf649477635c7cba7c',
//   measurementId: 'G-23R829JF4K',
// };

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
