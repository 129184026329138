import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import useStore from '../../../store/store';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineShoppingCart, AiOutlineUser } from 'react-icons/ai';
import { IoIosLogOut } from 'react-icons/io';
import { motion } from 'framer-motion';
import { CartContext } from '../../../context/cart';
import { useContext } from 'react';

export const Navigations = ({ setIsOpen }) => {
  const { setSignin, user, setUser } = useStore();
  const { cartPlans } = useContext(CartContext);
  const navigate = useNavigate();
  const toast = useToast();
  // console.log('calling user in the navigations', user);

  const breakpoint = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });

  const onClickHandler = () => {
    if (breakpoint === 'sm') {
      setIsOpen(false);
    }
  };

  

  const IconButtonClickHandler = url => {
    if (breakpoint === 'sm') {
      setIsOpen(false);
    }
    navigate(`${url}`);
  };

  const loginHandler = () => {
    if (breakpoint === 'sm') {
      setIsOpen(false);
    }
    console.log('calling login');
    setSignin(true);
  };

  const logoutHandler = () => {
    if (breakpoint === 'sm') {
      setIsOpen(false);
    }
    localStorage.clear();
    setUser(null);
    navigate('/');

    // console.log('calling user after logged out', user);
    // let newUser = localStorage.getItem('user');
    // console.log('calling new user after loggedout ', newUser);
    toast({
      title: 'logged out successfully',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Stack
      width={['100%', '']}
      flexDirection={['column-reverse', 'row']}
      justifyContent={['center', 'space-between']}
      alignItems={'center'}
      gap={'5'}
    >
      <Link
        to={'/about'}
        sx={{
          opacity: '70%',
        }}
        _hover={{
          opacity: '100%',
        }}
        onClick={() => onClickHandler}
      >
        About Us
      </Link>

      <Link
        to={'/contactus'}
        sx={{
          opacity: '70%',
        }}
        _hover={{
          opacity: '100%',
        }}
        onClick={() => onClickHandler}
      >
        Contact Us
      </Link>

      {cartPlans?.length === 0 ? (
        <IconButton
          colorScheme="teal"
          variant={'ghost'}
          display={['flex']}
          justifyContent={['flex-start', 'center']}
          onClick={() => IconButtonClickHandler('/cart')}
          p={1}
          borderRadius={'50px'}
        >
          <AiOutlineShoppingCart size={'20px'} />
        </IconButton>
      ) : (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            colorScheme="teal"
            variant={'ghost'}
            display={['flex']}
            justifyContent={['flex-start', 'center']}
            onClick={() => IconButtonClickHandler('/cart')}
            p={1}
            borderRadius={'50px'}
          >
            <AiOutlineShoppingCart size={'20px'} />
          </IconButton>
          <Box
            position="absolute"
            width="18px"
            h="18px"
            backgroundColor="red"
            borderRadius="100%"
            top="-15%"
            right="-15%"
            color={'white'}
          >
            <Text fontSize={'12px'} textAlign={'center'}>
              {cartPlans?.length}
            </Text>
          </Box>
        </Box>
      )}

      <Divider
        orientation={['horizontal', 'vertical']}
        height={'8'}
        display={['none', 'block']}
      />
      {user === null ? (
        <motion.div whileTap={{ scale: 0.8 }} onClick={loginHandler}>
          <Button variant={'outline'} colorScheme="teal">
            Login
          </Button>
        </motion.div>
      ) : (
        <>
          <Menu>
            <MenuButton
              display={['flex']}
              justifyContent={['flex-start', 'center']}
              alignItems={'flex-start'}
              as={Button}
              // rightIcon={<BiUser />}
              sx={{
                borderRadius: '100%',
                width: '40px',
                height: '40px',
                padding: 3,
              }}
            >
              <AiOutlineUser />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => IconButtonClickHandler('/profile')}
                gap={2}
              >
                <AiOutlineUser size={'23px'} />
                <Text mt={1.5}>Profile</Text>
              </MenuItem>
              <MenuItem onClick={logoutHandler} gap={2}>
                <IoIosLogOut size={'23px'} />
                <Text mt={1.5}>Logout</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </Stack>
  );
};
