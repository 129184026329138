import { Box, Flex, Heading, Input, Spinner, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { getLabs, searchlabs } from '../../../api/labs';
import useLabsStore from '../../../store/labsStore';
import { useNavigate } from 'react-router-dom';

import { LabCard } from '../Lab/LabCard';

export const AllLabs = () => {
  const { labs, setLabs } = useLabsStore();
  const [loading, setLoading] = useState('false');
  const [keyword, setValue] = useState('');

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await getLabs();
        setLabs(res);
      } catch (error) {
        console.error('error while loading labs', error);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const handleSearch = async e => {
    e.preventDefault();
    setValue(e.target.value);
    const res = await searchlabs(keyword);
    setLabs(res);
  };

  return (
    <VStack
      p={[3, 10]}
      spacing={12}
      alignItems={'center'}
      width={'100%'}
      justifyContent={'center'}
    >
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[24, 30]}>
          Explore All Labs
        </Heading>
        <Input
          variant="outline"
          placeholder="Search For Labs"
          size={['md', 'lg']}
          width={['80vw', '35vw']}
          onChange={e => handleSearch(e)}
        />
      </Flex>
      {loading ? (
        <Box
          display={'flex'}
          width={'100%'}
          h={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
          py={'32'}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#0C727E"
            size="xl"
          />
        </Box>
      ) : (
        <VStack
          width={'100%'}
          spacing={[6, 8]}
          sx={{
            display: 'grid',
            width: '100%',
            gridTemplateColumns: [
              '1fr',
              '1fr',
              '1fr 1fr',
              '1fr 1fr',
              '1fr 1fr',
            ],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {labs.map((lab, i) => {
            return <LabCard lab={lab} index={i} />;
          })}
        </VStack>
      )}
    </VStack>
  );
};
