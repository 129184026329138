import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  Input,
  VStack,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCartStore from '../../../../../store/cartStore';
import { CartContext } from '../../../../../context/cart';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';

export const TestCard = ({ test }) => {
  const navigate = useNavigate();
  const { cart, setCart, removeItem } = useCartStore();
  const [loading, setLoading] = useState();
  const [addTocart, setAddToCart] = useState();
  const toast = useToast();
  const { addToCartPlans, removeFromCart } = useContext(CartContext);

  useEffect(() => {
    setAddToCart(cart?.includes(test));
  }, []);

  useEffect(() => {
    setAddToCart(cart?.includes(test));
  }, [cart]);
  return (
    <Card
      maxW={['full', 'lg']}
      px={['0', '3']}
      py={1}
      borderRadius={8}
      variant={'outline'}
      height={['auto', '80', '96', '80', '80']}
    >
      <CardBody>
        <Stack gap={3}>
          <Heading
            fontSize={[20, 25]}
            onClick={() => navigate(`/test?testId=${test?._id}`)}
            _hover={{ cursor: 'pointer' }}
          >
            {test?.name}
          </Heading>
          <Text fontSize={['md', 'md']} textAlign={'justify'}>
            {test?.description.slice(0, 250)}
            {test?.description.length > 250 && '...'}
          </Text>
        </Stack>
      </CardBody>
      <CardFooter
        width={'100%'}
        py={'5'}
        justify="space-between"
        flexWrap="wrap"
        sx={{
          '& > button': {
            minW: '136px',
          },
        }}
      >
        <HStack width={'full'} justifyContent={'space-between'}>
          <Box>
            <Heading fontSize={[15, 18]} color={'#1DC2D5'}>
              ₹ {test?.price}
            </Heading>
            <Heading
              fontSize={[15, 18]}
              display={'flex'}
              gap={2}
              color={'#0C727E'}
            >
              ₹ <Text textDecor={' line-through'}>1048.55</Text>
            </Heading>
          </Box>
          {addTocart ? (
            <Box
              sx={{
                display: 'flex',
                gap: 5,
              }}
            >
              <IconButton colorScheme="teal" onClick={() => navigate('/cart')}>
                <AiOutlineShoppingCart size={'20px'} />
              </IconButton>
              <IconButton
                onClick={() => {
                  removeItem(test._id);
                  removeFromCart(test);
                  toast({
                    title: 'Remove Form Cart!',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                  });
                }}
              >
                <AiOutlineDelete />
              </IconButton>
            </Box>
          ) : (
            <Button
              isLoading={loading}
              bg={'#0C727E'}
              color={'#fff'}
              _hover={{}}
              onClick={() => {
                setLoading(true);
                setCart([test, ...cart]);
                addToCartPlans(test);
                setLoading(false);
                toast({
                  title: 'Added To Cart!',
                  status: 'success',
                  isClosable: true,
                  duration: 5000,
                });
              }}
            >
              Add to Cart
            </Button>
          )}
        </HStack>
      </CardFooter>
    </Card>
  );
};
