import { Box, Heading, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

export default function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box p={20}>
      <Heading pb={10}>Terms of Use</Heading>
      <Text>
        Dilate Laboratory is engaged in providing diagnostic
        services to the patient through the digital network of its associated
        diagnostic centers. These services are provided through our website, app
        or phone as the case may be. A user, by using our services, consent to
        our privacy policy, term of use and declaimer as presented on
        www.dilatelabs.com. The services provided by www.Dilatelabs.com are in the nature of aggregation with the
        technology intermediation. We are not providing any medical assessment
        or advice whatsoever. Though we takes all precautions to ensure that
        only high quality diagnostic labs are allowed to tie-up with us, dilate
        laboratory does not take any responsibility for the accuracy and quality
        of the service provided by the labs registered / tied up with us .We are
        merely an intermediary that is acting on good faith. Dilate laboratory
        is not liable for any medical assessment based on the reports of
        diagnostic tests booked through us at our partner labs. The patients are
        solely responsible for maintaining the confidentiality of their own
        account to access information and password. The patients shall be
        responsible for all uses of the Users’ account and password, whether or
        not authorized by the Users. The patients shall immediately notify
        dilate laboratory of any actual or suspected unauthorized use of the
        Users’ account or password. dilate laboratory will try to ensure that
        the diagnostic lab where a booking has been done through us, adheres to
        the timelines and the other promised services including for home
        collection, lab visit and providing test report. However, as these are
        outside of dilate laboratory control in many cases, and the liability
        lies with the service provider only. <br/>Disclaimer <br/> Web world is a huge
        place and due to absence of use of encryption in internet, the internet
        is not considered as a secure medium and 100% privacy cannot be ensured.
        Information provided by the user to the company website will be received
        by the company on a non-confidential basis. We recommend the user to use

        appropriate security, antivirus, and other protective softwares.Web
        world is a huge place and due to absence of use of encryption in
        internet, the internet is not considered as a secure medium and 100%
        privacy cannot be ensured. Information provided by the user to the
        company website will be received by the company on a non-confidential
        basis. We recommend the user to use appropriate security, antivirus, and
        other protective softwares. All the information provided on dilate
        laboratory platform is on best effort basis. Though, we take utmost care
        to maintain accuracy of the information provided, but some inadvertent
        error could not be ruled out. Thus patient may, in case of confusion,
        may contact lab or dilate laboratory customer care. Patient authorised
        partner lab to share reports and relevant data with dilate laboratory.
        Patient consent is implied once patient is referred to partner lab by
        dilate laboratory private limited.      </Text>
    </Box>
  );
}
