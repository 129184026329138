import { create } from 'zustand';

const usePackageStore = create(set => ({
  packages: [],
  setPackages: e =>
    set(state => ({
      packages: e,
    })),
}));

export default usePackageStore;
