import { create } from 'zustand';

const useStore = create(set => ({
  user: null,
  loading: false,
  signup: false,
  signin: false,
  setLoading: e => set(state => ({ loading: e })),
  setUser: e =>
    set(state => ({
      user: e,
    })),
  setSignup: e => set(state => ({ signup: e })),
  setSignin: e => set(state => ({ signin: e })),
}));

export default useStore;
