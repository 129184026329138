import React, { useContext } from 'react';
import { Item } from './Items';
import { Avatar, Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import useCartStore from '../../../../store/cartStore';
import { AiFillDelete } from 'react-icons/ai';
import { CartContext } from '../../../../context/cart';

export const Patient = () => {
  const { selectedPatients, removePatients } = useCartStore();

  const {
    cartPlans,
    setAddPatientsDrawer,
    setSelectedItem,
    removePatientFromCartItem,
  } = useContext(CartContext);

  function calculateAge(birthdate) {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000);
    const age = Math.floor(ageInYears);
    return age;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 12,
        // border: '3px solid #f2f2f2',
        p: 5,
        gap: 5,
      }}
      width={['100%', '90%', '80%', '73%']}
    >
      {selectedPatients?.map((patient, i) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Flex key={i} alignItems={'center'} gap={2}>
              <Avatar size={'sm'} />
              <Box>
                <Text fontSize={20}>{patient.name}</Text>
                <Text fontSize={14}>
                  {patient.tag}, {patient.gender},{' '}
                  {calculateAge(patient.birthdate)} Years
                </Text>
              </Box>
            </Flex>
            <IconButton
              variant={'ghost'}
              color={'red'}
              borderRadius={50}
              onClick={() => removePatients(i)}
            >
              <AiFillDelete />
            </IconButton>
          </Box>
        );
      })}

      {cartPlans?.map((data, i) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 16,
              border: '3px solid #f2f2f2',
              px: '5',
              py: '5',
              gap: 5,
            }}
          >
            {data?.patients?.map(patient => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDir: 'column',
                    }}
                  >
                    <Text
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      {patient?.name}
                    </Text>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Text
                        sx={{
                          fontSize: '12px',
                          color: 'rgba(0, 0, 0, 0.5)',
                          fontWeight: 600,
                        }}
                      >
                        {patient?.gender.slice(0, 1).toUpperCase()}
                        {patient?.gender.slice(1)}
                      </Text>
                      <Text
                        sx={{
                          fontSize: '12px',
                          color: 'rgba(0, 0, 0, 0.5)',
                          fontWeight: 550,
                        }}
                      >
                        {calculateAge(patient?.birthdate)} Years
                      </Text>
                    </Box>
                  </Box>
                  <IconButton
                    sx={{
                      backgroundColor: 'darkred',
                      borderRadius: '100%',
                      _hover: {
                        backgroundColor: 'darkred',
                      },
                    }}
                    onClick={() => {
                      setSelectedItem(() => ({ data: data, patient: patient }));
                      removePatientFromCartItem(data, patient);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      style={{
                        width: '15px',
                        height: '15px',
                        color: 'white',
                      }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </IconButton>
                </Box>
              );
            })}
            <Item data={data} key={i} index={i} />
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                backgroundColor: 'transparent',
                border: '1px solid teal',
                borderRadius: '20px',
                color: 'teal',
                _hover: {
                  backgroundColor: 'teal',
                  color: 'white',
                },
              }}
              onClick={() => {
                setSelectedItem(() => data);
                setAddPatientsDrawer(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
              </svg>
              Add Patients
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};
