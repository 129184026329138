import { Box } from '@chakra-ui/react';
import React from 'react';
import { AppointmentDetails } from '../AppointmentDetails';
import { OrderDetails } from '../OrderDetails';
import { LabDetail } from '../LabDetail';

export const CheckoutSection = () => {
  return (
    <Box
      sx={{
        flex: 7,
        display: 'flex',
        flexDir: 'column',
        gap: '30px',
        color: 'black',
        width: '100%',
      }}
    >
      <AppointmentDetails />
      <OrderDetails />
      <LabDetail></LabDetail>
    </Box>
  );
};
