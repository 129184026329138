import { Box } from '@chakra-ui/react';
import React from 'react';
import { SelectDate } from './SelectDate';
import { SelectTime } from './SelectTime';

export const DateSection = () => {
  return (
    <Box color={'black'} maxWidth={'100%'} overflowX={'hidden'} flex={'7'}>
      <Box
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          padding: ['20px', '30px', '30px', '30px'],
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          flex: '7',
        }}
      >
        <SelectDate />
        <SelectTime />
      </Box>
    </Box>
  );
};
