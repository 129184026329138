import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/layout';
import { labDetails } from '../api/labs';
import { Lab } from '../components/Labs/Lab';
import { Footer } from '../components/Footer';
import { FAQ } from '../components/Landing/FAQ';
import { FloatingButton } from '../components/Global';

export const LabPage = () => {
  const [lab, setLab] = useState('');
  const location = useLocation();

  // console.log('calling test location obeject', location);

  const searchObject = new URLSearchParams(location?.search);

  // console.log('calling searchOBject', searchObject);

  const labId = searchObject.get('labId');

  useEffect(() => {
    const fetchData = async () => {
      const res = await labDetails(labId);
      setLab(res);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDir: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        gap: 10,
        pt: 10,
      }}
    >
      <Lab lab={lab} />
      <FloatingButton />
      <FAQ />
      <Footer />
    </Box>
  );
};
