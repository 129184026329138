import { Flex, Heading, Input, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { getTests, searchTests } from '../../../api/tests';
import useTestStore from '../../../store/testStore';
import { TestCard } from '../../Landing/Tests/TestCard';

export const AllTests = () => {
  const { tests, setTests } = useTestStore();
  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await getTests();
      setTests(res);
    };
    fetchData();
  }, []);

  const handleSearch = async e => {
    e.preventDefault();
    const res = await searchTests(e.target.value);
    setTests(res);
  };

  return (
    <VStack p={[7, 10]} spacing={12}>
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[24, 30]}>
          Explore All Tests
        </Heading>
        <Input
          variant="outline"
          placeholder="Search For Test"
          size={'lg'}
          width={['80vw', '35vw']}
          onChange={e => handleSearch(e)}
        />
      </Flex>
      <VStack
        spacing={[6, 8]}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['auto', 'auto auto'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tests.map((test, i) => {
          return <TestCard test={test} key={i} />;
        })}
      </VStack>
    </VStack>
  );
};
