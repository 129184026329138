import axios from 'axios';

export const getReviews = async labId => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER}/getreviews?labId=${labId}`
    );

    return res.data.reviews;
  } catch (error) {
    console.error('error while getting reviews', error);
  }
};
