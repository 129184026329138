import { useContext, useEffect, useState } from 'react';
import { Switch, Flex, Text, HStack, Stack } from '@chakra-ui/react';
import { AiFillHome } from 'react-icons/ai';
import { ImLab } from 'react-icons/im';
import { CartContext } from '../../../../context/cart';

export const SampleSwitch = () => {
  const [isChecked, setIsChecked] = useState(true);
  const { setOrderDetail } = useContext(CartContext);
  const { cartPlans } = useContext(CartContext);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  // useEffect to change the sampleMode
  useEffect(() => {
    isChecked
      ? setOrderDetail(orderDetail => ({ ...orderDetail, sampleMode: 'home' }))
      : setOrderDetail(orderDetail => ({ ...orderDetail, sampleMode: 'lab' }));
  }, [isChecked, cartPlans]);

  //   console.log('calling order data in switch', orderDetail);
  return (
    <Flex
      align="center"
      width={['100%', '90%', '80%', '73%']}
      p={'5'}
      bg="gray.100"
      color="blackAlpha.400"
      borderRadius="12"
    >
      <Stack
        flex={1}
        flexDirection={['column', 'column', 'column', 'row-reverse']}
        justifyContent={['center', 'center', 'center', 'flex-start']}
        alignItems={['center', 'center', 'center', 'center']}
        width={'100%'}
        fontSize={['12', '16']}
        color={isChecked ? 'gray' : 'red'}
        mr={['0', '2']}
      >
        <ImLab size={'20px'} />
        <Text textAlign={'center'}>Lab Collection</Text>
      </Stack>
      <Switch
        colorScheme="red"
        size="md"
        isChecked={isChecked}
        onChange={handleChange}
      />
      <Stack
        flex={1}
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent={['center', 'center', 'center', 'flex-start']}
        alignItems={['center', 'center', 'center', 'center']}
        width={'100%'}
        fontSize={['12', '16']}
        color={isChecked ? 'red' : 'gray'}
        ml={['0', '2']}
      >
        <AiFillHome size={'22px'} />
        <Text textAlign={'center'}>Home Collection</Text>
      </Stack>
    </Flex>
  );
};
