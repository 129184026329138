import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Heading,
  Select,
  Spinner,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { TestCard } from '../components/UserOrders/TestCard';
import { PackageCard } from '../components/UserOrders/PackageCard';
import { getUser } from '../cookie/user';
import { getUserOrders } from '../api/order';
import { useLocation, useNavigate } from 'react-router-dom';

export const OrdersPage = () => {
  const user = getUser();

  const location = useLocation();
  const navigate = useNavigate();

  // console.log('calling test location obeject', location);

  const searchObject = new URLSearchParams(location?.search);

  // console.log('calling searchOBject', searchObject);

  const status = searchObject.get('status');
  const [allOrders, setAllOrders] = useState([]);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (user?._id) {
      try {
        setLoading(true);
        const res = await getUserOrders(user?._id);
        setAllOrders(res);
        if (!status) {
          navigate(`/profile/orders?status=pending`);
          const pendingOrders = res?.filter(
            order => order?.orderStatus === 'pending'
          );
          setDisplayedOrders(pendingOrders);
        } else {
          orderFiltering(status);
        }

        // console.log(user._id);
        // console.log('calling orders for the user in orders page', res);
      } catch (error) {
        console.error('Error while fetching orders');
      } finally {
        setLoading(false);
      }
    }
  };

  const orderFiltering = async status => {
    try {
      setLoading(true);
      if (status === 'completed') {
        const completedOrders = allOrders?.filter(
          order => order?.orderStatus === 'completed'
        );
        setDisplayedOrders(completedOrders);
      } else if (status === 'cancelled') {
        const cancelledOrders = allOrders?.filter(
          order => order?.orderStatus === 'cancelled'
        );
        setDisplayedOrders(cancelledOrders);
      } else {
        const pendingOrders = allOrders?.filter(
          order => order?.orderStatus === 'pending'
        );
        setDisplayedOrders(pendingOrders);
      }
    } catch (error) {
      console.error('error while filtering orders', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = e => {
    const status = e.target.value;
    setSelectedStatus(status);

    navigate(`/profile/orders?status=${status}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const status = searchObject?.get('status');
    orderFiltering(status);
    setSelectedStatus(status);
  }, [location, allOrders]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '60vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size={'lg'} />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            maxWidth: '100%',
            height: '85vh',
            flexDir: 'column',
            px: ['3', '5', '5', '5'],
            py: 5,
            gap: 5,
            marginLeft: ['0px', '0px', '260px', '260px'],
            overflowY: 'scroll',
            zIndex: '1000',
            pb: '100px',
          }}
        >
          <VStack
            justifyContent={'center'}
            alignItems={'center'}
            gap={'12'}
            paddingBottom={'12'}
          >
            <Heading color={'#0C727E'}>My Orders</Heading>
            <Stack
              width={'100%'}
              flexDirection={'column'}
              alignItems={'center'}
              spacing={'12'}
            >
              <Select
                width={['60vw', '40vw', '35vw', '30vw']}
                onChange={handleStatusChange}
                value={selectedStatus}
              >
                <option value="pending">Pending Orders</option>
                <option value="completed">Completed Orders</option>
                <option value="cancelled">Cancelled Orders</option>
              </Select>

              {displayedOrders?.length === 0 ? (
                <Heading>No {selectedStatus} orders</Heading>
              ) : (
                <>
                  <Grid
                    gap={'12'}
                    gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
                    justifyContent={'center'}
                  >
                    {displayedOrders?.map(order => {
                      if (order?.test) {
                        return <TestCard test={order?.test} order={order} />;
                      }
                      return;
                    })}
                  </Grid>
                  <Grid
                    gap={'12'}
                    gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
                    justifyContent={'center'}
                  >
                    {displayedOrders?.map(order => {
                      if (order?.packages?.tests) {
                        return (
                          <PackageCard
                            packages={order?.packages}
                            order={order}
                          />
                        );
                      }
                      return;
                    })}
                  </Grid>
                </>
              )}
            </Stack>
          </VStack>
        </Box>
      )}
    </>
  );
};
