import React, { useEffect, useState } from 'react';
// import { TopMain} from './'
import { Box } from '@chakra-ui/react';
import { Footer } from '../components/Footer';
import { FAQ } from '../components/Landing/FAQ';
import { PatientInstructions } from '../components/Tests/Test/PatientInstructions';
import { LabInformation } from '../components/Tests/Test/LabInformation';
import { MostSearchTests } from '../components/Tests/Test/MostSearchTests';
import { TestsCard } from '../components/Tests/Test/TestCard';
import { FloatingButton } from '../components/Global';
import { testDetails } from '../api/tests';
import { useLocation } from 'react-router';

export const TestPage = () => {
  const location = useLocation();

  // console.log('calling test location obeject', location);

  const searchObject = new URLSearchParams(location?.search);

  // console.log('calling searchOBject', searchObject);

  const testId = searchObject.get('testId');

  // console.log('calling testId', testId);

  const [test, setTest] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const res = await testDetails(testId);
      // console.log(`the test response is  : ${res}`);
      setTest(res);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      width={'full'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      <TestsCard test={test} />
      <PatientInstructions />
      <LabInformation />
      <FloatingButton />
      <MostSearchTests />
      <FAQ />
      <Footer />
    </Box>
  );
};
