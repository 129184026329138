import axios from 'axios';

export const createOrder = async orderData => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER}/createOrder`,
      orderData
    );
    return res;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getOrderDetails = async orderId => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER}/orders/${orderId}`
    );
    const order = res.data.order;
    return order;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};

export const getUserOrders = async userId => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER}/orders/user/${userId}`
    );
    const orders = res?.data?.userOrders;
    return orders;
  } catch (error) {
    console.error('errror fetching user orders', error);
  }
};
