import { Box, Heading, Text } from '@chakra-ui/react';
export default function RefundPage() {
    return (
      <Box p={20}>
        <Heading pb={10}>Refund & Cancelation Policy</Heading>
        <Text>
          Our focus is on complete customer satisfaction. In the event, if you
          are displeased with the services provided or we are unable to provide
          the service, we will refund back the money, provided the reasons are
          genuine and proved after the investigation. In case of dissatisfaction
          from services provided by our lab partners, customers have the liberty
          to cancel the booking before the test or start of the home visit for
          sample collection. However, no refund shall be admissible after the
          test has been completed. In case of any grievances with regards to the
          service provided by lab partners, customers are encouraged to speak to
          the concerned person at the lab. Though, Dilatelabs shall provide full
          assistance for coordination with the labs. Though we take all the care
          to display MRP, Discount & lab information correctly on our website,
          sometimes, an inadvertent error may found/ occur. In all such case,
          the customer is free to cancel the booking & full refund (in cases
          where payment has been made to Dilatelabs laboratory private limited directly) shall
          be admissible. However, if the customer chooses to proceed with the
          booking, Dilatelabs shall provide compensation of 10% of the payable
          amount subject to the ceiling of Rs 100. All such claims shall be
          processed once the customer submits the lab payment receipt. Our
          Policy for the cancellation and refund will be as follows:
          Cancellation Policy • Lab tests where lab visit has been opted by the
          customers, Customers are free to cancel the booking before the
          start/preparation of lab tests. A full refund shall be admissible in
          cases where payment has been made to Dilatelabs directly. •  In cases
          where only partial tests from the booking have been done, a refund
          shall be admissible to such an extent. • Lab tests/ health packages
          where home visit has been opted by the customers, Customers are free
          to cancel the tests prior to the start of the visit, a full refund
          shall be provided. However, in case of customer choose to cancel the
          test after the arrival of a technician at the site, then Rs 200/-
          shall be deducted as visit charges. • In all other cases, refund
          policies of our lab partner shall be applicable.  For Cancellations
          please contact us at or email us at info@ Dilatelabs.com. Refund
          Policy If paid by credit card, refunds will be issued to the original
          credit card provided at the time of purchase and in case of payment
          gateway payments refund will be made to the same account. The refund
          shall be provided within 7-15 working days wherever applicable.
        </Text>
      </Box>
    );
}
