import { useEffect, useState } from 'react';
import { getReviews } from '../../../../api/reviews';
import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Image,
  Stack,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { StarIcon } from '@chakra-ui/icons';
import useLabsStore from '../../../../store/labsStore';

export const LabCard = ({ lab, index, onClose }) => {
  const navigate = useNavigate();
  const [reviewsData, setReviewsData] = useState([]);
  const [selected, setSelected] = useState([]);
  const { setLabData } = useLabsStore();

  const totalRatings = reviewsData?.length;
  const averageRating =
    totalRatings === 0
      ? 0
      : reviewsData?.reduce((acc, review) => acc + review?.ratingStar, 0) /
        totalRatings;

  const ratingTextMap = {
    1: 'Terrible',
    2: 'Bad',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
  };

  const fetchReviews = async () => {
    const res = await getReviews(lab?._id);

    // console.log("calling get reviews res", res);
    setReviewsData(res);
  };

  const selectLabHandler = () => {
    setLabData(lab);
    onClose();
    console.log('select lab handler callled');
    setSelected(true);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <Card
      key={index}
      maxW={'100%'}
      variant="outline"
      display={'flex'}
      border={'1px solid rgb(180,180,180,1)'}
    >
      <Stack
        minH={['340px', '300px', '560px', '290px', '290px']}
        h={'100%'}
        width={'100%'}
        p={['3', '5']}
        gap={'3'}
        flexDirection={['column', 'column', 'column', 'row', 'row']}
      >
        <Image
          display={['flex', 'flex', 'flex', 'none', 'flex']}
          borderRadius={5}
          objectFit="cover"
          width={['100%', '100%', '100%', '50%', '20vw']}
          h={['30vw', '30vw', '100%', '50%', '20vw']}
          minH={['100%']}
          src="/Labs.jpg"
          alt="DLab partner"
          cursor={'pointer'}
        />

        <CardBody
          p={'0'}
          maxHeight={'fit-content'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          width={'100%'}
          gap={'3'}
        >
          <VStack
            width={['100%', '100%', '100%', '100%', '20vw']}
            h={'auto'}
            alignItems={['flex-start']}
          >
            <Heading
              size="md"
              onClick={() => navigate(`/lab?labId=${lab?._id}`)}
              cursor={'pointer'}
            >
              {lab?.labName}
            </Heading>

            <Text
              color={'gray'}
              onClick={() => navigate(`/lab?labId=${lab?._id}`)}
            >
              {lab?.labAddress?.placeName?.slice(0, 60)}
              {lab?.labAddress?.placeName?.length > 60 && ' ...'}
            </Text>
          </VStack>
          <VStack
            width={'100%'}
            justifyContent={'center'}
            gap={'3'}
            alignItems={'flex-start'}
          >
            <Box
              display={'flex'}
              gap={'3'}
              flexWrap={'wrap'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              width={'100%'}
            >
              <Tag backgroundColor="green.400" color={'white'} size={'lg'}>
                {averageRating.toFixed(1)}
              </Tag>
              <HStack gap={'2'}>
                {[...Array(5)].map((_, index) => (
                  <StarIcon
                    key={index}
                    color={
                      index < Math.floor(averageRating) ? '#ffd700' : 'gray.300'
                    }
                  />
                ))}
              </HStack>
              <Text fontSize="14px" fontWeight={'500'}>
                {totalRatings === 0
                  ? 'No Ratings'
                  : ratingTextMap[Math.floor(averageRating)]}
              </Text>
              <Text
                display={['none', 'none', 'none', 'flex', 'flex']}
                fontSize="14px"
              >
                ({totalRatings} Ratings)
              </Text>
            </Box>
            <HStack alignItems="center" width={'100%'} flexWrap={'wrap'}>
              <Text>{`Opens at ${lab?.openingTime}:00 am - ${lab?.closingTime}:00 pm`}</Text>
              <Text color="#1DC2D5">{lab?.workingDays}</Text>
            </HStack>
            <Box
              sx={{
                display: 'flex',
                flexDir: 'column',
                gap: 3,
              }}
            >
              <Box
                sx={{
                  color: 'gray',
                  fontWeight: '500',
                  display: 'flex',
                  gap: 3,
                  flexDir: ['column', 'row', 'column', 'column', 'column'],
                }}
              >
                <Text
                  cursor={'pointer'}
                  onClick={() => navigate(`/lab?labId=${lab?._id}`)}
                  sx={{
                    py: 1,
                    width: '40',
                    borderRadius: 5,
                    border: '1px solid gray',
                    textAlign: 'center',
                    bg: '#E2E8F0',
                  }}
                >
                  Popular blood test
                </Text>
                <Text
                  onClick={() => navigate(`/lab?labId=${lab?._id}`)}
                  sx={{
                    cursor: 'pointer',
                    p: 1,
                    width: '48',
                    borderRadius: 5,
                    border: '1px solid gray',
                    textAlign: 'center',
                    bg: '#E2E8F0',
                  }}
                >
                  Poular Health Package
                </Text>
              </Box>
              <Button
                colorScheme="teal"
                onClick={() => selectLabHandler()}
                variant={selected === true ? 'outline' : 'solid'}
              >
                {selected === true ? 'Selected' : 'Select This Lab'}
              </Button>
              {/* <Box
                sx={{
                  display: 'flex',
                  gap: 4,
                }}
              >
                <Button
                  variant="solid"
                  colorScheme="teal"
                  onClick={() => navigate(`/lab?labId=${lab?._id}`)}
                >
                  Visit Lab
                </Button>
                <Button variant="outline" colorScheme="teal">
                  Send Location To Phone
                </Button>
              </Box> */}
            </Box>
          </VStack>
        </CardBody>
      </Stack>
    </Card>
  );
};
