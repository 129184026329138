import { Box, Heading, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

export default function PrivacyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box p={20}>
      <Heading pb={10}>Privacy Policy</Heading>
      <Text>
        This is to bring to your notice that the website (www. This is to bring
        to your notice that the website (www.dilatelabs.com), its sub-domains
        and all related application and services are owned and operated by
        dilatelabs.com Our Privacy Policy is applicable to • A HEALTHCARE
        SERVICE PROVIDER WHO AGREES TO BE LISTED WITH dilatelabs • USERS(YOU)
        OR HIS RELATIVE/ATTENDANT WHO USE OUR SERVICES • ANYONE WHO USES OUR
        SERVICES IN ANY FORM (USERS OR YOU) • THIRD PARTIES (SUCH AS ADROLL) MAY
        PLACE COOKIES ON THEIR BROWSERS FOR TARGETED ADVERTISING PURPOSES. By
        using our services you are agreeing to the privacy policy and the terms
        and conditions contained below. The privacy policy and the terms and
        conditions constitute a legal agreement between dilatelabs.com and you
        in connection with the use and access to the Resources. Please read this
        privacy policy very carefully and DO NOT use our services if you do not
        agree with any of the points mentioned. If you are using
        dilatelabs.com’s services on behalf of a third party you represent that
        you are authorized to accept the conditions on behalf of any such third
        party. INTRODUCTION: This policy gives information about the type and
        amount of information collected from the Users, including sensitive
        personal data or information; the purpose of collection and modes of
        usage of such information; and how Dilatelabs.com will use such
        information. The utilization and access of our services is subjected to
        the acceptance of our privacy policy. Dilatelabs.COM is committed to
        protect the privacy of its Users, including all personal information. We
        will only reveal personal information whenever required to do so by the
        law, by the order of court, government law or statutory authority or
        similar requirements or to safeguard any assets or damage. Personal
        Information, Data Shared/ Information disclosed: Personal information
        that we collect, process and use in connection with the services include
        not only the information that we collect via various forms, but also the
        information that you voluntarily provide us in various contexts.
        Information collected by us from you may include (but is not limited to)
        the following: • NAME • LOGIN ID AND PASSWORD; • CONTACT INFORMATION
        (SUCH AS YOUR EMAIL ADDRESS, ADDRESSES, PHONE NUMBERS); • DEMOGRAPHIC
        DATA (SUCH AS YOUR GENDER, YOUR DATE OF BIRTH AND YOUR PIN CODE) • IP
        ADDRESS, OPERATING SYSTEM, BROWSER TYPE, BROWSER VERSION, BROWSER
        CONFIGURATION, NAME OF • INTERNET SERVICE PROVIDER, AND OTHER TYPES OF
        COMPUTER AND CONNECTION RELATED INFORMATION • RELEVANT TO IDENTIFYING
        YOUR TYPE OF DEVICE, CONNECTING TO THE WEBSITE, ENABLING DATA EXCHANGE •
        WITH YOU AND YOUR DEVICE, AND ENSURING A CONVENIENT USE OF THE WEBSITE;
        • HISTORIC DATA REGARDING YOUR USAGE OF OUR SERVICES; • SEARCH TERMS
        ENTERED; • INFORMATION COLLECTED BY COOKIES OR SIMILAR TECHNOLOGIES; •
        NEWSLETTER SUBSCRIPTIONS, ENROLMENT FOR PROMOTIONS, USE OF SPECIAL
        OFFERS, ETC. • SURVEY ANSWERS, REVIEWS, RATINGS AND OTHER TYPES OF
        FEEDBACK PROVIDED; • MEDICAL CREDENTIALS, VISITING HOURS OF A HEALTHCARE
        PROVIDER, FEES, LOCATIONS (IN RELATION TO HEALTHCARE PROVIDERS); •
        MEDICAL INFORMATION; • FINANCIAL AND PAYMENT RELATED DATA FOR PAYMENT OF
        SUBSCRIPTION AMOUNT AND CHARGES; • GEO-LOCATION INFORMATION WHEN
        PERMITTED BY YOU ON YOUR DEVICE; AND • INFORMATION COLLECTED THROUGH THE
        REGISTRATION FORM • ANY OTHER INFORMATION THAT YOU ENTER ON, OR UPLOAD
        IS VOLUNTARILY. Dilatelabs.com reserves the right to use information
        provided by you for the following: • PUBLISHING ON THE WEBSITE/MOBILE
        APPLICATION ETC. • CONTACTING YOU FOR OFFERING NEW PRODUCTS/SERVICES; •
        CONTACTING YOU TO OFFER NEW PRODUCTS/SERVICES BY OUR PARTNERS; •
        CONTACTING YOUR FOR FEEDBACK; • ANALYSIS AND REPORTING You hereby
        consent to use of such information by Dilatelabs.com. You are
        responsible for maintaining the accuracy of the information you submit
        to us, such as your contact information provided as part of account
        registration. If you provide any information that is untrue, inaccurate,
        out of date or incomplete (or becomes untrue, inaccurate, out of date or
        incomplete), or Dilatelabs.com suspects that the information provided by
        you is untrue, inaccurate, out of date or incomplete, it may, at its
        sole discretion, discontinue your usage of its services. If you do not
        want to receive any form of communication from us, please intimate us
        on info@ Dilatelabs.com Dilatelabs.com does not exercise control over
        the sponsored content or links. And hence, it is not liable for any type
        of information shared on any website. Dilatelabs.com may disclose
        personal information to the following: • dilatelabs.COM’S PARTNERS: WE
        MAY SHARE YOUR PERSONAL INFORMATION WITH OUR BUSINESS PARTNERS IF THERE
        IS A LEGITIMATE REASON TO DO SO. • THIRD PARTY SERVICE PROVIDERS: WE MAY
        EMPLOY THIRD PARTY SERVICE PROVIDERS (I.E., COMPANIES OR INDIVIDUALS
        ENGAGED BY US) TO PERFORM CERTAIN FUNCTIONS ON OUR BEHALF AND UNDER OUR
        INSTRUCTIONS. • COURTS, LAW ENFORCEMENT AUTHORITIES AND REGULATORS: WE
        MAY SHARE PERSONAL DATA WHEN WE BELIEVE IT NECESSARY TO COMPLY WITH THE
        LAW, TO PROTECT THE RIGHTS OR SAFETY OF OUR WEBSITE, OTHER USERS, OR
        THIRD PARTIES (E.G., FOR FRAUD PROTECTION PURPOSES). • BUYERS: AS WE
        CONTINUE TO DEVELOP OUR BUSINESS, WE MIGHT SELL ALL OR PARTS OF OUR
        WEBSITE OR BUSINESS. IN SUCH TRANSACTIONS, USER INFORMATION GENERALLY IS
        ONE OF THE TRANSFERRED BUSINESS ASSETS, BUT REMAINS SUBJECT TO THE
        PROMISES MADE IN ANY PRE-EXISTING WEBSITE PRIVACY POLICY, UNLESS YOU
        CONSENT OTHERWISE. Recipients of your personal data may be located in
        any country. This may include countries where applicable data protection
        laws provide a lesser degree of protection than your home country.
        Storing your personal data securely: As we are the ones, who use your
        data for the said purpose, we takes all necessary precautions to protect
        your personal information. Though it is imperative for you to protect
        against unauthorized access by sharing password etc. Dilatelabs.com is
        not responsible for the confidentiality, security or distribution of
        your personal information by our partners and third parties outside the
        scope of our agreement with such partners and third parties. Further, we
        shall not be responsible for any breach of security or for any actions
        of any third parties or events that are beyond the reasonable control of
        Dilatelabs.com. Disclosure of your personal information: Disclosure of
        your personal information We may reveal your personal information to any
        associate of our group (our subsidiary, our holding company and its
        subsidiaries). We may divulge your Personal information to our
        employees, officers, manager, associates and any other third party
        service providers with whom we have an arrangement. Changes in Privacy
        Policy: Dilatelabs.com may make changes in this Privacy Policy at any
        time, with or without advance notice. If there are any significant
        changes in this Privacy Policy, Dilatelabs.com will inform you of such
        revised privacy policy. Conclusion: We request all our users and
        visitors to go through the policy before they access any kind of
        information or use our service. In case of any unfortunate event or
        misunderstanding between both the parties or misuse of information as
        mentioned in the policy above, we will not be held liable. Accessing our
        website and using our resources means that you have agreed to our terms
        and conditions and the privacy policy. Your feedback is always welcome.
        If you have any questions or concerns about our privacy practices or
        your online privacy please do not hesitate to contact us. E-Mail: info@
        Dilatelabs.com Mobile: .com), its sub-domains and all related
        application and services are owned and operated by Dilatelabs.com Our
        Privacy Policy is applicable to • A HEALTHCARE SERVICE PROVIDER WHO
        AGREES TO BE LISTED WITH dilatelabs • USERS(YOU) OR HIS
        RELATIVE/ATTENDANT WHO USE OUR SERVICES • ANYONE WHO USES OUR SERVICES
        IN ANY FORM (USERS OR YOU) • THIRD PARTIES (SUCH AS ADROLL) MAY PLACE
        COOKIES ON THEIR BROWSERS FOR TARGETED ADVERTISING PURPOSES. By using
        our services you are agreeing to the privacy policy and the terms and
        conditions contained below. The privacy policy and the terms and
        conditions constitute a legal agreement between Dilatelabs.com and you
        in connection with the use and access to the Resources. Please read this
        privacy policy very carefully and DO NOT use our services if you do not
        agree with any of the points mentioned. If you are using
        Dilatelabs.com’s services on behalf of a third party you represent that
        you are authorized to accept the conditions on behalf of any such third
        party. INTRODUCTION: This policy gives information about the type and
        amount of information collected from the Users, including sensitive
        personal data or information; the purpose of collection and modes of
        usage of such information; and how Dilatelabs.com will use such
        information. The utilization and access of our services is subjected to
        the acceptance of our privacy policy. Dilatelabs.COM is committed to
        protect the privacy of its Users, including all personal information. We
        will only reveal personal information whenever required to do so by the
        law, by the order of court, government law or statutory authority or
        similar requirements or to safeguard any assets or damage. Personal
        Information, Data Shared/ Information disclosed: Personal information
        that we collect, process and use in connection with the services include
        not only the information that we collect via various forms, but also the
        information that you voluntarily provide us in various contexts.
        Information collected by us from you may include (but is not limited to)
        the following: • NAME • LOGIN ID AND PASSWORD; • CONTACT INFORMATION
        (SUCH AS YOUR EMAIL ADDRESS, ADDRESSES, PHONE NUMBERS); • DEMOGRAPHIC
        DATA (SUCH AS YOUR GENDER, YOUR DATE OF BIRTH AND YOUR PIN CODE) • IP
        ADDRESS, OPERATING SYSTEM, BROWSER TYPE, BROWSER VERSION, BROWSER
        CONFIGURATION, NAME OF • INTERNET SERVICE PROVIDER, AND OTHER TYPES OF
        COMPUTER AND CONNECTION RELATED INFORMATION • RELEVANT TO IDENTIFYING
        YOUR TYPE OF DEVICE, CONNECTING TO THE WEBSITE, ENABLING DATA EXCHANGE •
        WITH YOU AND YOUR DEVICE, AND ENSURING A CONVENIENT USE OF THE WEBSITE;
        • HISTORIC DATA REGARDING YOUR USAGE OF OUR SERVICES; • SEARCH TERMS
        ENTERED; • INFORMATION COLLECTED BY COOKIES OR SIMILAR TECHNOLOGIES; •
        NEWSLETTER SUBSCRIPTIONS, ENROLMENT FOR PROMOTIONS, USE OF SPECIAL
        OFFERS, ETC. • SURVEY ANSWERS, REVIEWS, RATINGS AND OTHER TYPES OF
        FEEDBACK PROVIDED; • MEDICAL CREDENTIALS, VISITING HOURS OF A HEALTHCARE
        PROVIDER, FEES, LOCATIONS (IN RELATION TO HEALTHCARE PROVIDERS); •
        MEDICAL INFORMATION; • FINANCIAL AND PAYMENT RELATED DATA FOR PAYMENT OF
        SUBSCRIPTION AMOUNT AND CHARGES; • GEO-LOCATION INFORMATION WHEN
        PERMITTED BY YOU ON YOUR DEVICE; AND • INFORMATION COLLECTED THROUGH THE
        REGISTRATION FORM • ANY OTHER INFORMATION THAT YOU ENTER ON, OR UPLOAD
        IS VOLUNTARILY. Dilatelabs.com reserves the right to use information
        provided by you for the following: • PUBLISHING ON THE WEBSITE/MOBILE
        APPLICATION ETC. • CONTACTING YOU FOR OFFERING NEW PRODUCTS/SERVICES; •
        CONTACTING YOU TO OFFER NEW PRODUCTS/SERVICES BY OUR PARTNERS; •
        CONTACTING YOUR FOR FEEDBACK; • ANALYSIS AND REPORTING You hereby
        consent to use of such information by Dilatelabs.com. You are
        responsible for maintaining the accuracy of the information you submit
        to us, such as your contact information provided as part of account
        registration. If you provide any information that is untrue, inaccurate,
        out of date or incomplete (or becomes untrue, inaccurate, out of date or
        incomplete), or Dilatelabs.com suspects that the information provided by
        you is untrue, inaccurate, out of date or incomplete, it may, at its
        sole discretion, discontinue your usage of its services. If you do not
        want to receive any form of communication from us, please intimate us
        on info@ Dilatelabs.com Dilatelabs.com does not exercise control over
        the sponsored content or links. And hence, it is not liable for any type
        of information shared on any website. Dilatelabs.com may disclose
        personal information to the following: • dilatelabs.COM’S PARTNERS: WE
        MAY SHARE YOUR PERSONAL INFORMATION WITH OUR BUSINESS PARTNERS IF THERE
        IS A LEGITIMATE REASON TO DO SO. • THIRD PARTY SERVICE PROVIDERS: WE MAY
        EMPLOY THIRD PARTY SERVICE PROVIDERS (I.E., COMPANIES OR INDIVIDUALS
        ENGAGED BY US) TO PERFORM CERTAIN FUNCTIONS ON OUR BEHALF AND UNDER OUR
        INSTRUCTIONS. • COURTS, LAW ENFORCEMENT AUTHORITIES AND REGULATORS: WE
        MAY SHARE PERSONAL DATA WHEN WE BELIEVE IT NECESSARY TO COMPLY WITH THE
        LAW, TO PROTECT THE RIGHTS OR SAFETY OF OUR WEBSITE, OTHER USERS, OR
        THIRD PARTIES (E.G., FOR FRAUD PROTECTION PURPOSES). • BUYERS: AS WE
        CONTINUE TO DEVELOP OUR BUSINESS, WE MIGHT SELL ALL OR PARTS OF OUR
        WEBSITE OR BUSINESS. IN SUCH TRANSACTIONS, USER INFORMATION GENERALLY IS
        ONE OF THE TRANSFERRED BUSINESS ASSETS, BUT REMAINS SUBJECT TO THE
        PROMISES MADE IN ANY PRE-EXISTING WEBSITE PRIVACY POLICY, UNLESS YOU
        CONSENT OTHERWISE. Recipients of your personal data may be located in
        any country. This may include countries where applicable data protection
        laws provide a lesser degree of protection than your home country.
        Storing your personal data securely: As we are the ones, who use your
        data for the said purpose, we takes all necessary precautions to protect
        your personal information. Though it is imperative for you to protect
        against unauthorized access by sharing password etc. Dilatelabs.com is
        not responsible for the confidentiality, security or distribution of
        your personal information by our partners and third parties outside the
        scope of our agreement with such partners and third parties. Further, we
        shall not be responsible for any breach of security or for any actions
        of any third parties or events that are beyond the reasonable control of
        Dilatelabs.com. Disclosure of your personal information: Disclosure of
        your personal information We may reveal your personal information to any
        associate of our group (our subsidiary, our holding company and its
        subsidiaries). We may divulge your Personal information to our
        employees, officers, manager, associates and any other third party
        service providers with whom we have an arrangement. Changes in Privacy
        Policy: Dilatelabs.com may make changes in this Privacy Policy at any
        time, with or without advance notice. If there are any significant
        changes in this Privacy Policy, Dilatelabs.com will inform you of such
        revised privacy policy. Conclusion: We request all our users and
        visitors to go through the policy before they access any kind of
        information or use our service. In case of any unfortunate event or
        misunderstanding between both the parties or misuse of information as
        mentioned in the policy above, we will not be held liable. Accessing our
        website and using our resources means that you have agreed to our terms
        and conditions and the privacy policy. Your feedback is always welcome.
        If you have any questions or concerns about our privacy practices or
        your online privacy please do not hesitate to contact us. E-Mail:
        contact@dilatelabs.com
      </Text>
    </Box>
  );
}
