import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import useTestStore from '../../../store/testStore';
import { getTests, searchTests } from '../../../api/tests';
import { useNavigate } from 'react-router-dom';
import { TestCard } from './TestCard';
import useStore from '../../../store/store';

export const PopularTests = () => {
  const { tests, setTests } = useTestStore();
  const { setLoading, loading } = useStore();

  const navigate = useNavigate();

  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getTests();
      setTests(res);
    } catch (error) {
      console.log('error in loading tests', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = async e => {
    e.preventDefault();
    const res = await searchTests(e.target.value);
    setTests(res);
  };

  return (
    <VStack p={[5, 10]} spacing={[10]} height={'full'} id="tests">
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[25, 30]}>
          Popular Tests
        </Heading>
        <Input
          variant="outline"
          placeholder="Search For Test"
          size={'lg'}
          width={['80vw', '35vw']}
          onChange={e => handleSearch(e)}
        />
      </Flex>
      <VStack
        spacing={[6, 8]}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '60vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner
              size={'lg'}
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="teal"
            />
          </Box>
        ) : (
          <>
            {tests.slice(0, 6).map((test, i) => {
              return <TestCard test={test} key={i} index={i} />;
            })}
          </>
        )}
      </VStack>
      <Button
        variant={'ghost'}
        color={'teal'}
        onClick={() => navigate('/tests')}
      >
        All Tests
      </Button>
    </VStack>
  );
};
