import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FAQ } from '../components/Landing/FAQ';
import { PackageDetails } from '../components/UserOrders/PackageDetails';

export const PackageOrderDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '100%',
        height: '85vh',
        flexDir: 'column',
        px: ['3', '5', '5', '5'],
        py: 5,
        gap: 5,
        marginLeft: ['0px', '0px', '260px', '260px'],
        overflowY: 'scroll',
        zIndex: '1000',
        pb: '100px',
      }}
    >
      <Box
        display={'flex'}
        width={'100%'}
        alignItems={'flex-start'}
        flexDirection={'column'}
      >
        <PackageDetails />
        <FAQ />
      </Box>
    </Box>
  );
};
