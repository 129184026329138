import {
  Button,
  CardBody,
  HStack,
  Heading,
  Card,
  Text,
  Stack,
  CardFooter,
  Box,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useCartStore from '../../../../../store/cartStore';
import { CartContext } from '../../../../../context/cart';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';

export const TestsCard = ({ test }) => {
  const navigate = useNavigate();
  const { addToCartPlans, removeFromCart } = useContext(CartContext);
  const { cart, setCart, removeItem } = useCartStore();
  const [loading, setLoading] = useState();
  const toast = useToast();
  const [addTocart, setAddToCart] = useState();

  useEffect(() => {
    setAddToCart(cart?.includes(test));
  }, []);

  useEffect(() => {
    setAddToCart(cart?.includes(test));
  }, [cart]);

  return (
    <Card
      width={'100%'}
      maxW={'100%'}
      height={['96', '80', '96', '80', '80']}
      px={[3, 5]}
      py={1}
      borderRadius={8}
      variant={'outline'}
      transition={'ease-in-out 0.3s'}
      border={'1px solid rgb(180,180,180,1)'}
      _hover={{
        // boxShadow: '0px 4px 5px 0px rgba(42, 43, 44, 0.50)',
        boxShadow: 'xl',
        border: '1px solid gray',
      }}
    >
      <CardBody px={'0'}>
        <Stack gap={3}>
          <Link to={`/test?testId=${test?._id}`} target="_blank">
            <Heading fontSize={[20, 25]}>{test?.name}</Heading>
          </Link>
          <Text fontSize={['md', 'md']}>
            {test?.description.slice(0, 250)}
            {test?.description.length > 250 && '...'}
          </Text>
        </Stack>
      </CardBody>
      <CardFooter
        px={'0'}
        justify="space-between"
        flexWrap="wrap"
        sx={{
          '& > button': {
            minW: '136px',
          },
        }}
      >
        <HStack width={'full'} justifyContent={'space-between'}>
          <Box>
            <Heading fontSize={[15, 18]} color={'#1DC2D5'}>
              ₹ {test?.price}
            </Heading>
            <Heading
              fontSize={[15, 18]}
              display={'flex'}
              gap={2}
              color={'#0C727E'}
            >
              ₹ <Text textDecor={' line-through'}>1048.55</Text>
            </Heading>
          </Box>

          {addTocart ? (
            <Box
              sx={{
                display: 'flex',
                gap: 5,
              }}
            >
              <IconButton colorScheme="teal" onClick={() => navigate('/cart')}>
                <AiOutlineShoppingCart size={'20px'} />
              </IconButton>
              <IconButton
                onClick={() => {
                  removeItem(test._id);
                  removeFromCart(test);
                  toast({
                    title: 'Remove Form Cart!',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                  });
                }}
              >
                <AiOutlineDelete />
              </IconButton>
            </Box>
          ) : (
            <Button
              isLoading={loading}
              bg={'#0C727E'}
              color={'#fff'}
              _hover={{}}
              onClick={() => {
                setLoading(true);
                setCart([test, ...cart]);
                addToCartPlans(test);
                setLoading(false);
                toast({
                  title: 'Added To Cart!',
                  status: 'success',
                  isClosable: true,
                  duration: 5000,
                });
              }}
            >
              Add to Cart
            </Button>
          )}
        </HStack>
      </CardFooter>
    </Card>
  );
};
