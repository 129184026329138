import { create } from 'zustand';

const useLabsStore = create(set => ({
  labs: [],
  labData: {},
  keyword: '',
  searchResult: [],

  
  setLabData: e => set(state => ({ labData: e })),
  setKeyword: e => set(state => ({ keyword: e })),
  setSearchResult: e =>
    set(state => ({
      searchResult: e,
    })),
  setLabs: e =>
    set(state => ({
      labs: e,
    })),
}));

export default useLabsStore;
