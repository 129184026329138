import { useSteps, useToast } from '@chakra-ui/react';
import { createContext, useEffect, useState } from 'react';
import { fetchPatient } from '../api/patients';
import useCartStore from '../store/cartStore';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { cart } = useCartStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [cartPlans, setCartPlans] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [patients, setPatients] = useState([]);
  const [availablePatients, setAvailablePatients] = useState([]);
  const [addPatientsDrawer, setAddPatientsDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [location, setLocation] = useState();
  const [orderDetail, setOrderDetail] = useState({
    appointmentDate: '',
    appointmentTime: '',
    phone: '',
    email: '',
    mainAddress: '',
    address: {},
    sampleMode: '',
  });
  const toast = useToast();
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: 5,
  });

  const updateStep = val => {
    val
      ? setCurrentStep(currentStep => currentStep + 1)
      : setCurrentStep(currentStep => currentStep - 1);
  };

  const addToCartPlans = cartPlan => {
    if (!cartPlans?.includes(cartPlan)) {
      setCartPlans(cartPlans => [...cartPlans, cartPlan]);
    }
  };

  const removeFromCart = cartPlan => {
    const filteredCart = cartPlans?.filter(plan => plan._id !== cartPlan._id);
    setCartPlans(() => filteredCart);
  };

  const addPatient = patients => {
    setPatients(patients);
    // const mySet = new Set([...patients, ...patient]);
    // setPatients(() => Array.from(mySet));
  };

  const getPatientsForAUser = async () => {
    try {
      const res = await fetchPatient();
      if (res) {
        // console.log('fetch patient called in cart context');
      }
      setPatients(() => res);
    } catch (error) {
      toast({
        title: error?.message || 'Cannot fetch patients!',
        variant: 'subtle',
      });
    }
  };

  const addPatientToCartPlan = patient => {
    const selectedCartItem = selectedItem;
    if (!selectedCartItem?.patients) {
      selectedCartItem.patients = [];
    }
    selectedCartItem.patients = [...selectedCartItem?.patients, patient];
    const newCartPlans = cartPlans.filter(
      plan => plan._id !== selectedItem._id
    );
    setCartPlans(() => [selectedCartItem, ...newCartPlans]);
  };

  const removePatientFromCartItem = (data, patient) => {
    let newSelectedItem = data;
    if (!newSelectedItem?.patients) {
      newSelectedItem.patients = [];
    }
    newSelectedItem.patients = newSelectedItem?.patients?.filter(
      p => p !== patient
    );
    const cartItems = cartPlans?.map(it => {
      if (it._id === newSelectedItem._id) {
        return newSelectedItem;
      }
      return it;
    });
    setCartPlans(() => cartItems);
  };

  const getAvailablePatients = () => {
    const availablePatients = patients?.map(pat => {
      if (!selectedItem?.patients?.includes(pat)) {
        return pat;
      }
      return;
    });
    setAvailablePatients(() => availablePatients);
  };

  useEffect(() => {
    const filteredCartItem = cartPlans.filter(
      plan => plan?.patients?.length > 0
    );
    setCartData(filteredCartItem);
  }, [cartPlans, cart]);

  useEffect(() => {
    getPatientsForAUser();
    // console.log('fetch patients is called');
  }, []);

  return (
    <CartContext.Provider
      value={{
        currentStep,
        updateStep,
        cartPlans,
        setCartPlans,
        addToCartPlans,
        patients,
        addPatient,
        availablePatients,
        getPatientsForAUser,
        removeFromCart,
        addPatientsDrawer,
        setAddPatientsDrawer,
        selectedItem,
        setSelectedItem,
        addPatientToCartPlan,
        removePatientFromCartItem,
        getAvailablePatients,
        location,
        setLocation,
        activeStep,
        setActiveStep,
        orderDetail,
        setOrderDetail,
        cartData,
        setCartData,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
