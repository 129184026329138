import React, { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Grid,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { BsChevronDown } from 'react-icons/bs';
import { AiFillStar } from 'react-icons/ai';
import { FaBolt } from 'react-icons/fa';
import { FiPercent } from 'react-icons/fi';

import useLabsStore from '../store/labsStore';
import { getLabs, searchlabs } from '../api/labs';
import LabCard from '../components/LabCards';
import { useLocation } from 'react-router-dom';


export const SearchLabs = () => {
  const { searchResult, setSearchResult } = useLabsStore();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const location = useLocation();

  // console.log('calling test location obeject', location);

  const searchObject = new URLSearchParams(location?.search);

  // console.log('calling searchOBject', searchObject);

  const keyword = searchObject.get('keyword');

  const sortSearchResultByCapacityLowToHigh = () => {
    // console.log('Sorting low to high');
    const sortedResults = [...searchResult].sort(
      (a, b) => a.sampleCapacity - b.sampleCapacity
    );
    // console.log('Sorted Results:', sortedResults);
    setSearchResult([...sortedResults]);
  };

  const sortSearchResultByCapacityHighToLow = () => {
    // console.log('Sorting high to low');
    const sortedResults = [...searchResult].sort(
      (a, b) => b.sampleCapacity - a.sampleCapacity
    );
    // console.log('Sorted Results:', sortedResults);
    setSearchResult([...sortedResults]);
  };

  const fetch = async () => {
    try {
      setLoading(true);
      if (keyword) {
        const res = await searchlabs(keyword);
        setSearchResult(res);
        // setKeyword('');
      } else {
        const res = await getLabs();

        setSearchResult(res);
      }
    } catch (error) {
      toast({
        title: 'error while searching lab',
        status: error,
        isClosable: true,
        duration: 9000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [location]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Box
        py="9px"
        px={'5'}
        backgroundColor="#1DC2D5"
        display={['none', 'none', 'flex', 'flex', 'flex']}
        flexDir={'column'}
      >
        <HStack width="100%" spacing="34px" justifyContent={'center'}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<BsChevronDown />}
              variant="ghost"
              textColor="white"
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            >
              Sort
            </MenuButton>
            <MenuList>
              <MenuItem>Rating</MenuItem>
              <MenuItem>Popular</MenuItem>
              <MenuItem>Distance</MenuItem>
              <MenuItem>Review</MenuItem>
              <MenuItem onClick={sortSearchResultByCapacityLowToHigh}>
                Capacity Low to High
              </MenuItem>
              <MenuItem onClick={sortSearchResultByCapacityHighToLow}>
                Capacity High to Low
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<BsChevronDown />}
              variant="ghost"
              textColor="white"
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            >
              Ratings
            </MenuButton>
            <MenuList>
              <MenuItem>Any</MenuItem>
              <MenuItem>3.5</MenuItem>
              <MenuItem>4.0</MenuItem>
              <MenuItem>4.5</MenuItem>
              <MenuItem>5.0</MenuItem>
            </MenuList>
          </Menu>
          <Button
            leftIcon={<AiFillStar />}
            variant="ghost"
            textColor="white"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            Top Rated
          </Button>
          <Button
            leftIcon={<FaBolt />}
            variant="ghost"
            textColor="white"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            Quick Response
          </Button>
          <Button
            leftIcon={<FiPercent />}
            variant="ghost"
            textColor="white"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            Wellness Card
          </Button>
        </HStack>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '60vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size={'lg'} />
        </Box>
      ) : (
        <VStack py="100px" spacing="58px" px={'5'} pb={'32'}>
          <HStack alignItems="center" spacing="30px">
            <Text fontSize={['25px', '30px']} textAlign={['center', 'center']}>
              {searchResult?.length} lab found for your search {keyword}
            </Text>
            {/* <Button
            leftIcon={<MdLocationOn />}
            variant="ghost"
            borderBottom="1px"
            _hover={{ bg: 'transparent' }}
            rounded="lg"
            boxShadow="lg"
          >
            <Text opacity="60%"> Labs near me </Text>
          </Button> */}
          </HStack>

          <Grid
            templateColumns={['1fr', '1fr', '1fr', '1fr 1fr', '1fr 1fr']}
            gap={'8'}
            px={['5', '5', '12', '12', '12']}
          >
            {searchResult?.map((labData, index) => {
              return (
                <Box key={index}>
                  <LabCard labData={labData} />
                </Box>
              );
            })}
          </Grid>

          <Button
            variant="ghost"
            color="#1DC2D5"
            fontWeight="semibold"
            fontSize={['18px', '22px']}
            width="275px"
            height="62px"
            _hover={{ bg: 'transparent' }}
          >
            View More Packages
          </Button>
        </VStack>
      )}
    </>
  );
};
