import React, { useContext, useEffect, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  Avatar,
  Box,
  Text,
  Flex,
  Checkbox,
  Card,
  CardBody,
  Tabs,
  TabList,
  Tab,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import useStore from '../../../../store/store';
import { getUser } from '../../../../cookie/user';
import { CartContext } from '../../../../context/cart';

export const CartDrawer = ({ btnRef }) => {
  const {
    addPatientsDrawer,
    setAddPatientsDrawer,
    addPatientToCartPlan,
    addPatient,
    patients,
    selectedItem,
    removePatientFromCartItem,
  } = useContext(CartContext);
  const { setUser } = useStore();

  const user = getUser();
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const tags = ['Self', 'Father', 'Mother', 'Brother', 'Sister', 'Daughter'];

  const unusedTags = [
    ...tags?.filter(tag => !patients?.some(p => p.tag === tag)),
    'Other',
  ];
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  // const [tickedPatients, setTickedPatients] = useState([]);
  const [patientsToShow, setPatientsToShow] = useState([]);
  const [tag, setTag] = useState(unusedTags[0]);

  const getGenderFromTag = tag => {
    return ['Mother', 'Sister', 'Daughter'].includes(tag) ? 'female' : 'male';
  };

  const [gender, setGender] = useState(getGenderFromTag(unusedTags[0]));
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    gender === 'male' ? 0 : 1
  );

  const [newPatient, setNewPatient] = useState({
    name: '',
    birthdate: '',
    gender: gender,
    tag: tag,
  });

  const onGenderChange = gender => {
    setGender(gender);
    genderTabHandler(gender);
  };

  const onTagChange = tag => {
    setTag(tag);
    setGender(() => getGenderFromTag(tag));
  };

  const genderTabHandler = gender => {
    setSelectedTabIndex(gender === 'male' ? 0 : 1);
  };

  useEffect(() => {
    genderTabHandler(gender);
  }, [gender]);
  const onClose = () => {
    setAddPatientsDrawer(false);
    setIsEditorOpen(false);
  };

  console.log(
    'calling select tab index,gender,tag',
    selectedTabIndex,
    gender,
    tag
  );

  useEffect(() => {
    const updatedPatients = patients?.map(patient => {
      const isSelected = selectedItem?.patients?.includes(patient);
      if (isSelected) {
        return { ...patient, checked: true };
      }
      return patient;
    });
    setPatientsToShow(updatedPatients);
  }, [patients, selectedItem]);

  // checkbox handler

  const handleCheckboxChange = patient => {
    if (selectedItem?.patients?.includes(patient)) {
      removePatientFromCartItem(selectedItem, patient);
    } else {
      addPatientToCartPlan(patient);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (user?._id) {
        const patient = { ...newPatient, tag: tag, gender: gender };
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER}/addPatient`,
          {
            userId: user?._id,
            ...patient,
          }
        );

        addPatient(res?.data?.user?.patients);
        setPatientsToShow(res?.data?.user?.patients);

        setUser(res.data.user);
        localStorage.setItem('user', JSON.stringify(res.data.user));

        fetchPatient();

        setIsEditorOpen(false);
        toast({
          title: 'Patient Added',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error Adding Patient',
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsEditorOpen(false);
    } finally {
      setLoading(false);
      setIsEditorOpen(false);
    }
  };

  const fetchPatient = async () => {
    try {
      setLoading(true);
      if (user?._id) {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER}/getUser/${user._id}`
        );

        addPatient(res?.data?.user?.patients);
        setPatientsToShow(() => res?.data?.user?.patients);
      }
    } catch (error) {
      console.error('error while fetching patient', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatient();
    setPatientsToShow(patients);
  }, []);

  const calculateAge = birthdate => {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000);
    return Math.floor(ageInYears);
  };

  return (
    <>
      <Drawer
        size="sm"
        isOpen={addPatientsDrawer}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add or Select Patient</DrawerHeader>

          <DrawerBody
            sx={{
              display: 'flex',
              flexDir: 'column',
              gap: 3,
            }}
          >
            <Box
              sx={{
                border: '3px solid #f2f2f2',
                display: isEditorOpen ? 'none' : 'flex',
                px: 4,
                py: 3,
                borderRadius: 10,
                alignItems: 'center',
                gap: '3',
              }}
            >
              <Avatar size="sm" />
              <Text
                fontSize={14}
                cursor="pointer"
                onClick={() => setIsEditorOpen(!isEditorOpen)}
              >
                Add New Patient
              </Text>
            </Box>
            <Card
              height="container.sm"
              sx={{
                display: isEditorOpen ? 'block' : 'none',
              }}
            >
              <CardBody
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 5,
                }}
              >
                <Tabs
                  size="sm"
                  variant="soft-rounded"
                  colorScheme="green"
                  sx={
                    {
                      // overflowX: 'scroll',
                    }
                  }
                >
                  <TabList width={'100%'} overflowX={'scroll'}>
                    {unusedTags.map((tag, i) => (
                      <Tab key={i} onClick={() => onTagChange(tag)}>
                        {tag}
                      </Tab>
                    ))}
                  </TabList>
                </Tabs>
                <Input
                  size="sm"
                  fontSize="sm"
                  borderRadius={10}
                  variant="outline"
                  colorScheme="teal"
                  placeholder="Patient Name"
                  onChange={e =>
                    setNewPatient({ ...newPatient, name: e.target.value })
                  }
                />
                <Flex alignItems="center" gap={4}>
                  <Input
                    size="sm"
                    fontSize="sm"
                    borderRadius={10}
                    variant="outline"
                    colorScheme="teal"
                    placeholder="Patient Name"
                    type="date"
                    onChange={e =>
                      setNewPatient({
                        ...newPatient,
                        birthdate: e.target.value,
                      })
                    }
                  />
                  <Tabs
                    variant="unstyled"
                    size="sm"
                    index={selectedTabIndex}
                    onChange={index => setSelectedTabIndex(index)}
                  >
                    {console.log(['Mother', 'Sister', 'Daughter'].includes(tag))}
                    <TabList>
                      <Tab
                        isDisabled={['Mother', 'Sister', 'Daughter'].includes(tag)}
                        _selected={{
                          color: 'white',
                          bg: selectedTabIndex === 0 ? 'blue.500' : 'gray.400',
                        }}
                        onClick={() => onGenderChange('male')}
                      >
                        M
                      </Tab>
                      <Tab
                      isDisabled={['Brother', 'Father'].includes(tag)}
                        _selected={{
                          color: 'white',
                          bg: selectedTabIndex === 1 ? 'green.400' : 'gray.400',
                        }}
                        onClick={() => onGenderChange('female')}
                      >
                        F
                      </Tab>
                    </TabList>
                  </Tabs>
                </Flex>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="ghost"
                    borderRadius={10}
                    onClick={() => setIsEditorOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={loading}
                    variant="outline"
                    colorScheme="teal"
                    borderRadius={10}
                    onClick={handleSubmit}
                  >
                    Add Patient
                  </Button>
                </Box>
              </CardBody>
            </Card>
            {loading ? (
              <>
                <Box
                  width={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Spinner width={'10'} h={'10'} thickness="4px" color="teal" />
                </Box>
                <Text fontWeight={'bold'}>Patients List is Loading ...</Text>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDir: 'column',
                    gap: 3,
                    overflowY: 'scroll',
                  }}
                >
                  {patientsToShow?.map((patient, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: '3px solid #f2f2f2',
                        display: 'flex',
                        px: 4,
                        py: 3,
                        borderRadius: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Flex gap={3}>
                        <Avatar size="sm" />
                        <Box>
                          <Text fontSize={14} fontWeight={600}>
                            {patient?.name}
                          </Text>
                          <Text fontSize={12} color="gray.600">
                            {`${patient?.tag}, ${patient?.gender
                              }, ${calculateAge(patient?.birthdate)} Years`}
                          </Text>
                        </Box>
                      </Flex>
                      <Checkbox
                        colorScheme="teal"
                        isChecked={selectedItem?.patients?.includes(patient)}
                        onChange={() => handleCheckboxChange(patient)}
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={onClose}>
              Done
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
