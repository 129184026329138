import { Box } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { MainSection } from '../components/Cart/MainSecion';
import { Navbar } from '../components/Cart/Navbar';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../cookie/user';
import { CartStepper } from '../components/Cart/CartStepper';
import { CartContext } from '../context/cart';
import { AddressSection } from '../components/Cart/AddressSection';
import { DateSection } from '../components/Cart/DateSection';
import { CartWrapper } from '../components/Cart/CartWrapper';
import { CheckoutSection } from '../components/Cart/CheckoutSection';
import { PaymentSection } from '../components/Cart/PaymentSection';

export const Cart = () => {
  const navigate = useNavigate();
  // const { cart, setCart } = useCartStore();
  const { activeStep } = useContext(CartContext);

  React.useEffect(() => {
    const user = getUser();
    if (user === null) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Box 
       color={'white'}
       width={'100vw'}
       justifyContent={'center'}
      //  padding={['4', '6', '8']} // Responsive padding

      >
        <Navbar />
        <CartStepper />
        {activeStep === 1 && <MainSection />}
        <CartWrapper display={activeStep === 2 ? true : false}>
          <AddressSection />
        </CartWrapper>
        <CartWrapper display={activeStep === 3 ? true : false}>
          <DateSection />
        </CartWrapper>
        <CartWrapper display={activeStep === 4 ? true : false}>
          <CheckoutSection />
        </CartWrapper>
        <CartWrapper display={activeStep === 5 ? true : false}>
          <PaymentSection />
        </CartWrapper>
      </Box>
    </>
  );
};
