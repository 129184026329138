import { create } from 'zustand';

const useCartStore = create(set => ({
  cart: [],
  selectedPatients: [],
  location: '',
  isLocationOpen: false,
  consumerDetails: {},
  totalOrderPrice: 0,
  nearbyLabs: [],
  setNearbyLabs: e => set(state => ({ nearbyLabs: e })),
  setTotalOrderPrice: e => set(state => ({ totalOrderPrice: e })),
  setSelectedPatients: e => set(state => ({ selectedPatients: e })),
  setConsumerDetails: e => set(state => ({ cunsumerDetails: e })),
  removeSelectedPatients: patient => {
    set(state => {
      const newData = state.selectedPatients.filter(
        obj => obj.name !== patient
      );
      return { selectedPatients: newData };
    });
  },
  setIsLocationOpen: e =>
    set(state => ({
      isLocationOpen: e,
    })),
  setCart: e =>
    set(state => ({
      cart: e,
    })),
  setLocation: e => set(state => ({ location: e })),

  removeItem: _id =>
    set(state => {
      const updatedCart = state.cart.filter(item => item._id !== _id);
      return { cart: updatedCart };
    }),
  removePatients: index =>
    set(state => {
      const updatedPatients = [...state.selectedPatients];
      if (index >= 0 && index < updatedPatients.length) {
        updatedPatients.splice(index, 1);
      }
      return { selectedPatients: updatedPatients };
    }),
}));

export default useCartStore;
