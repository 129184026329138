import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  VStack,
  Heading,
  Image,
  Stack,
  Divider,
  InputGroup,
  Box,
  useToast,
} from '@chakra-ui/react';
import useStore from '../../../store/store';
import { TextField } from '../../Inputs/TextField';
import { Formiz, FormizStep, useForm } from '@formiz/core';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { firebaseAuth } from '../../../firebase';
import axios from 'axios';
import { isMinNumber } from '@formiz/validations';
import { getUser } from '../../../cookie/user';

export const SigninModal = () => {
  const [loading, setLoading] = useState(false);
  const { signin, setSignin, setUser } = useStore();
  const toast = useToast();
  const form = useForm();

  const onClose = () => {
    setSignin(false);
    setLoading(false);
  };

  const handleSignin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/login`,
        {
          phone: form.flatValues.phone1,
        }
        // { timeout: 20000 }
      );
      setUser(response?.data?.user);
      console.log('calling user response in sign in ', response);
      // console.log('calling user response in sign again ', user);
      localStorage.setItem('user', JSON.stringify(response?.data?.user));
      let newUser = JSON.parse(localStorage.getItem('user'));
      setUser(newUser);
      console.log('calling new user in signin', newUser);
      // console.log('calling user in signin handler', user);
      console.log('calling getuser again in signin', getUser());
      toast({
        title: response.data.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: error?.response?.data?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const getOtp = async e => {
    setLoading(true);
    firebaseAuth.settings.appVerificationDisabledForTesting = false;
    e.preventDefault();
    const phone = form.flatValues.phone1;
    if (!phone) {
      toast({
        title: 'Please enter phone number!',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        firebaseAuth,
        'sign-in-btn',
        {
          size: 'normal',
          callback: response => {},
          'expired-callback': error => {
            setLoading(false);
            toast({
              title: `"errr in the captcha verifier",${error.message}`,
              duration: 9000,
              status: 'error',
              isClosable: true,
            });
          },
        }
      );

      const phoneNumber = `+91${phone}`;
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        firebaseAuth,
        phoneNumber,
        appVerifier
      );
      setLoading(false);
      window.confirmationResult = confirmationResult;
      form.nextStep();
      toast({
        title: 'OTP Sent!',
        duration: 9000,
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `"errr in confirmation result",${error.message}`,
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      console.error(error.message);
    }
  };

  const verifyOtp = async e => {
    setLoading(true);
    e.preventDefault();
    const { otp } = form.flatValues;
    if (!otp) {
      setLoading(false);
      return;
    }

    try {
      const { confirmationResult } = window;
      await confirmationResult.confirm(otp);
      toast({ title: 'Otp Verify', duration: 9000, status: 'success' });
      setLoading(false);
      onClose();
      handleSignin();
      // console.log('success');
    } catch (error) {
      setLoading(false);
      toast({ title: error.code, duration: 9000, status: 'error' });
      console.error(error.message);
    }
  };

  return (
    <Modal
      isOpen={signin}
      onClose={onClose}
      size={['md', 'lg', '2xl', '3xl', '3xl']}
      y
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <HStack alignItems={'center'} spacing={5}>
            <VStack
              flex={1}
              p={5}
              display={['none', 'none', 'none', 'flex', 'flex']}
            >
              <Heading fontSize={'2xl'}>Welcome To Dilate Labs</Heading>
              <Image src={'/assets/signup/Signup.png'} width={80} />
            </VStack>
            <Formiz
              connect={form}
              onValidSubmit={verifyOtp}
              onSubmit={() => verifyOtp()}
            >
              <VStack flex={1} p={[1, 5]} spacing={7} display={'flex'}>
                <Heading fontSize={'23'}> Login With OTP </Heading>
                <FormizStep label="Step 1" name="step1">
                  <Box display="flex" width={'xs'}>
                    <TextField
                      disabled={false}
                      id={'phone1'}
                      name={'phone1'}
                      type={'number'}
                      placeholder="Enter phone"
                      validations={[
                        {
                          rule: isMinNumber(10),
                          message: 'Should be a number over 10',
                        },
                      ]}
                    />
                  </Box>
                </FormizStep>
                <FormizStep label="Step 2" name="step2">
                  <Box display="flex" flexDir={'column'} gap={3} width={'xs'}>
                    <InputGroup>
                      <TextField
                        disabled={false}
                        id={'otp'}
                        name={'otp'}
                        type={'number'}
                        placeholder="Enter OTP"
                      />
                    </InputGroup>
                  </Box>
                </FormizStep>

                <Stack spacing={3}>
                  {form.isLastStep ? (
                    <Button
                      isDisabled={
                        form.flatValues?.otp?.length === 6 ? false : true
                      }
                      bg={'#0C727E'}
                      isLoading={loading}
                      color={'white'}
                      fontWeight={400}
                      width={310}
                      type="submit"
                      onClick={verifyOtp}
                    >
                      Verify OTP
                    </Button>
                  ) : (
                    <>
                      {' '}
                      <div id="sign-in-btn" />
                      <Button
                        isDisabled={
                          form.flatValues?.phone1?.length === 10 ? false : true
                        }
                        id="sign-in-btn"
                        isLoading={loading}
                        bg={'#0C727E'}
                        color={'white'}
                        fontWeight={400}
                        width={310}
                        type="submit"
                        _hover={{}}
                        onClick={e => {
                          getOtp(e);
                        }}
                      >
                        Get OTP
                      </Button>
                    </>
                  )}
                  <Divider />
                </Stack>
              </VStack>
            </Formiz>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
