import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Heading,
  Stack,
  Text,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCartStore from '../../../../store/cartStore';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';
import { CartContext } from '../../../../context/cart';

export const TestCard = ({ test, index }) => {
  const navigate = useNavigate();
  const { addToCartPlans, removeFromCart } = useContext(CartContext);
  const { cart, setCart, removeItem } = useCartStore();
  const [loading, setLoading] = useState();
  const toast = useToast();
  const [addTocart, setAddToCart] = useState();

  const calculateDescriptionLength = () => {
    return window.innerWidth < 600 ? 162 : 236;
  };

  const descriptionLength = calculateDescriptionLength();

  useEffect(() => {
    setAddToCart(cart?.includes(test));
  }, []);

  useEffect(() => {
    setAddToCart(cart?.includes(test));
  }, [cart]);

  return (
    <Card
      width={'100%'}
      maxW={'lg'}
      height={['80']}
      px={[1, 3]}
      py={1}
      borderRadius={8}
      variant={'outline'}
      transition={'ease-in-out 0.3s'}
      border={'1px solid rgb(180,180,180,1)'}
      _hover={{
        // boxShadow: '0px 4px 5px 0px rgba(42, 43, 44, 0.50)',
        boxShadow: 'xl',
        border: '1px solid gray',
      }}
    >
      <CardBody height={'60'} overflowY={'hidden'} p={[3, 5, 5, 5, 5]}>
        <Stack gap={3}>
          <Heading
            fontSize={[18, 25]}
            onClick={() => navigate(`/test?testId=${test._id}`)}
            _hover={{ cursor: 'pointer' }}
          >
            {test?.name}
          </Heading>
          <Text fontSize={['sm', 'md']}>
            {test?.description.slice(0, descriptionLength)}
            {test?.description.length > descriptionLength && '...'}
          </Text>
        </Stack>
      </CardBody>
      <CardFooter
        justify="space-between"
        flexWrap="wrap"
        sx={{
          '& > button': {
            minW: '136px',
          },
        }}
      >
        <HStack width={'full'} justifyContent={'space-between'}>
          <Box>
            <Heading fontSize={[16, 18]} color={'#1DC2D5'}>
              ₹ {test?.price}
            </Heading>
          </Box>
          {addTocart ? (
            <Box
              sx={{
                display: 'flex',
                gap: 5,
              }}
            >
              <IconButton
                size={['md', 'md']}
                colorScheme="teal"
                onClick={() => navigate('/cart')}
              >
                <AiOutlineShoppingCart />
              </IconButton>
              <IconButton
                size={['md', 'md']}
                onClick={() => {
                  removeItem(test._id);
                  removeFromCart(test);
                  toast({
                    title: 'Remove Form Cart!',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                    position: 'top',
                  });
                }}
              >
                <AiOutlineDelete />
              </IconButton>
            </Box>
          ) : (
            <Button
              isLoading={loading}
              bg={'#0C727E'}
              color={'#fff'}
              _hover={{}}
              onClick={() => {
                setLoading(true);
                setCart([test, ...cart]);
                addToCartPlans(test);
                setLoading(false);
                toast({
                  title: 'Added To Cart!',
                  status: 'success',
                  isClosable: true,
                  duration: 5000,
                  position: 'top',
                });
              }}
            >
              Add to Cart
            </Button>
          )}
        </HStack>
      </CardFooter>
    </Card>
  );
};
