import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Spinner,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { getPackages, searchPackages } from '../../../api/packages';
import usePackageStore from '../../../store/packageStore';
import { useNavigate } from 'react-router-dom';
import { PackageCard } from './PackageCard';
import useStore from '../../../store/store';

export const PopularPackages = () => {
  const { packages, setPackages } = usePackageStore();

  const { loading, setLoading } = useStore();
  const toast = useToast();
  const navigate = useNavigate();
  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getPackages();
        setPackages(res);
      } catch (error) {
        toast({ duration: 5000, status: error, isClosable: true });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = async e => {
    e.preventDefault();
    const res = await searchPackages(e.target.value);
    setPackages(res);
  };

  return (
    <VStack p={[5, 10]} spacing={12}>
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[24, 30]}>
          Health Checkup Packages
        </Heading>
        <Input
          variant="outline"
          placeholder="Search For Package"
          size={'lg'}
          width={['80vw', '35vw']}
          onChange={e => handleSearch(e)}
        />
      </Flex>
      <VStack
        spacing={[6, 8]}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '60vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner
              size={'lg'}
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="teal"
            />
          </Box>
        ) : (
          <>
            {packages.slice(0, 4).map((packages, i) => {
              return <PackageCard packages={packages} key={i} />;
            })}
          </>
        )}
      </VStack>
      <Button
        variant={'ghost'}
        color={'teal'}
        onClick={() => navigate('/packages')}
      >
        All Packages
      </Button>
    </VStack>
  );
};
