import React, { useState } from 'react';
import {
  Flex,
  Box,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { FaHome, FaTeamspeak } from 'react-icons/fa';
import { GiTeamIdea } from 'react-icons/gi';
import { AiOutlineShoppingCart, AiOutlineUser } from 'react-icons/ai';
import '../../../App.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUser } from '../../../cookie/user';
import { IoIosLogOut } from 'react-icons/io';
import { motion } from 'framer-motion';
import useStore from '../../../store/store';

export const MobileNavbar = () => {
  const [activeIcon, setActiveIcon] = useState('home');
  const navigate = useNavigate();
  const user = getUser();
  const [isOpen, setIsOpen] = useState(false);
  const { setSignin, setUser } = useStore();
  const toast = useToast();

  const icons = [
    { name: 'about', url: '/about', icon: <GiTeamIdea />, id: 1 },
    { name: 'contact', url: '/contactus', icon: <FaTeamspeak />, id: 2 },
    { name: 'home', url: '/', icon: <FaHome />, id: 3 },
    { name: 'cart', url: '/cart', icon: <AiOutlineShoppingCart />, id: 4 },
  ];

  const location = useLocation();

  const handleIconClick = name => {
    setActiveIcon(name);
  };

  const loginHandler = () => {
    setIsOpen(false);
    setSignin(true);
  };

  const logoutHandler = () => {
    setIsOpen(false);
    localStorage.clear();
    setUser(null);
    navigate('/');

    // console.log('calling user after logged out', user);

    toast({
      title: 'logged out successfully',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Flex
      display={['flex', 'none']}
      paddingBottom={'2'}
      align="center"
      flexDir={'row'}
      justify="space-around"
      bg="#eee"
      // bg={'blackAlpha.900'}
      position="fixed"
      gap={'0'}
      bottom="0"
      left="50%"
      transform="translateX(-50%)"
      width="100%"
      boxShadow="0px -5px 15px rgba(0, 0, 0, 0.1)"
      zIndex={'200'}
    >
      {icons.map(item => (
        <Link
          to={item?.url}
          key={item?.id}
          onClick={() => {
            handleIconClick(item?.name);
          }}
          className={`footer-icon ${
            location?.pathname === item?.url ? 'active' : ''
          }`}
        >
          {item?.icon}
        </Link>
      ))}

      <Link
        onClick={() => {
          setIsOpen(true);
          handleIconClick('profile');
        }}
        className={`footer-icon ${
          location?.pathname === '/profile' ? 'active' : ''
        }`}
      >
        <AiOutlineUser />
      </Link>
      <Drawer
        placement="bottom"
        onClose={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      >
        <DrawerOverlay backdropFilter={'blur(6px)'} />
        <DrawerContent>
          <DrawerBody>
            <VStack spacing={4} alignItems={'center'} py={'6vh'}>
              {user === null ? (
                <motion.div whileTap={{ scale: 0.8 }} onClick={loginHandler}>
                  <Button variant={'outline'} colorScheme="teal">
                    Login
                  </Button>
                </motion.div>
              ) : (
                <>
                  <Button onClick={() => navigate('/profile')} gap={2}>
                    <AiOutlineUser size={'23px'} />
                    <Text mt={1.5}>Profile</Text>
                  </Button>
                  <Button onClick={() => navigate('/profile/orders')} gap={2}>
                    <AiOutlineShoppingCart size={'23px'} />
                    <Text mt={1.5}>Orders</Text>
                  </Button>
                  <Button onClick={logoutHandler} gap={2}>
                    <IoIosLogOut size={'23px'} />
                    <Text mt={1.5}>Logout</Text>
                  </Button>
                </>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
