import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { firebaseAuth } from '../firebase';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
  }

  function signUp(email, password) {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
  }
  function logOut() {
    return signOut(firebaseAuth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(firebaseAuth, googleAuthProvider);
  }

  function setUpRecaptha(number) {
    // console.log(number);
    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      { size: 'normal' },
      firebaseAuth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(firebaseAuth, number, recaptchaVerifier);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, currentuser => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        setUpRecaptha,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
