import React, { useState } from 'react';
import { Box, Heading, VStack, HStack } from '@chakra-ui/react';
import Profile from '../components/Profile';

const ProfilePage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '100%',
        height: '85vh',
        flexDir: 'column',
        px: ['0', '0', '0', '5'],
        py: 5,
        gap: 5,
        overflowY: 'scroll',
        marginLeft: ['0px', '0px', '260px', '260px'],
        zIndex: '1000',
        alignItems: 'center',
        pb: '100px',
      }}
    >
      <VStack
        width={'100%'}
        spacing={4}
        pt={8}
        px={['3', '5', '3', '8']}
        alignItems={['center', 'flex-start']}
      >
        <HStack justifyContent="space-between" maxWidth="100%">
          <Heading as="h1" size="lg">
            Profile
          </Heading>
        </HStack>
        <Profile />
      </VStack>
    </Box>
  );
};

export default ProfilePage;
