import React, { useEffect, useState } from 'react';
import {
  Input,
  Select,
  HStack,
  Button,
  Avatar,
  Stack,
  FormControl,
  Textarea,
  Box,
  VStack,
  useToast,
  Text,
  Grid,
} from '@chakra-ui/react';
import axios from 'axios';
import { getUser } from '../../cookie/user';
import useStore from '../../store/store';
import { useDropzone } from 'react-dropzone';
import { BsImage } from 'react-icons/bs';

const Profile = () => {
  const user = getUser();
  const { setUser } = useStore();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const [profileData, setProfileData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    dateOfBirth: user?.dateOfBirth,
    phone: user?.phone,
    country: user?.country,
    state: user?.state,
    city: user?.city,
    gender: user?.gender,
    pincode: user?.pincode,
    password: user?.password,
    email: user?.email,
    address: user?.address,
    fileUrl: user?.fileUrl,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    multiple: false,
    onDrop: accepted => {
      setAcceptedFiles(accepted);
      fileHandler(accepted);
    },
  });

  const fileHandler = async accepted => {
    const file = accepted[0];
    // console.log('calling accepted in file hanlder', accepted, file);
    setFileUrl(URL.createObjectURL(file));
    // console.log('calling file url locally', URL.createObjectURL(file));
    try {
      setLoading(true);
      const data = new FormData();
      data?.append('file', file);
      data?.append('upload_preset', 'vv0rqrrj');
      data?.append('cloud_name', 'ddqlrpysr');

      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/ddqlrpysr/image/upload',
        data
      );

      // console.log('calling cloudinary res', response);
      if (response?.status === 200) {
        const imageUrl = response?.data?.secure_url;
        setFileUrl(imageUrl);
        setProfileData({ ...profileData, fileUrl: imageUrl });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast({
        title: 'error in uploading user file to cloudinary',
        status: error,
        isClosable: true,
        duration: 9000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = event => {
    setProfileData({
      ...profileData,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // console.log('calling profile data before sending', profileData);
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER}/user/${user?._id}`,
        profileData
      );

      // console.log('calling user update res', response);
      if (response.status === 200) {
        setUser(user => response?.data?.user);
        localStorage.setItem('user', JSON.stringify(response?.data?.user));
        // console.log('calling user after updating details', user);
        toast({
          title: 'user details updated',
          status: 'success',
          duration: '5000',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'error while updating user',
        status: 'error',
        isClosable: 'true',
        duration: 5000,
      });
      console.error('Error updating profile:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileData.dateOfBirth) {
      const dob = new Date(profileData.dateOfBirth);
      if (!isNaN(dob.getTime())) {
        const formattedDOB = dob.toISOString().split('T')[0];
        setProfileData({ ...profileData, dateOfBirth: formattedDOB });
      }
    }
  }, []);

  useEffect(() => {
    // console.log('calling accepted files', acceptedFiles);
  }, [acceptedFiles]);
  return (
    <>
      <Box width={'100%'}>
        <FormControl>
          <Stack spacing={4} width={'100%'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <VStack
                {...getRootProps()}
                width="220px"
                height="220px"
                border="2px dashed"
                borderColor="gray.200"
                borderRadius="md"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                fontSize={'normal'}
                padding={'5'}
              >
                <Box
                  width={'100%'}
                  padding={'5'}
                  flexDirection={'column'}
                  display={'flex'}
                  justifyContent="center"
                  alignItems={'center'}
                  gap={'5'}
                >
                  {profileData?.fileUrl ? (
                    <Avatar src={profileData?.fileUrl} size={'xl'} />
                  ) : (
                    <>
                      <BsImage width={'100%'} height={'100%'} size={'50px'} />
                      <Text
                        textAlign="center"
                        width={'100%'}
                        alignItems={'center'}
                      >
                        Drag & drop an image here or click to select one
                      </Text>
                    </>
                  )}
                </Box>
                <Input type="file" {...getInputProps()} />
              </VStack>
            </Box>
            <Grid
              width={'100%'}
              gap={'4'}
              templateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr']}
            >
              <Input
                id="firstName"
                type="text"
                name="firstName"
                value={profileData?.firstName}
                onChange={handleFormChange}
                placeholder="first name"
              />
              <Input
                id="lastName"
                type="text"
                name="lastName"
                value={profileData?.lastName}
                onChange={handleFormChange}
                placeholder="last name"
              />
            </Grid>
            <Grid
              width={'100%'}
              gap={'4'}
              templateColumns={['1fr', '1fr fr', '1fr 1fr', '1fr 1fr 1fr']}
            >
              <Input
                id="email"
                type="email"
                name="email"
                value={profileData?.email}
                onChange={handleFormChange}
                placeholder="your email"
              />
              <HStack width={'100%'}>
                <Select
                  name="gender"
                  value={profileData?.gender}
                  onChange={handleFormChange}
                  placeholder="gender"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Other</option>
                </Select>
              </HStack>
              <Input
                width={'100%'}
                id="dateOfBirth"
                type="date"
                name="dateOfBirth"
                value={profileData?.dateOfBirth}
                onChange={handleFormChange}
                placeholder="date of birth"
              />
            </Grid>
            <Grid
              width={'100%'}
              gap={'4'}
              templateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']}
            >
              <Input
                id="country"
                type="text"
                name="country"
                value={profileData?.country}
                onChange={handleFormChange}
                placeholder="Country"
              />
              <Input
                id="state"
                type="text"
                name="state"
                value={profileData?.state}
                onChange={handleFormChange}
                placeholder="state"
              />
              <Input
                id="city"
                type="text"
                name="city"
                value={profileData?.city}
                onChange={handleFormChange}
                placeholder="city"
              />
            </Grid>
            <Grid
              width={'100%'}
              gap={'4'}
              templateColumns={['1fr', '1fr fr', '1fr 1fr', '1fr 1fr 1fr']}
            >
              <Input
                id="password"
                type="password"
                name="password"
                value={profileData?.password}
                onChange={handleFormChange}
                placeholder="password"
              />
              <Input
                id="number"
                type="number"
                name="pincode"
                value={profileData?.pincode}
                onChange={handleFormChange}
                placeholder="pincode"
              />

              <Input
                id="phone"
                type="number"
                name="phone"
                value={profileData?.phone}
                onChange={handleFormChange}
              />
            </Grid>
            <Textarea
              id="address"
              name="address"
              value={profileData?.address}
              onChange={handleFormChange}
              placeholder="enter your adresss here"
              rows={4}
            />
            <HStack pb={'16'}>
              <Button
                type="submit"
                onClick={handleSubmit}
                variant={'solid'}
                colorScheme="teal"
                isLoading={loading}
              >
                Update Details
              </Button>
            </HStack>
          </Stack>
        </FormControl>
      </Box>
    </>
  );
};

export default Profile;
