import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  VStack,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCartStore from '../../../../store/cartStore';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';
import { CartContext } from '../../../../context/cart';

export const PackageCard = ({ packages, index }) => {
  const navigate = useNavigate();
  const { addToCartPlans, removeFromCart } = useContext(CartContext);
  const { cart, setCart, removeItem } = useCartStore();
  const [loading, setLoading] = useState();
  const toast = useToast();
  const [addTocart, setAddToCart] = useState();

  useEffect(() => {
    setAddToCart(cart.includes(packages));
  }, []);

  useEffect(() => {
    setAddToCart(cart.includes(packages));
  }, [cart]);

  return (
    <VStack
      justifyContent={'space-between'}
      spacing={0}
      color={'black'}
      gap={5}
      transition={'all'}
    >
      <HStack width={'80%'}>
        <VStack
          sx={{
            py: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
            gap: 5,
          }}
        >
          <Heading fontSize={'3xl'}>{packages?.name}</Heading>
          <Text
            sx={{
              width: '60%',
              fontSize: 'xl',
            }}
          >
            {packages?.description}
          </Text>
          <HStack spacing={7} fontSize={'lg'}>
            <Box
              sx={{ display: 'flex', flexDir: 'column', alignItems: 'center' }}
            >
              <Text color={'teal.400'}>Basic</Text>
              <Text>30 packagess</Text>
            </Box>
            <Box
              sx={{ display: 'flex', flexDir: 'column', alignItems: 'center' }}
            >
              <Text color={'teal.400'}>Essential</Text>
              <Text>32 packagess</Text>
            </Box>
            <Box
              sx={{ display: 'flex', flexDir: 'column', alignItems: 'center' }}
            >
              <Text color={'teal.400'}>Advance</Text>
              <Text>35 packagess</Text>
            </Box>
          </HStack>
        </VStack>
        <VStack spacing={1}>
          <Text fontSize={20} color={'teal'}>
            ₹ {packages.price}
          </Text>

          {addTocart ? (
            <Box
              sx={{
                display: 'flex',
                gap: 5,
              }}
            >
              <IconButton colorScheme="teal" onClick={() => navigate('/cart')}>
                <AiOutlineShoppingCart size={'20px'} />
              </IconButton>
              <IconButton
                onClick={() => {
                  removeItem(packages._id);
                  removeFromCart(packages);
                  toast({
                    title: 'Remove Form Cart!',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                  });
                }}
              >
                <AiOutlineDelete />
              </IconButton>
            </Box>
          ) : (
            <Button
              isLoading={loading}
              bg={'#0C727E'}
              color={'#fff'}
              _hover={{}}
              onClick={() => {
                setLoading(true);
                setCart([packages, ...cart]);
                addToCartPlans(packages);
                setLoading(false);
                toast({
                  title: 'Added To Cart!',
                  status: 'success',
                  isClosable: true,
                  duration: 5000,
                });
              }}
            >
              Add to Cart
            </Button>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};
