import { Box, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { CartContext } from '../../../context/cart';

const Order = ({ cartItem }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDir: 'column',
        gap: '5px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
        px: '20px',
        py: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDir: 'row',
          gap: '10px',
          alignItems: 'center',
          pb: '10px',
          borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style={{
            width: '20px',
            height: '20px',
          }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
          />
        </svg>
        <Text
          sx={{
            fontWeight: 600,
          }}
        >
          {cartItem?.name}
        </Text>
        <Text
          sx={{
            ml: 'auto',
            fontWeight: 600,
          }}
        >
          {cartItem?.price}
        </Text>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDir: 'column',
          gap: '5px',
          pb: '10px',
          borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        {cartItem?.patients?.map((pat, ind) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            key={ind}
          >
            <Text
              sx={{
                fontWeight: 600,
              }}
            >
              {pat?.name}
            </Text>
            <Text
              sx={{
                fontWeight: 600,
                fontSize: '12px',
              }}
            >
              {cartItem?.price}
            </Text>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Text
          sx={{
            fontWeight: 600,
          }}
        >
          Total
        </Text>
        <Text
          sx={{
            fontWeight: 600,
          }}
        >
          <span style={{ fontSize: '12px', fontWeight: 600 }}>
            {cartItem?.price} *{' '}
            {cartItem?.patients ? cartItem.patients.length : 0} ={' '}
          </span>
          <span style={{ color: 'darkgreen' }}>
            {cartItem?.patients
              ? parseInt(cartItem?.price) * parseInt(cartItem?.patients?.length)
              : 0}
            /-
          </span>
        </Text>
      </Box>
    </Box>
  );
};

export const OrderDetails = () => {
  const { cartData } = useContext(CartContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        // mb: '100px',
      }}
    >
      <Text
        sx={{
          fontWeight: 600,
          fontSize: '18px',
        }}
      >
        Order Details
      </Text>
      {cartData?.map((cartItem, index) => (
        <Order key={index} cartItem={cartItem} />
      ))}
    </Box>
  );
};
