import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Stack,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

export const LabInformation = () => {
  return (
    <VStack
      sx={{
        display: 'none',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      <Heading size={'lg'}>Lab Information</Heading>
      <Card
        width={'sm'}
        direction={{ base: 'column', sm: 'row' }}
        overflow="hidden"
        variant="outline"
      >
        <Stack>
          <CardBody
            sx={{
              display: 'flex',
              flexDir: 'column',
              gap: 5,
              px: 8,
              py: 6,
            }}
          >
            <Box>
              <Heading size="md">Krushna Clinic Lab Pvd Ltd</Heading>
              <Text>
                1st Floor, Shop No. 10, M.J. College Road, Jalgaon(424201)
              </Text>
              <Text fontSize={'sm'}>Open at 9:00am - 10:00pm</Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 5,
              }}
            >
              <Tag
                sx={{
                  px: 5,
                  py: 3,
                  border: '1px solid gray',
                }}
              >
                Popular Blood Test
              </Tag>
              <Tag
                sx={{
                  px: 5,
                  py: 3,
                  border: '1px solid gray',
                }}
              >
                Health Packages
              </Tag>
            </Box>
            <Button variant="solid" colorScheme="teal" width={'36'}>
              Visit Lab
            </Button>
          </CardBody>
        </Stack>
      </Card>
    </VStack>
  );
};
