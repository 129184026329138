import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Image,
  Text,
  IconButton,
  VStack,
  Box,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCartStore from '../../../../store/cartStore';
import { AiOutlineShoppingCart, AiOutlineDelete } from 'react-icons/ai';
import { CartContext } from '../../../../context/cart';

export const PackageCard = ({ packages }) => {
  const navigate = useNavigate();
  const { addToCartPlans, removeFromCart } = useContext(CartContext);
  const { cart, setCart, removeItem } = useCartStore();
  const [loading, setLoading] = useState();
  const toast = useToast();
  const [addTocart, setAddToCart] = useState();

  const calculateDescriptionLength = () => {
    return window.innerWidth < 600 ? 130 : 150;
  };

  const descriptionLength = calculateDescriptionLength();

  useEffect(() => {
    setAddToCart(cart?.includes(packages));
  }, []);

  useEffect(() => {
    setAddToCart(cart?.includes(packages));
  }, [cart]);

  return (
    <Card
      maxW={'lg'}
      h={'full'}
      px={[1, 3]}
      py={1}
      borderRadius={8}
      variant={'outline'}
      transition={'ease-in-out 0.3s'}
      border={'1px solid rgb(180,180,180,1)'}
      _hover={{
        // boxShadow: '0px 4px 10px rgba(42, 43, 44, 0.50)',
        boxShadow: 'xl',
        border: '1px solid gray',
      }}
    >
      <CardBody h={'lg'} p={[3, 5, 5, 5, 5]}>
        <VStack spacing={5} justifyContent={'space-between'}>
          <HStack spacing={0} alignItems={['flex-start']}>
            <Image
              display={['none', 'none', 'flex', 'none', 'flex']}
              src="/assets/landing/package/diabetes.png"
              width={['20', '24', '20', '20', '24']}
              height={['20', '24', '20', '20', '24']}
            />
            <VStack alignItems={'flex-start'}>
              <Heading
                fontSize={[18, 25]}
                _hover={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/package?packageId=${packages._id}`);
                }}
              >
                {packages?.name.slice(0, 23)}
                {packages?.name.length > 23 && '...'}
              </Heading>
              <Text fontSize={['sm', 'md']}>
                {packages?.description.slice(0, descriptionLength)}
                {packages?.description.length > descriptionLength && '...'}
              </Text>
            </VStack>
          </HStack>
          <Card
            maxW="lg"
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={[3, 6]}
            flexDir={'row'}
            py={2}
            borderRadius={8}
            variant={'outline'}
          >
            <Text
              color={'#1DC2D5'}
              fontSize={[16, 20]}
              fontWeight={600}
              display={['flex', 'flex']}
            >
              ₹ {packages?.price}
            </Text>
            <Text
              color={'#1DC2D5'}
              fontSize={[16, 20]}
              fontWeight={600}
              display={'flex'}
              flexDirection={['column', 'column']}
            >
              basic
              <Text color={'black'} fontSize={['16', '20']} fontWeight={600}>
                30 Tests
              </Text>
            </Text>
          </Card>

          {addTocart ? (
            <Box
              sx={{
                display: 'flex',
                gap: 5,
              }}
            >
              <IconButton colorScheme="teal" onClick={() => navigate('/cart')}>
                <AiOutlineShoppingCart size={'20px'} />
              </IconButton>
              <IconButton
                onClick={() => {
                  removeItem(packages._id);
                  removeFromCart(packages);
                  toast({
                    title: 'Removed Form Cart!',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                    position: 'top',
                  });
                }}
              >
                <AiOutlineDelete />
              </IconButton>
            </Box>
          ) : (
            <Button
              isLoading={loading}
              bg={'#0C727E'}
              color={'#fff'}
              _hover={{}}
              onClick={() => {
                setLoading(true);
                setCart([packages, ...cart]);
                addToCartPlans(packages);
                setLoading(false);
                toast({
                  title: 'Added To Cart!',
                  status: 'success',
                  isClosable: true,
                  duration: 5000,
                  position: 'top',
                });
              }}
            >
              Add to Cart
            </Button>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};
