import { Box, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../../../context/cart';

const SlotCard = ({ date }) => {
  const { setOrderDetail, orderDetail } = useContext(CartContext);
  const dateString = date?.date?.toString();
  const dayString = date?.day?.toString();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDir: 'column',
        alignItems: 'center',
        boxShadow: ' 1px -1px 10px 1px rgba(0, 0, 0, 0.1)',
        minWidth: '125px',
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'pointer',
        border: `2px solid ${
          orderDetail?.appointmentDate === date?.date?.toString()
            ? 'darkgreen'
            : 'transparent'
        }`,
      }}
      onClick={() =>
        setOrderDetail(orderDetail => ({
          ...orderDetail,
          appointmentDate: dateString,
        }))
      }
    >
      <Text
        sx={{
          fontWeight: 600,
        }}
      >
        {dateString}
      </Text>
      <Text>{dayString}</Text>
      <Box
        sx={{
          width: '100%',
          bgColor: 'rgb(207, 255, 207)',
          mt: '10px',
          borderTop: '2px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        <Text
          sx={{
            color: 'darkgreen',
            textAlign: 'center',
          }}
        >
          Available
        </Text>
      </Box>
    </Box>
  );
};

export const SelectDate = () => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    function getFutureDatesWithDay(numDays) {
      let currentDate = new Date();
      let futureDatesWithDay = [];

      for (let i = 0; i < numDays; i++) {
        currentDate.setDate(currentDate.getDate() + 1);

        let formattedDate = `${currentDate
          .getDate()
          .toString()
          .padStart(2, '0')}-${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${currentDate.getFullYear()}`;

        let dayOfWeek = currentDate.toLocaleDateString('en-US', {
          weekday: 'long',
        });

        futureDatesWithDay.push({ date: formattedDate, day: dayOfWeek });
      }

      return futureDatesWithDay;
    }

    const dates = getFutureDatesWithDay(14);
    setDates(() => dates);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDir: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        maxWidth: '100%',
      }}
    >
      <Text
        sx={{
          color: 'black',
          fontSize: '18px',
          fontWeight: 600,
        }}
      >
        Select Date
      </Text>
      <Box display={'flex'} gap={'10px'} overflowX={'scroll'} maxWidth={'100%'}>
        {dates?.map((date, ind) => (
          <SlotCard date={date} key={ind} />
        ))}
      </Box>
    </Box>
  );
};
