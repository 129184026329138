import { Box, VStack } from '@chakra-ui/react';
import { TopMain } from '../components/Landing/TopMain';
import { LandingSteps } from '../components/Landing/Steps';
import { PopularTests } from '../components/Landing/Tests';
import { PopularPackages } from '../components/Landing/Packages';
import { WhyDLabs } from '../components/Landing/Why';
import { LandingStats } from '../components/Landing/Stats';
import { Contact } from '../components/Landing/Contact';
import { FAQ } from '../components/Landing/FAQ';
import { SigninModal } from '../components/Landing/SigninModal';
import { FloatingButton } from '../components/Global';

export const HomePage = () => {
  return (
    <VStack w="100%" overflowX={'hidden'}>
      <TopMain />
      <LandingSteps />
      <PopularTests />
      <PopularPackages />
      <Contact />
      <Box display={'flex'} px={['3', '10', '10', '24', '32']} width={'100%'}>
        <FAQ />
      </Box>

      <WhyDLabs />
      <LandingStats />
      <SigninModal />
      <FloatingButton />
    </VStack>
  );
};
