import {
  Box,
  Center,
  Divider,
  HStack,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { Image, Button, Textarea, useToast, Stack } from '@chakra-ui/react';
import ReactStars from 'react-rating-stars-component';
import { ImHome, ImLab } from 'react-icons/im';
import userImage from '../../assets/lab_search/1.png';
import axios from 'axios';

import {
  FaGrinHearts,
  FaMeh,
  FaSadCry,
  FaSadTear,
  FaSmile,
} from 'react-icons/fa';
import { labDetails } from '../../api/labs';

export const Review = ({ testOrder }) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [ratingText, setRatingText] = useState('');
  const [lab, setLab] = useState('');

  const [reviewData, setReviewData] = useState({
    ratingStar: rating,
    ratingText: ratingText,
    reviewText: reviewText,
    labId: testOrder?.lab,
    user: testOrder?.consumerDetails?.user,
    orderId: testOrder?._id,
  });

  const ratingChanged = newRating => {
    setRating(newRating);
  };

  const handleReviewSubmission = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/reviews/create`,
        reviewData
      );
      // console.log('calling reviews response', response);
      setRating(0);
      setReviewText('');
      setRatingText('');
      toast({
        title: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error submitting review:', error);
      toast({
        title: 'error while adding reviews',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getRatingText = () => {
    if (rating === 1) {
      return setRatingText('Terrible');
    } else if (rating === 2) {
      return setRatingText('Bad');
    } else if (rating === 3) {
      return setRatingText('Average');
    } else if (rating === 4) {
      return setRatingText('Good');
    } else if (rating === 5) {
      return setRatingText('Excellent');
    } else {
      return '';
    }
  };

  const getRatingEmoji = () => {
    if (rating === 1) {
      return <FaSadCry color="#ffd700" />;
    } else if (rating === 2) {
      return <FaSadTear color="#ffd700" />;
    } else if (rating === 3) {
      return <FaMeh color="#ffd700" />;
    } else if (rating === 4) {
      return <FaSmile color={'#ffd700'} />;
    } else if (rating === 5) {
      return <FaGrinHearts color={'#ffd700'} />;
    } else {
      return;
    }
  };

  const fetchLab = async () => {
    // console.log('calling lab id', testOrder?.lab);
    const lab = await labDetails(testOrder?.lab);
    // console.log('calling lab response', lab);
    setLab(lab);
  };

  useEffect(() => {
    fetchLab();
  }, []);

  useEffect(() => {
    getRatingEmoji();
    getRatingText();
    setReviewData({
      ratingStar: rating,
      ratingText: ratingText,
      reviewText: reviewText,
      labId: testOrder?.lab,
      user: testOrder?.consumerDetails?.user,
      orderId: testOrder?._id,
    });
  }, [rating, reviewText]);
  return (
    <Box
      gap={10}
      display={'flex'}
      flexDir={'column'}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      p={['0']}
      pb={'10'}
    >
      <Heading
        color={'#1D5563'}
        fontSize={['25px', '3xl']}
        textAlign={['center', 'left']}
      >
        Write Review & Give Ratings
      </Heading>
      <Stack
        width={'100%'}
        justifyContent={'space-between'}
        border={'1px solid rgba(0,0,0,0.2)'}
        borderRadius={'20px'}
        alignItems={['center']}
        p={['0', '10']}
        py={'4'}
        gap={'5'}
        flexDirection={['column', 'column', 'column', 'row', 'row']}
        height={'100%'}
      >
        <VStack
          gap={5}
          alignItems={[
            'center',
            'center',
            'center',
            'flex-start',
            'flex-start',
          ]}
          width={'100%'}
        >
          <Stack
            gap={'5'}
            flexDirection={['column', 'column', 'row', 'column', 'row']}
          >
            <Image src={userImage} alt="" sizes="lg" width={'200px'} />
            <VStack
              gap={'5'}
              justifyContent={[
                'flex-start',
                'flex-start',
                'center',
                'flex-start',
                'flex-start',
              ]}
            >
              <Text
                color={'#1D5563'}
                fontFamily={'Nunito Sans'}
                fontSize={'18px'}
                fontWeight={'600'}
                lineHeight={'normal'}
                textTransform={'capitalize'}
              >
                {lab?.labName}
              </Text>
              <Text>{lab?.labAddress?.placeName}</Text>
            </VStack>
          </Stack>
          <VStack
            width={'100%'}
            alignItems={[
              'center',
              'center',
              'center',
              'flex-start',
              'flex-start',
            ]}
          >
            <Text
              color={'#1D5563'}
              fontFamily={'Nunito Sans'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'normal'}
              textTransform={'capitalize'}
            >
              Rate Us with Your Experience
            </Text>
            <ReactStars
              count={5}
              value={rating}
              onChange={ratingChanged}
              size={24}
              activeColor="#ffd700"
            />
            {rating ? (
              <HStack border={'1px solid #1DC2D5'} borderRadius={'6px'} p={2}>
                <Text>{ratingText}</Text>
                {getRatingEmoji()}
              </HStack>
            ) : (
              <HStack border={'1px solid #1DC2D5'} borderRadius={'6px'} p={2}>
                <Text>Click on Star</Text>
              </HStack>
            )}
          </VStack>
        </VStack>
        <Center>
          <Divider
            height={['0', '0', '0', '60', '60']}
            orientation={[
              'horizontal',
              'horizontal',
              'horizontal',
              'vertical',
              'vertical',
            ]}
            border={'1px solid gray'}
          />
        </Center>
        <VStack
          gap={'5'}
          p={'5'}
          width={'100%'}
          justifyContent={['center', 'flex-start']}
          alignItems={'center'}
        >
          <HStack
            gap={['5', '10']}
            width={'100%'}
            justifyContent={'space-evenly'}
            flexWrap={'wrap'}
          >
            <Button
              variant={'outline'}
              color="#1DC2D5"
              border={'1px solid #1DC2D5'}
              borderRadius={'6'}
              leftIcon={<ImLab />}
            >
              Lab Visit
            </Button>

            <Button
              variant={'outline'}
              color="#1DC2D5"
              border={'1px solid #1DC2D5'}
              borderRadius={'6'}
              leftIcon={<ImHome />}
            >
              Home Visit
            </Button>
          </HStack>
          <Text
            fontSize={'18px'}
            fontWeight={'600'}
            width={'100%'}
            textAlign={['center', 'center', 'center', 'left', 'left']}
          >
            Tell Us Your Experience
          </Text>
          <Textarea
            name="reviewText"
            value={reviewText}
            onChange={e => setReviewText(e.target.value)}
            focusBorderColor={'#1D5563'}
            placeholder="can you please share your experience with our lab service"
          ></Textarea>
        </VStack>
      </Stack>
      <Button
        colorScheme="teal"
        onClick={handleReviewSubmission}
        isLoading={loading}
        padding={'5'}
      >
        Submit Your Response
      </Button>
    </Box>
  );
};
