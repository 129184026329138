import React, { useEffect, useState } from 'react';
import {
  HStack,
  Input,
  Button,
  InputGroup,
  InputRightAddon,
  useBreakpoint,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  useDisclosure,
  DrawerContent,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { SearchBar } from '../../SearchBar';

export const TopSearchBar = () => {
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const firstField = React.useRef();

  const breakpoint = useBreakpoint();

  const handleSearch = async e => {
    e?.preventDefault();
    onClose();

    keyword === ''
      ? navigate(`/search`)
      : navigate(`/search?keyword=${keyword}`);
  };

  return (
    <>
      {breakpoint === 'base' || breakpoint === 'md' || breakpoint === 'sm' ? (
        <>
          <Button
            colorScheme="teal"
            onClick={onOpen}
            width={'100%'}
            size={'sm'}
          >
            Search Lab
          </Button>
          <Drawer
            isOpen={isOpen}
            placement="top"
            onClose={onClose}
            initialFocusRef={firstField}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerBody py={'10'}>
                <form onSubmit={handleSearch}>
                  <HStack display={['flex', 'flex']} width={'100%'} p={'0'}>
                    <InputGroup border={'none'}>
                      <Input
                        placeholder="Search By City, Lab Or Pincode"
                        type="text"
                        variant={'filled'}
                        color={'black'}
                        _focus={{ bg: 'white', color: 'black' }}
                        // width={['25vw', '20vw']}
                        borderLeftRadius={'7'}
                        name="keyword"
                        onChange={e => setKeyword(e.target.value)}
                        value={keyword}
                      />
                      <InputRightAddon
                        padding={'0'}
                        border={'none'}
                        borderRightRadius={'7'}
                      >
                        <Button
                          type="submit"
                          borderLeftRadius={'0'}
                          colorScheme="teal"
                          border={'none'}
                          px={[2, 6]}
                          py={2}
                        >
                          <SearchIcon />
                        </Button>
                      </InputRightAddon>
                    </InputGroup>
                  </HStack>
                </form>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <SearchBar />
      )}
    </>
  );
};
