import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Text, useBreakpoint } from '@chakra-ui/react';
import { CartContext } from '../../../context/cart';
import { AiOutlineShoppingCart } from 'react-icons/ai';

export const FloatingButton = () => {
  const { cartPlans } = useContext(CartContext);
  const navigate = useNavigate();

  const breakpoint = useBreakpoint();

  return (
    <Button
      size={['xs', 'md']}
      display={
        cartPlans?.length > 0
          ? breakpoint === 'sm' || breakpoint === 'md' || breakpoint === 'base'
            ? 'none'
            : 'flex'
          : 'none'
      }
      sx={{
        position: 'fixed',
        alignItems: 'center',
        backgroundColor: 'teal',
        color: 'white',
        bottom: '100px',
        right: '30px',
        borderRadius: '20px',
        px: '20px',
        py: '25px',
        _hover: {
          backgroundColor: 'teal',
          color: 'white',
        },
      }}
      onClick={() => navigate('/cart')}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style={{
            width: '25px',
            height: '25px',
            color: 'white',
          }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
          />
        </svg> */}
        <IconButton
          size={['sm', 'md']}
          colorScheme="transparent"
          onClick={() => navigate('/cart')}
        >
          <AiOutlineShoppingCart />
        </IconButton>
        <Box
          sx={{
            width: '15px',
            height: '15px',
            backgroundColor: 'red',
            borderRadius: '100%',
            fontSize: '12px',
            position: 'absolute',
            top: '-15%',
            right: '-15%',
          }}
        >
          {cartPlans?.length}
        </Box>
      </Box>
      <Text>{cartPlans?.length} Health Check</Text>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        style={{
          width: '20px',
          height: '20px',
          color: 'white',
        }}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </Button>
  );
};
