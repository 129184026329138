import {
  Avatar,
  Box,
  Button,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrderDetails } from '../../../api/order';
import { IconComponent } from '../../Global/IconComponents';
import axios from 'axios';
import { Review } from '../../Reviews';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { MdLocationOn } from 'react-icons/md';

export const TestDetails = () => {
  const [test, setTest] = useState({});
  const [testOrder, setTestOrder] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // console.log('calling test location obeject', location);

  const searchObject = new URLSearchParams(location?.search);

  // console.log('calling searchOBject', searchObject);

  const orderId = searchObject.get('orderId');

  console.log('calling order Id', orderId);

  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const handleCancelled = async () => {
    try {
      navigate(
        `/profile/orders/orderdetails?orderId=${orderId}&action?=cancel`
      );
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/labs/orders/cancel/${orderId}`
      );

      setTestOrder(res?.data?.order);
      toast({
        title: res?.data?.message,
        duration: 9000,
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error cancelling order:', error);
      toast({
        title: 'error in cancelling the order',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchOrder = async () => {
    if (orderId) {
      try {
        setLoading(true);
        const res = await getOrderDetails(orderId);
        setTest(() => res.test);
        // console.log('calling getorderDetails res', res);
        setTestOrder(res);
      } catch (error) {
        console.error('error while fetching orderDetails', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '60vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size={'lg'} />
        </Box>
      ) : (
        <VStack width={'100%'} gap={'12'}>
          <VStack
            justifyContent={'space-between'}
            spacing={0}
            color={'black'}
            gap={12}
            px={['1', '3']}
            width={'100%'}
          >
            <Stack
              width={'100%'}
              gap={[0, 0, 0, 0, 10]}
              justifyContent={'space-between'}
              alignItems={['center']}
              flexDir={['column', 'column', 'column', 'column', 'row']}
            >
              <VStack
                sx={{
                  py: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  width: '100%',
                  gap: 5,
                }}
              >
                <Heading fontSize={['25px', '3xl']}>{test?.name}</Heading>
                <Text
                  sx={{
                    width: '100%',
                    fontSize: ['16px', '18px'],
                  }}
                >
                  {test?.description}
                </Text>
              </VStack>
              <VStack spacing={1}>
                <Text fontSize={20} color={'teal'}>
                  ₹ {test?.price}
                </Text>
                <Text
                  color={'gray'}
                  sx={{
                    display: 'flex',
                    textDecor: 'line-through',
                  }}
                  fontSize={'15'}
                >
                  ₹ 2500.00
                </Text>

                <Button
                  bg={
                    testOrder?.orderStatus === 'pending'
                      ? '#FFC035'
                      : testOrder?.orderStatus === 'completed'
                      ? '#2CBAA3'
                      : 'messenger.500'
                  }
                  color={'#fff'}
                  _hover={{}}
                >
                  {testOrder?.orderStatus}
                </Button>
              </VStack>
            </Stack>
            <VStack width={'100%'} alignItems={'flex-start'} gap={'5'}>
              <Heading color={'#1D5563'} fontSize={['25px', '3xl']}>
                Customer Details
              </Heading>
              <HStack
                justifyContent={'space-between'}
                width={'100%'}
                gap={'5'}
                flexWrap={'wrap'}
              >
                <VStack gap={'5'} alignItems={'flexd-start'}>
                  <HStack gap={'5'}>
                    <Avatar src={testOrder?.consumerDetails?.user?.fileUrl} />
                    <VStack alignItems={'flex-start'}>
                      <Text color={'teal'}>
                        {testOrder?.consumerDetails?.user?.phone}
                      </Text>
                      <Text>{testOrder?.consumerDetails?.user?._id}</Text>
                    </VStack>
                  </HStack>
                  <Box
                    sx={{
                      border: '1px solid rgba(0, 0, 0, 0.2)',
                      borderRadius: '20px',
                      px: ['5'],
                      py: '20px',
                      display: 'flex',
                      flexDir: 'column',
                      flexWrap: 'wrap',
                      gap: '10px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '20px',
                        justifyContent: ['center', 'flex-start'],
                        alignItems: ['flex-start', 'center'],
                        flexDir: ['column', 'row'],
                      }}
                    >
                      <IconComponent
                        icon={<PhoneIcon />}
                        text={testOrder?.consumerDetails?.phone}
                      />
                      <IconComponent
                        icon={<EmailIcon />}
                        text={testOrder?.consumerDetails?.email}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '20px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent
                        icon={<MdLocationOn />}
                        text={
                          testOrder?.consumerDetails?.address?.secondaryAddress
                        }
                      />
                    </Box>
                  </Box>
                </VStack>
                {testOrder?.orderStatus === 'completed' ? (
                  <VStack justifyContent={'flex-start'} gap={'5'}>
                    <Button
                      colorScheme="purple"
                      onClick={() =>
                        navigate(
                          `/profile/orders/orderdetails/healthscore?orderId=${orderId}`
                        )
                      }
                    >
                      Check Health Score
                    </Button>
                    <Button bg={'teal'} color={'#fff'}>
                      <a
                        href={testOrder?.reportUrl}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download Report
                      </a>
                    </Button>
                  </VStack>
                ) : testOrder?.orderStatus === 'pending' ? (
                  <Button
                    bg={'#FF725E'}
                    color={'#fff'}
                    onClick={handleCancelled}
                    isLoading={loading}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button bg={'#FF725E'} color={'#fff'} isDisabled>
                    Cancel
                  </Button>
                )}
              </HStack>
            </VStack>
          </VStack>
          {testOrder?.orderStatus === 'completed' && (
            <Review testOrder={testOrder} />
          )}
        </VStack>
      )}
    </>
  );
};
