import { Box, Button, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { IconComponent } from '../../Global/IconComponents';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { MdLocationOn } from 'react-icons/md';
import useLabsStore from '../../../store/labsStore';
import { NearbyLabsModal } from '../NearByLabModal';

export const LabDetail = () => {
  const { labData, setLabData } = useLabsStore();
  const [labChange, setLabChange] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <HStack width={'100%'} justifyContent={'space-between'}>
          <Text
            sx={{
              fontWeight: 600,
              fontSize: '18px',
            }}
          >
            Selected Lab Details
          </Text>
          <Button
            colorScheme="teal"
            borderRadius={'10px'}
            onClick={() => setLabChange(true)}
          >
            Change Lab
          </Button>
        </HStack>

        <Box
          sx={{
            display: 'flex',
            flexDir: 'column',
            gap: '5px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: '20px',
            px: '20px',
            py: '20px',
          }}
        >
          {labData?.labAddress?.placeName ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDir: 'row',
                  gap: '10px',
                  alignItems: 'center',
                  pb: '10px',
                  borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                  />
                </svg>
                <Text
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {labData?.name}
                </Text>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDir: 'column',
                  gap: '5px',
                  pb: '10px',
                  borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDir: ['column', 'row'],
                    gap: '20px',
                    justifyContent: 'flex-start',
                    alignItems: ['flex-start', 'center'],
                  }}
                >
                  <IconComponent icon={<PhoneIcon />} text={labData?.phone} />
                  <IconComponent icon={<EmailIcon />} text={labData?.email} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <IconComponent
                  icon={<MdLocationOn />}
                  text={labData?.labAddress?.placeName}
                />
              </Box>
            </>
          ) : (
            <VStack
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Text
                  px={'4'}
                  py={'1'}
                  backgroundColor={'darkred'}
                  borderRadius={'20'}
                  color={'white'}
                >
                  Attention, No Lab Selected
                </Text>
              </Box>
              <Text>
                No Lab Selected, To Continue Click On Select Lab Button
              </Text>
              <Button
                colorScheme="teal"
                onClick={() => setLabChange(true)}
                borderRadius={'10px'}
              >
                Select Lab
              </Button>
            </VStack>
          )}
        </Box>
      </Box>

      <NearbyLabsModal
        isOpen={labChange}
        onClose={() => setLabChange(!labChange)}
      />
    </>
  );
};
