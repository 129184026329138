import {
  Heading,
  VStack,
  Button,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import usePackageStore from '../../../../store/packageStore';
import { getPackages } from '../../../../api/packages';
import { useNavigate } from 'react-router-dom';
import { PackageCard } from '../../../Landing/Packages/PackageCard';

export const MostSearchPackages = () => {
  const { packages, setPackages } = usePackageStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getPackages();
      setPackages(res);
    };
    fetchData();
  }, []);
  return (
    <VStack p={[7, 10]} spacing={12}>
      <Flex flexDir={'column'} gap={[5, 9]} alignItems={'center'}>
        <Heading color="teal" fontSize={[24, 30]}>
          Health Checkup Packages
        </Heading>
      </Flex>
      <VStack
        spacing={[6, 8]}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'center',
        }}
      >
        {packages.slice(0, 2).map((packages, i) => (
          <PackageCard key={i} packages={packages} />
        ))}
      </VStack>
      <Button
        variant={'ghost'}
        color={'teal'}
        onClick={() => navigate('/packages')}
      >
        All Packages
      </Button>
    </VStack>
  );
};
