import { Heading, Text, VStack } from '@chakra-ui/react';

export const WhyDLabs = () => {
  return (
    <VStack
      p={[5, 10]}
      height={['lg', 'xs']}
      spacing={[5]}
      textAlign={'center'}
      mb={['12', '12']}
      display={['none', 'flex']}
    >
      <Heading color="teal" fontSize={[24, 30]}>
        Why Choose D Labs?
      </Heading>
      <Text
        w={[254, 900]}
        textAlign={['left', 'center']}
        fontSize={['md', 'lg']}
      >
        D Labs for your blood tests and health needs means gaining access to
        affordable pricing, top-notch quality testing, the convenience of home
        sample collection, speedy report delivery, and the option to connect
        with labs near you. Our focus on customer satisfaction and accessible
        healthcare makes D Labs the ideal choice for individuals seeking
        reliable and efficient testing services. Your health is important to us,
        and we are dedicated to providing you with the best possible care.
      </Text>
    </VStack>
  );
};
