import React, { useEffect } from 'react';
import { Footer } from '../components/Footer';
import { FAQ } from '../components/Landing/FAQ';
import { AllLabs } from '../components/Labs/AllLabs';

export const LabsPage = () => {
  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await getPackages();
  //     setPackages(res);
  //   };
  //   fetchData();
  // }, []);

  // const handleSearch = async e => {
  //   e.preventDefault();
  //   const res = await searchPackages(e.target.value);
  //   setPackages(res);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AllLabs />
      <FAQ />
      <Footer />
    </>
  );
};
