import axios from 'axios';

export const getTests = async () => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER}/getTests`);
  const tests = res.data.tests;
  return tests;
};

export const searchTests = async value => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER}/getTests?keyword=${value}`
  );
  const tests = res.data.tests;
  return tests;
};

export const testDetails = async id => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER}/getTests?id=${id}`
  );
  const test = res.data.test;

  return test;
};
