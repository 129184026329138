import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  VStack,
  IconButton,
  useToast,
  Stack,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCartStore from '../../../../store/cartStore';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';
import { CartContext } from '../../../../context/cart';

export const TestsCard = ({ test, index }) => {
  const navigate = useNavigate();
  const { addToCartPlans, removeFromCart } = useContext(CartContext);
  const { cart, setCart, removeItem } = useCartStore();
  const [loading, setLoading] = useState();
  const toast = useToast();
  const [addTocart, setAddToCart] = useState();

  useEffect(() => {
    setAddToCart(cart.includes(test));
  }, []);

  useEffect(() => {
    setAddToCart(cart.includes(test));
  }, [cart]);

  return (
    <Stack
      width={'100%'}
      flexDirection={['column', 'column', 'row', 'row', 'row']}
      px={['3', '10', '10', '24', '32']}
      py={'10'}
      gap={'10'}
    >
      <VStack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          width: '100%',
          gap: 5,
        }}
      >
        <Heading fontSize={['20px', '3xl']}>{test?.name}</Heading>
        <Text
          sx={{
            width: '100%',
            fontSize: ['18px', 'lg'],
          }}
        >
          {test?.description}
        </Text>
      </VStack>
      <VStack spacing={1}>
        <Text fontSize={['18px', '20px']} color={'teal'}>
          ₹ {test?.price}
        </Text>
        <Text
          color={'gray'}
          sx={{
            display: 'flex',
            textDecor: 'line-through',
          }}
          fontSize={['18px', '20px']}
        >
          ₹ 2500.00
        </Text>

        {addTocart ? (
          <Box
            sx={{
              display: 'flex',
              gap: 5,
            }}
          >
            <IconButton colorScheme="teal" onClick={() => navigate('/cart')}>
              <AiOutlineShoppingCart size={['18px', '20px']} />
            </IconButton>
            <IconButton
              onClick={() => {
                removeItem(test._id);
                removeFromCart(test);
                toast({
                  title: 'Remove Form Cart!',
                  status: 'error',
                  isClosable: true,
                  duration: 5000,
                });
              }}
            >
              <AiOutlineDelete />
            </IconButton>
          </Box>
        ) : (
          <Button
            isLoading={loading}
            bg={'#0C727E'}
            color={'#fff'}
            size={['md', 'md']}
            onClick={() => {
              setLoading(true);
              setCart([test, ...cart]);
              addToCartPlans(test);
              setLoading(false);
              toast({
                title: 'Added To Cart!',
                status: 'success',
                isClosable: true,
                duration: 5000,
              });
            }}
          >
            Add to Cart
          </Button>
        )}
      </VStack>
    </Stack>
  );
};
