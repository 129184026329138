import { Box, Button, HStack, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { LabTests } from './Tests';
import { LabPackages } from './Packages';

export const Lab = ({ lab }) => {
  return (
    <>
      <Stack
        flex={1}
        px={['3', '10', '10', '24', '32']}
        width={'100%'}
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        justifyContent={'space-between'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDir: 'column',
            gap: 3,
          }}
        >
          <Heading fontSize={['25px', '3xl']} cursor={'pointer'}>
            {lab?.labName}
          </Heading>

          <Text>{lab?.description}</Text>
          <Text color={'gray'}>{lab?.labAddress?.placeName}</Text>
          <Box
            sx={{
              color: 'gray',
              fontWeight: '500',
              display: 'flex',
              gap: 3,
            }}
          >
            <Text
              sx={{
                py: 1,
                width: '40',
                borderRadius: 5,
                border: '1px solid gray',
                textAlign: 'center',
                bg: '#E2E8F0',
                size: ['sm', 'md'],
              }}
            >
              Popular blood test
            </Text>
          </Box>
          <HStack alignItems="center" width={'100%'} flexWrap={'wrap'}>
            <Text>{`Open at ${lab?.openingTime}:00 am - ${lab?.closingTime}:00 pm`}</Text>
            <Text color="#1DC2D5">{lab?.workingDays}</Text>
          </HStack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            flexDir: ['column', 'row', 'column', 'column', 'column'],
            alignItems: [
              'flex-start',
              'flex-start',
              'center',
              'center',
              'center',
            ],
            justifyContent: [
              'flex-start',
              'flex-start',
              'center',
              'center',
              'center',
            ],
          }}
        >
          <Button variant="solid" colorScheme="teal" size={['sm', 'md']}>
            Book a test
          </Button>
          <Button variant="outline" colorScheme="teal" size={['sm', 'md']}>
            Send Location To Phone
          </Button>
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDir: 'column',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <LabTests />
        <LabPackages />
      </Box>
    </>
  );
};
