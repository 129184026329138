import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export const PatientInstructions = () => {
  return (
    <VStack
      display={'flex'}
      flexDir={'column'}
      justifyContent={'space-between'}
      spacing={0}
      color={'black'}
      gap={8}
    >
      <Heading size={'lg'} color={'#0C727E'}>
        Patient Instruction
      </Heading>
      <VStack
        sx={{
          py: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          border: '2px solid gray',
          borderRadius: 5,
          py: 10,
          px: 8,
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '80vw',
          }}
        >
          <Box>
            <Text fontWeight={'semibold'} fontSize={20}>
              Category:
            </Text>
            <Text>Liver Function Test</Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '80vw',
          }}
        >
          <Box>
            <Text fontWeight={'semibold'} fontSize={20}>
              Pre-test Information:
            </Text>
            <Text width={'90%'}>
              Plan on fasting for up to 12 hours before you take a liver panel
              test, only drinking water during this time. Also, be sure your
              doctor knows what medications you are taking because some
              prescription drugs and over-the-counter medications or dietary
              supplements can interfere with the test.
            </Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '80vw',
          }}
        >
          <Box>
            <Text fontWeight={'semibold'} fontSize={20}>
              Report Delivery::
            </Text>
            <Text>Daily (Mon - Sat) :18:30 hrs with same day reporting</Text>
          </Box>
        </Box>
      </VStack>
    </VStack>
  );
};
