import { Heading, Image, Stack, Text, VStack } from '@chakra-ui/react';
import { SearchBar } from '../../SearchBar';

export const TopMain = () => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={[
        'flex-start',
        'flex-start',
        'flex-start',
        'space-between',
        'space-between',
      ]}
      px={[3, 10, 10, 10, 16]}
      py={[10]}
      flexDirection={'row'}
      bgColor={'teal.400'}
      spacing={0}
      color={'#fff'}
    >
      <VStack
        w={['100%', '100%', '100%', '80%', '50%']}
        spacing={['8', 16]}
        alignItems={'flex-start'}
        justifyContent={'center'}
        textAlign={'start'}
        // bg={'black'}
      >
        <VStack
          display={'flex'}
          alignItems={'flex-start'}
          spacing={[3, 5]}
          w={['100%', '100%', '100%', '70%', '70%']}
        >
          <Heading fontWeight={700} fontSize={['30px', '40px']}>
            Digital Diagnostics Enhancing Precision & Efficiency
          </Heading>
          <Text>Transforming Healthcare Through Digital Innovation</Text>
        </VStack>
        <SearchBar />
      </VStack>
      <Image
        src="/assets/lab-main.png"
        display={['none', 'none', 'flex', 'flex', 'flex']}
        width={['50%', '50%', '40%', '35%', '35%']}
      />
    </Stack>
  );
};
