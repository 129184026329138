import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  IconButton,
  Image,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCartStore from '../../../../../store/cartStore';
import { CartContext } from '../../../../../context/cart';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';

export const PackageCard = ({ packages }) => {
  const navigate = useNavigate();
  const { addToCartPlans, removeFromCart } = useContext(CartContext);
  const { cart, setCart, removeItem } = useCartStore();
  const [loading, setLoading] = useState();
  const toast = useToast();
  const [addTocart, setAddToCart] = useState();

  useEffect(() => {
    setAddToCart(cart?.includes(packages));
  }, []);

  useEffect(() => {
    setAddToCart(cart?.includes(packages));
  }, [cart]);
  return (
    <Card
      maxW={['full', 'lg']}
      px={['0', '3']}
      py={1}
      borderRadius={8}
      variant={'outline'}
    >
      <CardBody>
        <VStack spacing={5}>
          <HStack spacing={5} alignItems={['flex-start']}>
            <Image
              src="/assets/landing/package/diabetes.png"
              width={'24'}
              height={'24'}
              display={['none', 'flex']}
            />
            <VStack alignItems={'flex-start'}>
              <Heading
                fontSize={[22, 25]}
                _hover={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/package?packageId=${packages?._id}`);
                }}
              >
                {packages?.name.slice(0, 23)}
                {packages?.name.length > 25 && '...'}
              </Heading>
              <Text>
                {packages?.description.slice(0, 150)}
                {packages?.description.length > 150 && '...'}
              </Text>
            </VStack>
          </HStack>
          <Card
            maxW="lg"
            width={'100%'}
            sx={{
              display: 'flex',
              flexDirection: 'rows',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            px={[3, 6]}
            py={2}
            borderRadius={8}
            variant={'outline'}
          >
            <Text color={'#1DC2D5'} fontSize={[18, 20]} fontWeight={600}>
              ₹ {packages?.price}
            </Text>
            <Text
              color={'#1DC2D5'}
              fontSize={[18, 20]}
              fontWeight={600}
              display={'flex'}
              flexDirection={'column'}
            >
              basic{' '}
              <Text color={'black'} fontSize={15} fontWeight={600}>
                30 tests
              </Text>
            </Text>
          </Card>

          {addTocart ? (
            <Box
              sx={{
                display: 'flex',
                gap: 5,
              }}
            >
              <IconButton colorScheme="teal" onClick={() => navigate('/cart')}>
                <AiOutlineShoppingCart size={'20px'} />
              </IconButton>
              <IconButton
                onClick={() => {
                  removeItem(packages._id);
                  removeFromCart(packages);
                  toast({
                    title: 'Removed Form Cart!',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                  });
                }}
              >
                <AiOutlineDelete />
              </IconButton>
            </Box>
          ) : (
            <Button
              isLoading={loading}
              bg={'#0C727E'}
              color={'#fff'}
              _hover={{}}
              onClick={() => {
                setLoading(true);
                setCart([packages, ...cart]);
                addToCartPlans(packages);
                setLoading(false);
                toast({
                  title: 'Added To Cart!',
                  status: 'success',
                  isClosable: true,
                  duration: 5000,
                });
              }}
            >
              Add to Cart
            </Button>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};
