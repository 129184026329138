import React, { useEffect } from 'react';
import './App.css';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  ForgotPasswordPage,
  Layout,
  NoPage,
  ResetPasswordPage,
  SigninPage,
  SignupPage,
  VerifyEmailPage,
} from './pages';
import { Footer } from './components/Footer';
import { UserAuthContextProvider } from './context/UserAuthContext';
import PrivacyPage from './pages/Privacy';
import TermsPage from './pages/Terms';
import RefundPage from './pages/Refund';
import { Cart } from './pages/Cart';
import useStore from './store/store';
import { PackagePage } from './pages/PackagePage';
import { PackagesPage } from './pages/PackagesPage';
import { TestsPage } from './pages/TestsPage';
import { TestPage } from './pages/TestPage';
import { getUser } from './cookie/user';
import { LabsPage } from './pages/LabsPage';
import { HomePage } from './pages/HomePage';
import { LabPage } from './pages/LabPage';
import { SearchLabs } from './pages/SearchLabs';
import { CartProvider } from './context/cart';
import AboutPage from './pages/About';
import ProfilePage from './pages/ProfilePage';
import { Menu } from './components/Menu';
import { OrdersPage } from './pages/OrdersPage';
import { TestOrderDetails } from './pages/TestOrderDetails';
import { ContactPage } from './pages/ContactPage';
import { HealthScoreDetails } from './pages/HealthScoreDetails';
import { PackageOrderDetails } from './pages/PackageOrderDetails';

function App() {
  const { setUser } = useStore();

  useEffect(() => {
    let user1 = getUser();
    // console.log('calling user in the app', user);
    // console.log('calling user in the app', user1);
    setUser(user1);
    // console.log("calling user after settung in app",user);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <UserAuthContextProvider>
        <CartProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route
                  index
                  element={
                    <>
                      <HomePage />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/search"
                  element={
                    <>
                      <SearchLabs />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/privacy"
                  element={
                    <>
                      <PrivacyPage />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <>
                      <AboutPage />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/contactus"
                  element={
                    <>
                      <ContactPage />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/terms"
                  element={
                    <>
                      <TermsPage />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/refund"
                  element={
                    <>
                      <RefundPage />
                      <Footer />
                    </>
                  }
                />
                <Route path="/package" element={<PackagePage />} />
                <Route path="/packages" element={<PackagesPage />} />
                <Route path="/tests" element={<TestsPage />} />
                <Route path="/test" element={<TestPage />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/labs" element={<LabsPage />} />
                <Route path="/lab" element={<LabPage />} />
              </Route>
              <Route>
                <Route
                  path="/profile"
                  element={
                    <>
                      <Layout />
                      <ProfilePage />
                      <Menu />
                    </>
                  }
                />
                <Route
                  path="/profile/orders"
                  element={
                    <>
                      <Layout />
                      <OrdersPage />
                      <Menu />
                    </>
                  }
                />
                <Route
                  path="/profile/orders/testorderdetails"
                  element={
                    <>
                      <Layout />
                      <TestOrderDetails />
                      <Menu />
                    </>
                  }
                />
                <Route
                  path="/profile/orders/packagesorderdetails"
                  element={
                    <>
                      <Layout />
                      <PackageOrderDetails />
                      <Menu />
                    </>
                  }
                />
                <Route
                  path="/profile/orders/orderdetails/healthscore"
                  element={
                    <>
                      <Layout />
                      <HealthScoreDetails />
                      <Menu />
                    </>
                  }
                />
              </Route>

              <Route path="/signin" element={<SigninPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/verify-email" element={<VerifyEmailPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="*" element={<NoPage />} />
            </Routes>
          </BrowserRouter>
        </CartProvider>
      </UserAuthContextProvider>
    </ChakraProvider>
  );
}

export default App;
