import axios from 'axios';

export const getPackages = async () => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER}/getPackages`);
  const packages = res?.data?.packages;
  return packages;
};

export const searchPackages = async value => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER}/getPackages?keyword=${value}`
  );
  const packages = res?.data?.packages;
  return packages;
};

export const packageDetails = async ({ id }) => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER}/getPackages?id=${id}`
  );
  const packages = res?.data?.packages;
  return packages;
};
